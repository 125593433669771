import {
  Center,
  Text,
  Link,
  Button,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";

const ApplicationStatus = (props) => {
  const [applicationStatus, setApplicationStatus] = useState(0);
  const [discordInitialized, setDiscordInitialized] = useState(false);
  const colours = {
    card: "#202934",
    accent: "#9b4587",
    text_selected: "#ffffff",
  };

  /**
   * 0: Application not received
   * 1: Application received
   * 2: Application accepted
   * 3: Application rejected
   */
  const description = {
    0: "Please submit an application by going on the Application tab first!",
    1: (
      <text>
        <Text as="b">We've received your application. ⏳</Text> Please keep an
        eye out for any updates!
      </text>
    ),
    2: (
      <text>
        Congratulations, <Text as="b">you've been accepted</Text> to MetHacks
        2023! 🎉
      </text>
    ),
    3: (
      <text>
        Sorry, <Text as="b">you weren't chosen</Text> to be a part of this
        year's hackathon 😔 Please apply again next year!
      </text>
    ),
  };

  const progress = {
    0: 0,
    1: 50,
    2: 100,
    3: 100,
  };

  useEffect(() => {
    async function getUserStatus() {
      const docRef = doc(db, "applications", props.user.uid);

      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userInfo = docSnap.data();
        setApplicationStatus(userInfo.applicationStage + 1);
        setDiscordInitialized(userInfo.discordInitialized);
      } else {
        setApplicationStatus(0);
      }
    }
    getUserStatus();
  }, []);

  return (
    <>
      <Text my={10} textAlign="center">
        {description[applicationStatus]}
      </Text>
      <Center>
        <CircularProgress
          mb={10}
          value={progress[applicationStatus]}
          size="200px"
          color={colours.accent}
        >
          <CircularProgressLabel>
            {applicationStatus === 3 ? 2 : applicationStatus}/2
          </CircularProgressLabel>
        </CircularProgress>
      </Center>
      {applicationStatus === 2 && (
        <>
          {discordInitialized ? (
            <Text textAlign="center">
              Looks like you've joined our Discord server already!
            </Text>
          ) : (
            <Text textAlign="center">
              Now that you've been accepted, be sure join our Discord server
              before the events start!
            </Text>
          )}
          <Center mt={5}>
            <Link href="https://discord.gg/CRwAzT533">
              <Button isDisabled={discordInitialized}>Join our Discord!</Button>
            </Link>
          </Center>
        </>
      )}
    </>
  );
};

export default ApplicationStatus;

import React from 'react'

const PageTitle = ({title, description}) => {
  return (
    <>
      <div className='flex flex-col gap-4 w-[90%] lg:mx-16 mx-6 lg:my-8 my-4'>
        <div className="text-title font-extrabold mt-3 ss:p-0 pt-5">{title}</div>
        <div className='text-norm'>{description}</div>
      </div>
    </>
  )
}

export default PageTitle
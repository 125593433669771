import React from "react";
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  Text,
  Link,
  Flex,
  Stack,
} from "@chakra-ui/react";
import schedule from "./schedule";
import PageTitle from "../../Components/PageTitle";

const schedule_days = Object.keys(schedule);
const datetime_options = {
  hour: "numeric",
  minute: "2-digit",
};
const colours = {
  card: "#202934",
  card_selected: "#9b4587",
  text_selected: "#ffffff",
};

const Schedule = () => {
  return (
    <div className="flex flex-col items-center h-[100%] ss:p-10">
      <PageTitle
        title={"Schedule"}
        description={
          "Check out the schedule to see what we have in store for the weekend!"
        }
      />
      <Tabs size="lg" variant="solid-rounded">
        <Flex
          flexWrap={{ base: "wrap", md: "initial" }}
          justifyContent="center"
          maxW="100%"
        >
          <TabList alignItems="flex-start">
            <Stack
              direction={{ base: "row", md: "column" }}
              spacing={5}
              mb={2}
              bg={colours.card}
              rounded={15}
              p={2}
            >
              {schedule_days.map((day) => {
                return (
                  <Tab
                    rounded={15}
                    _selected={{
                      color: colours.text_selected,
                      bg: colours.card_selected,
                    }}
                    display="center"
                  >
                    May <Heading>{day}</Heading>
                  </Tab>
                );
              })}
            </Stack>
          </TabList>
          <div className="px-4">
            <TabPanels
              bg={colours.card}
              rounded={15}
              ml={{ base: 0, md: 5 }}
              maxW="2xl"
              minW="xxs"
            >
              {schedule_days.map((day) => {
                const events = Object.keys(schedule[day]);

                return (
                  <TabPanel>
                    {events.map((eventID) => {
                      const event = schedule[day][eventID];

                      return (
                        <Stack
                          direction={{ base: "column", md: "row" }}
                          bg="white"
                          p={3}
                          rounded={10}
                          mb={3}
                          borderLeftWidth={5}
                          borderColor={event.colour}
                        >
                          <div>
                            <Heading size="sm" color="black">
                              {event.title}
                            </Heading>
                            <Text color="black">
                              {event.startTime.toLocaleTimeString(
                                "en-us",
                                datetime_options
                              )}{" "}
                              -
                              {event.endTime.toLocaleTimeString(
                                "en-us",
                                datetime_options
                              )}
                            </Text>
                            <Text color="black">Where: {event.where}</Text>
                          </div>
                        </Stack>
                      );
                    })}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </div>
        </Flex>
      </Tabs>
    </div>
  );
};

export default Schedule;

import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Heading,
  Box,
  Center,
  Flex,
  Text,
  Image,
  Show,
} from "@chakra-ui/react";
import Countdown from "react-countdown";
import mascot_party from "../../Assets/mascot_party.png";
import mascot_cookie from "../../Assets/mascot_cookie.png";
import ApplicationStatus from "./Components/ApplicationStatus";
import PageTitle from "../../Components/PageTitle";

const Dashboard = () => {
  const [error, setError] = useState("");
  const { currentUser, logout, appFirstName } = useAuth();
  const navigate = useNavigate();

  const colours = {
    card: "#202934",
    accent: "#9b4587",
    text_selected: "#ffffff",
  };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      // Render a completed state
      return <Image src="" />;
    } else {
      // Render a countdown
      return (
        <Flex
          bg={colours.accent}
          rounded="lg"
          p={2}
          justifyContent="space-evenly"
          w={{ base: "100%", lg: "48vw" }}
          position="relative"
        >
          <Text textAlign="center">
            <Text as="b" fontSize="4xl">
              {days}
            </Text>{" "}
            days
          </Text>
          <Text textAlign="center">
            <Text as="b" fontSize="4xl">
              {hours}
            </Text>{" "}
            hours
          </Text>
          <Text textAlign="center">
            <Text as="b" fontSize="4xl">
              {minutes}
            </Text>{" "}
            minutes
          </Text>
          <Show above="sm">
            {/* <Box maxW="150px" position="absolute" bottom={-2} right={-10}>
            <Image src={mascot_cookie} />
          </Box> */}
            <Box maxW="110px" position="absolute" bottom={0} right={-35}>
              <Image src={mascot_party} />
            </Box>
          </Show>
        </Flex>
      );
    }
  };

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }
  const firstItemRef = useRef(null);
  const secondItemRef = useRef(null);

  useEffect(() => {
    if (firstItemRef.current && secondItemRef.current) {
      const firstItemHeight = firstItemRef.current.clientHeight;
      secondItemRef.current.style.height = `${firstItemHeight}px`;
    }
  }, []);

  return (
    <>
      <div className="text-white w-full ss:p-10 flex flex-col">
        <div className="w-full">
          <PageTitle
            title={`Welcome to MetHacks 2024`}
            description={
              "Be sure to submit your application to the event before April 15th 2024!"
            }
          />
        </div>

        <div className="flex flex-row item-center justify-center">
          <div className="w-[90%]">
            <Flex
              display={{ base: "block", lg: "flex" }}
              mb={3}
              justifyContent="space-evenly"
              p={2}
            >
              <Center w={{ base: "100%", lg: "200px" }}>
                <Text as="b" textAlign="center" p={2}>
                  OFFICIAL METHACKS COUNTDOWN
                </Text>
              </Center>
              <Countdown date="2024-05-03T14:30:00-04:00" renderer={renderer} />
            </Flex>
            <Box bg={colours.card} rounded="xl" p={10}>
              <Heading textAlign="center" size="lg">
                Application Status
              </Heading>
              <ApplicationStatus user={currentUser} />
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

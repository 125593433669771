import React, { useState, useEffect } from 'react'
import RequiredStar from "../../../Assets/RequiredStar.png"
import Select from "react-select";

const dropdownStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF'
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#FFFFFF',
    color: 'black',
  })
}
  

const OptionalInput = ({title, ph_name, getValue, setFunction, dropdownOptions}) => {
  const [dropdownValue, setDropdownValue] = useState("")
  const [disabled, setDisabled] = useState(true)
  const [optionalText, setOptionalText] = useState("")

  const listOfOther = ["Other (Please Specify)", "Prefer to self-describe", "Different identity (Please Specify)"]

  useEffect(() => {
    const exists = dropdownOptions.find(item => item.value === getValue);

    if (exists) {
      // console.log("Known value")
      setDropdownValue({value: getValue, label: getValue})
      if (
        getValue === "Other (Please Specify)" || 
        getValue === "Prefer to self-describe" || 
        getValue === "Different identity (Please Specify)"
      ) {
        setDisabled(false)
      } else {
        setOptionalText("")
        setDisabled(true)
      }
    } else if (getValue !== "") {
      // console.log(listOfOther)
      for (let i=0; i < listOfOther.length; i++) {
        // console.log(listOfOther[i])
        if (dropdownOptions.find(item => item.value == listOfOther[i])) {
          setDropdownValue({value: listOfOther[i], label: listOfOther[i]})
          setDisabled(false)
          setOptionalText(getValue)
          console.log("Enabled the optional field")
        }
      }
    }

  }, [])

  const handleDropdownChange = (values) => {
    setDropdownValue(values)
    if (
        values.label === "Other (Please Specify)" || 
        values.label === "Prefer to self-describe" || 
        values.label === "Different identity (Please Specify)"
    ) {
      setFunction(values.label)
      // console.log("Hi im over here the value is, ", values)
      setDisabled(false)
      //setOptionalText(getValue)
    } else {
      setOptionalText("")
      setDisabled(true)
      setFunction(values.label)
    }

  }

  return (
    <>
      <div className='flex flex-row'>
        <span className="text-white mb-2">
          {title}
        </span>
        <img src={RequiredStar} className='requiredStar-style'/>
      </div>
      <Select
        options={dropdownOptions} 
        labelField="label"
        valueField="value"
        value={dropdownValue} 
        onChange={(values) => {handleDropdownChange(values)}} 
        styles={dropdownStyles}
        placeholder={ph_name}
      />
      <div className='flex md:flex-row flex-col py-2 md:items-center'>
        <span className='text-white flex-shrink-0 mx-1'>
          If not listed above:
        </span>
        <input 
          className="flex flex-grow min-w-0 border rounded py-1 px-3 text-gray-700 focus:outline-none" 
          value={optionalText}
          onChange={(e) => {
            setOptionalText(e.target.value)
            setFunction(e.target.value)
            }}
          disabled={disabled}
        />
      </div>
    </>
  )
}

export default OptionalInput
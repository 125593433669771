const event_type = {
  sponsored: "#ef476f",
  main: "#46bdc6",
  fun: "#ffd580",
  general_workshop: "#cbc3e3",
};

const schedule = {
  /**   ********************************************************
   *
   *                  MAY 5
   *
   *
   */

  5: [
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Registration Begins",
      startTime: new Date("2024-05-05T14:30:00-04:00"),
      endTime: new Date("2022-05-06T17:00:00-04:00"),
      where: "TRS 1/ 7th Floor - In front of CARA Commons",
    },
    {
      colour: event_type.sponsored,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Open Sponsor Networking",
      startTime: new Date("2024-05-05T16:00:00-04:00"),
      endTime: new Date("2022-05-06T19:00:00-04:00"),
      where: "7th Floor Hallway",
    },
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Opening Ceremony",
      startTime: new Date("2024-05-05T17:30:00-04:00"),
      endTime: new Date("2022-05-06T19:00:00-04:00"),
      where: "1-067",
    },
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Hacking Starts!",
      startTime: new Date("2024-05-05T18:30:00-04:00"),
      endTime: new Date("2022-05-06T21:00:00-04:00"),
      where: "",
    },
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Dinner Begins",
      startTime: new Date("2024-05-05T19:00:00-04:00"),
      endTime: new Date("2022-05-06T23:00:00-04:00"),
      where: "CARA Commons *groups will be assigned a specific time",
    },
    {
      colour: event_type.general_workshop,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Resume Roast",
      startTime: new Date("2024-05-05T21:00:00-04:00"),
      endTime: new Date("2022-05-07T00:00:00-04:00"),
      where: "3-147",
    },
    {
      colour: event_type.fun,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Typing Contest",
      startTime: new Date("2024-05-05T22:30:00-04:00"),
      endTime: new Date("2022-05-06T20:00:00-04:00"),
      where: "3-147",
    },
    {
      colour: event_type.fun,
      url: "https://app.wonder.me/?spaceId=46efa791-6f05-47fb-b924-ba081c68d67c",
      title: "Movie Night",
      startTime: new Date("2024-05-05T23:00:00-04:00"),
      endTime: new Date("2022-05-06T18:30:00-04:00"),
      where: "2-147",
    },
  ],

  /**   ********************************************************
   *
   *                  MAY 6
   *
   *
   */

  6: [
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Breakfast Begins",
      startTime: new Date("2024-05-06T08:30:00-04:00"),
      endTime: new Date("2022-05-07T11:00:00-04:00"),
      where: "CARA Commons *groups will be assigned specific times",
    },
    {
      colour: event_type.sponsored,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "IBZ Infobesity",
      startTime: new Date("2024-05-06T11:00:00-04:00"),
      endTime: new Date("2022-05-07T12:00:00-04:00"),
      where: "2-147",
    },
    {
      colour: event_type.fun,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Check out Board Games",
      startTime: new Date("2024-05-06T12:00:00-04:00"),
      endTime: new Date("2022-05-07T13:00:00-04:00"),
      where: "Registration Desk",
    },
    {
      colour: event_type.main,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Lunch Begins",
      startTime: new Date("2024-05-06T13:00:00-04:00"),
      endTime: new Date("2022-05-07T14:00:00-04:00"),
      where: "CARA Commons *groups will be assigned specific times",
    },
    {
      colour: event_type.general_workshop,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "XRPL",
      startTime: new Date("2024-05-06T14:00:00-04:00"),
      endTime: new Date("2022-05-07T15:00:00-04:00"),
      where: "2-147",
    },
    {
      colour: event_type.general_workshop,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Intro to Cohere, LLM, and real-world application",
      startTime: new Date("2024-05-06T15:00:00-04:00"),
      endTime: new Date("2022-05-07T14:00:00-04:00"),
      where: "2-147",
    },
    {
      colour: event_type.fun,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Carnival games and cotton candy!",
      startTime: new Date("2024-05-06T16:00:00-04:00"),
      endTime: new Date("2024-05-06T18:00:00-04:00"),
      where: "9th floor landing",
    },
    {
      colour: event_type.general_workshop,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "High School Hacker Meetup",
      startTime: new Date("2024-05-06T16:15:00-04:00"),
      endTime: new Date("2022-05-07T21:00:00-04:00"),
      where: "2-149",
    },
    {
      colour: event_type.sponsored,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Bob Ross by MLH",
      startTime: new Date("2024-05-06T17:00:00-04:00"),
      endTime: new Date("2022-05-07T16:00:00-04:00"),
      where: "2-149",
    },
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Dinner Begins",
      startTime: new Date("2024-05-06T18:00:00-04:00"),
      endTime: new Date("2022-05-07T18:00:00-04:00"),
      where: "CARA Commons *groups will be assigned specific times",
    },
    {
      colour: event_type.sponsored,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Pitching Workshop MLH",
      startTime: new Date("2024-05-06T19:00:00-04:00"),
      endTime: new Date("2022-05-07T17:00:00-04:00"),
      where: "2-147",
    },
    {
      colour: event_type.fun,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Cup Stacking Competition",
      startTime: new Date("2024-05-06T20:00:00-04:00"),
      endTime: new Date("2022-05-07T19:00:00-04:00"),
      where: "2-149",
    },
    {
      colour: event_type.fun,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Family Feud",
      startTime: new Date("2024-05-06T23:00:00-04:00"),
      endTime: new Date("2022-05-07T23:30:00-04:00"),
      where: "3-147",
    },
    {
      colour: event_type.fun,
      url: "https://discord.com/channels/928825084297244692/969038903577296948",
      title: "Buldak Noodle Competition",
      startTime: new Date("2024-05-07T00:00:00-04:00"),
      endTime: new Date("2022-05-07T23:00:00-04:00"),
      where: "CARA Commons (where food is served)",
    },
  ],

  /**   ********************************************************
   *
   *                  MAY 7
   *
   *
   */

  7: [
    {
      colour: event_type.main,
      url: "https://ru-hacks-2022-digital-15171.devpost.com/",
      title: "Brunch Begins",
      startTime: new Date("2024-05-07T09:00:00-04:00"),
      endTime: new Date("2022-05-08T10:00:00-04:00"),
      where: "CARA Commons *groups will be assigned specific times",
    },
    {
      colour: event_type.main,
      url: "/schedule",
      title: "SUBMISSIONS CLOSE",
      startTime: new Date("2024-05-07T10:00:00-04:00"),
      endTime: new Date("2022-05-08T14:30:00-04:00"),
      where: "",
    },
    {
      colour: event_type.main,
      url: "https://hopin.com/events/ru-hacks-2022",
      title: "Judging Period Begins (Science Fair Style)",
      startTime: new Date("2024-05-07T10:30:00-04:00"),
      endTime: new Date("2022-05-08T15:30:00-04:00"),
      where: "*your group's room will be assigned the day of",
    },
    {
      colour: event_type.main,
      url: "https://app.wonder.me/?spaceId=46efa791-6f05-47fb-b924-ba081c68d67c",
      title: "Closing Ceremony",
      startTime: new Date("2024-05-07T15:00:00-04:00"),
      endTime: new Date("2022-05-08T12:00:00-04:00"),
      where: "1-067",
    },
  ],
};

export default schedule;

import React, {useState} from 'react'
import CalenderIcon from "../Assets/CalenderIcon.png"
import FormIcon from "../Assets/FormIcon.png"
import HomeIcon from "../Assets/HomeIcon.png"
import InformationIcon from "../Assets/InformationIcon.png"
import LogoutIcon from "../Assets/LogoutIcon.png"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../Contexts/AuthContext"
import MetHacksLogo from "../Assets/MetHacksLogoSmall.png"
import MenuIcon from "../Assets/MenuIcon.png"
import LeftArrowIcon from "../Assets/LeftArrowIcon.png"

const Item = ({ title, to, icon, selected, setSelected }) => {
  return ( 
    <Link to={to}>
      <li 
        className={`items-center py-5 mr-5 ml-3 my-3 ${selected === title ? 'bg-primary-600' : 'bg-transparent'} hover:bg-primary-500 transition-colors rounded-[20px] cursor-pointer`}
        onClick={() => setSelected(title)}
      >
        <div className={`flex`}>
          <img src={icon} className="ml-5 mr-5 w-[30px] h-[30px]"/>
          <span class="text-[20px] text-gray-300 font-poppins">{title}</span>
        </div>
      </li>
    </Link>
  )
}

const ItemMini = ({ title, to, icon, selected, setSelected }) => {
  return ( 
    <Link to={to}>
    <div className='group'>
      <li 
        className={`items-center p-3 mr-5 ml-3 my-3 ${selected === title ? 'bg-primary-600' : 'bg-transparent'} hover:bg-primary-500 transition-colors rounded-[20px] cursor-pointer`}
        onClick={() => setSelected(title)}
      >
        <img src={icon} className="m-auto w-[30px] h-[30px]"/>
        <span className='sidebar-tooltip group-hover:scale-100'>
          {title}
        </span>
      </li>
    </div>  
    </Link>
  )
}

const Sidebar = () => {
  const [selectedPage, setSelectedPage] = useState("Application")
  const [toggle, setToggle] = useState(false)
  const { logout } = useAuth()
  const navigate = useNavigate()
  const [error, setError] = useState("")

  const handleLogout = async() => {
    setError("")

    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  return (
    <div className="ss:h-screen h-[50px] ss:w-[100px] lg:w-[300px] w-full fixed z-10 text-white font-Rubik">
      <div className="lg:flex lg:flex-col hidden bg-[#141a27] h-screen">
        <div class="flex flex-col bg-[#141a27] px-4 py-2 border-b border-primary-200">
          <div class="flex my-7 px-3">
            <img src={MetHacksLogo} alt="MetHacks-Logo" className="m-auto w-[70px] h-[70px]"/>
            <span className="ml-10 text-subHeader font-semibold">MetHacks Dashboard</span>
          </div>
          <div className="text-center py-2 px-4 bg-[#9b4587] rounded-[20px] mb-7 font-bold text-header">
            Hacker
          </div>
        </div>
        <ul className="list-none px-5 my-5 overflow-auto">
          <Item
            title="General"
            to="/"
            icon={HomeIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          />
          {/* <Item
            title="Schedule"
            to="/schedule"
            icon={CalenderIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          /> */}
          <Item
            title="Application"
            to="/application"
            icon={FormIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          />
          {/* <Item
            title="Hacker Guide"
            to="/hacker-guide"
            icon={InformationIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          /> */}
        </ul>
        <div className="bottomSideBar mt-auto mb-5 flex flex-col gap-3 items-center">
          <button onClick={() => {handleLogout()}}>
            <div className="flex flex-row items-center py-[10px] px-10 bg-[#4d2243] hover:bg-[#3a1a32] rounded-[10px] mb-7">
              <img src={LogoutIcon} className='w-[30px] h-[30px] cursor-pointer'/>
              <span className="ml-4 text-norm font-bold">Logout</span>
            </div>
          </button>
        </div>
      </div>

      <div className="flex-col lg:block ss:block hidden bg-[#141a27] h-screen font-Rubik">
        <div class="flex flex-col bg-[#141a27] px-4 py-2 border-b border-primary-200">
          <div class="flex my-5">
            <img src={MetHacksLogo} alt="MetHacks-Logo" className="m-auto w-[50px] h-[50px]"/>
          </div>
        </div>
        <ul className="list-none my-5 overflow-auto">
          <ItemMini
            title="General"
            to="/"
            icon={HomeIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          />
          {/* <ItemMini
            title="Schedule"
            to="/schedule"
            icon={CalenderIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          /> */}
          <ItemMini
            title="Application"
            to="/application"
            icon={FormIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          />
          {/* <ItemMini
            title="Hacker Guide"
            to="/hacker-guide"
            icon={InformationIcon}
            selected={selectedPage}
            setSelected={setSelectedPage}
          /> */}
        </ul>
        <div className="bottomSideBar mt-auto mb-5 flex flex-col gap-3 items-center">
          <button onClick={() => {handleLogout()}}>
            <div className="flex flex-row items-center py-2 px-2 bg-[#4d2243] hover:bg-[#3a1a32] rounded-[10px] mb-7">
              <img src={LogoutIcon} className='w-[30px] h-[30px] cursor-pointer'/>
            </div>
          </button>
        </div>
      </div>

      <div className="ss:hidden w-full">
        <div className='bg-[#141a27] w-full flex flex-row justify-between items-center py-3 px-10'>
          <img 
            src={MenuIcon} 
            alt="menu"
            className="w-[28px] h-[28px] cursor-pointer"
            onClick={() => setToggle((prev) => !prev)}
          />
          <img 
            src={MetHacksLogo} 
            alt="logo" 
            className="w-[45px] h-[45px]" 
          />
        </div>
        <div className={`bg-[#121724] text-white h-screen w-[70%] fixed top-0 left-0 z-[5] transform ${toggle ? "translate-x-0" : "-translate-x-full"} transition-all duration-300 ease-in-out flex flex-col`}>
          <div>
            <div className="flex w-full justify-end items-center pt-3 px-4">
              <img src={LeftArrowIcon} className='h-[32px] w-[32px] p-1 cursor-pointer' onClick={() => setToggle(!toggle)}/>
            </div>
            <div className="flex flex-col bg-[#121724] h-screen">
            <div class="flex flex-col bg-[#121724] px-4 py-2 border-b border-primary-200">
              <div class="flex my-7 px-3">
                <img src={MetHacksLogo} alt="MetHacks-Logo" className="m-auto w-[70px] h-[70px]"/>
                <span className="ml-10 text-subHeader font-semibold">MetHacks Dashboard</span>
              </div>
              <div className="text-center py-2 px-4 bg-[#9b4587] rounded-[20px] mb-7 font-bold text-header">
                Hacker
              </div>
            </div>
            <ul className="list-none px-5 my-5 overflow-auto">
              <Item
                title="General"
                to="/"
                icon={HomeIcon}
                selected={selectedPage}
                setSelected={setSelectedPage}
              />
              {/* <Item
                title="Schedule"
                to="/schedule"
                icon={CalenderIcon}
                selected={selectedPage}
                setSelected={setSelectedPage}
              /> */}
              <Item
                title="Application"
                to="/application"
                icon={FormIcon}
                selected={selectedPage}
                setSelected={setSelectedPage}
              />
              {/* <Item
                title="Hacker Guide"
                to="/hacker-guide"
                icon={InformationIcon}
                selected={selectedPage}
                setSelected={setSelectedPage}
              /> */}
            </ul>
            <div className="bottomSideBar mt-auto mb-16 flex flex-col gap-3 items-center">
              <button onClick={() => {handleLogout()}}>
                <div className="flex flex-row items-center py-[10px] px-10 bg-[#4d2243] hover:bg-[#3a1a32] rounded-[10px] mb-20">
                  <img src={LogoutIcon} className='w-[30px] h-[30px] cursor-pointer'/>
                  <span className="ml-4 text-norm font-bold">Logout</span>
                </div>
              </button>
            </div>
          </div>
          </div>
          <div className="bottomSideBar m-auto h-1/6 flex flex-col gap-3 items-center">
            {/* <a href="https://www.google.com/" target="_blank">
              <img src={twitter} className='w-[24px] h-[24px] cursor-pointer'/>
            </a> */}
            {/* <h6 className='font-sans text-zinc-500'></h6> */}
          </div>
        </div>
      </div>

    </div>
  )
}

export default Sidebar

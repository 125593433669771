import React from "react";
import { Link, useNavigate } from "react-router-dom";
import MetHacksLogo from "../../Assets/MetHacksLogoSmall.png"
import MascotComputer from "../../Assets/mascot_computer.png"
import MascotParty from "../../Assets/mascot_party.png"

const RegisterConfirm = () => {
  const navigate = useNavigate()


  return (
    <>
      <div className="lg:h-[100vh] md:m-0">
        <div className="flex flex-col item-center justify-center lg:h-full lg:my-0 my-10 lg:px-24">
          <div className="flex lg:flex-row flex-col w-full items-center justify-center">
            
            <div className="lg:w-[50%] w-[90%] lg:px-20">
              <div className="flex flex-col justify-center item-center h-full lg:gap-5 gap-1">
                <img src={MascotComputer} className="w-[70%] lg:block hidden"/>
                <div className="flex flex-row items-center justify-center">
                  <img src={MetHacksLogo} className="md:w-[100px] w-[75px] lg:hidden"/>
                </div>
                <div className="font-bold text-title text-center lg:text-start">
                  Welcome to the <br /> MetHacks Portal
                </div>
                <div className="text-header text-center lg:text-start md:block hidden">
                  Register to view your application status, hackathon schedule, and more!
                </div>
              </div>
            </div>

            <div className="lg:w-[50%] w-[100%] flex items-center justify-center lg:my-0 my-8">
              <div className="lg:w-[100%] w-[90%] lg:p-10">
                  <div className="rounded-3xl dark-Box lg:px-20 px-8 lg:py-12 py-6">
                    <div className="text-center font-bold text-bigHeader lg:mb-4 mb-2">
                      Thank you for Registering
                    </div>
                    <div className="text-header text-center">
                      Please Check your Email to Verify your Account
                    </div>
                    <div className="flex flex-row item-center justify-center">
                      <img src={MascotParty} className="w-[50%]"/>
                    </div>
                    <button
                      className="bg-[#da6dc0] hover:bg-[#a85194] text-norm text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-8"
                      onClick={() => {
                        navigate("/login")
                      }}
                    >
                      Go to Login
                    </button>
                  </div>
              </div>
            </div>
            <div className="text-header text-center w-[90%] mb-10 lg:hidden">
              Register to view your application status, hackathon schedule, and more!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterConfirm;

import React, { useState, useEffect } from 'react'
import AppNavbar from './Components/AppNavbar'
import PageTitle from '../../Components/PageTitle'
import './index.css'
import General from './Pages/General'
import LongQs from './Pages/LongQs'
import Final from './Pages/Final'
import Loading from './Pages/Loading'
import Complete from './Pages/Complete'
import { useQuestionaire } from '../../Contexts/QuestionaireContext'
import { useAuth } from '../../Contexts/AuthContext'
import { auth } from '../../firebase'

const Application = () => {
  const { appPage } = useQuestionaire()
  const [ pageToShow, setPageToShow ] = useState(null)
  const [ loading, setLoading ] = useState(false)
  const { checkAppComplete, appComplete, setAppComplete} = useAuth()

  const getAppStatus = async () => {
    const status = await checkAppComplete(auth?.currentUser?.uid)
    //console.log(status)
    setAppComplete(status)
    setLoading(false)
  }
  
  useEffect(() => {
    getAppStatus()
  }, [])

  useEffect(() => {
    if (appPage.get === 0) {
      setPageToShow(<General />)
    } else if (appPage.get === 1) {
      setPageToShow(<LongQs />)
    } else if (appPage.get === 2) {
      setPageToShow(<Final />)
    } 
  }, [appPage])

  return (
    <>
      {/* <button 
        onClick={() => {console.log("What is the app page", appPage.get)}}
        className='bg-red-200'
      >
        Testing
      </button> */}
      <div className='flex flex-col items-center h-[100%] ss:p-10'>
        <PageTitle title={"Hackathon Application"} description={"Be sure to submit your application to the event before May 4th 2024!"}/>
        {/* {loading ? (<Loading/>) : null} */}
        {(loading) ? (<></>) : null}
        {(!loading && !appComplete) ? (
          <>
            <div className='justify-center items-center mt-12 flex'>
              <AppNavbar />
            </div>
            <div className='flex flex-col ss:py-10 rounded-3xl dark-Box w-[80%] text-norm items-center'>
              {pageToShow}
            </div>
          </>
        ) : null}
        {(!loading && appComplete) ? (<Complete/>) : null}
      </div>
    </>
  )
}

export default Application
import React from 'react'
import RequiredStar from "../../../Assets/RequiredStar.png"

const DateField = ({title, getValue, setFunction}) => {
  const currentDate = new Date().toISOString().split('T')[0];
  
  return (
    <>
      <div className='flex flex-row'>
        <label className="text-white mb-2">
          {title}
        </label>
        <img src={RequiredStar} className='requiredStar-style'/>
      </div>
      <input 
        type="date"
        className="textInput-style"
        value={getValue}
        onChange={(e) => setFunction(e.target.value)}
        max={currentDate}
        required 
      />
    </>
  )
}

export default DateField
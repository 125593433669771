import React, { useRef, useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import MetHacksLogo from "../../Assets/MetHacksLogoSmall.png"
import MascotComputer from "../../Assets/mascot_computer.png"

const Register = () => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    // console.log(emailRef.current.value)
    // console.log(passwordRef.current.value)
    // console.log(passwordConfirmRef.current.value)
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    } 

    if (passwordRef.current.value.length < 7 ) {
      return setError("Password must exceed 6 Characters")
    } 

    try {
      setError('')
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      navigate('/register-confirm')
    } catch {
      setError("Failed to create an account")
    }

    setLoading(false)

  }

  return (
    <>
      <div className="lg:h-[100vh] md:m-0">
        <div className="flex flex-col item-center justify-center lg:h-full lg:my-0 my-10 lg:px-24">
          <div className="flex lg:flex-row flex-col w-full items-center justify-center">
            
            <div className="lg:w-[50%] w-[90%] lg:px-20">
              {/* <button 
                className="md:flex hidden flex-row w-full gap-1 text-white"
              >
                <img src={BackArrow} className="h-[24x]"/>
                Back
              </button> */}
              <div className="flex flex-col justify-center item-center h-full lg:gap-5 gap-1">
                <img src={MascotComputer} className="w-[70%] lg:block hidden"/>
                <div className="flex flex-row items-center justify-center">
                  <img src={MetHacksLogo} className="md:w-[100px] w-[75px] lg:hidden"/>
                </div>
                <div className="font-bold text-title text-center lg:text-start">
                  Welcome to the <br /> MetHacks Portal
                </div>
                <div className="text-header text-center lg:text-start md:block hidden">
                  Register to view your application status, hackathon schedule, and more!
                </div>
              </div>
            </div>

            <div className="lg:w-[50%] w-[100%] flex items-center justify-center lg:my-0 my-8">
              <div className="lg:w-[100%] w-[90%] lg:px-10">
                <form onSubmit={handleSubmit}>
                  <div className="rounded-3xl dark-Box lg:px-20 px-8 lg:py-12 py-6">
                    <div className="text-center font-bold text-bigHeader lg:mb-12 mb-8">
                      Register
                    </div>
                    {error && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">{error}</div>}
                    <div className="mb-4 flex flex-col">
                      <label className="text-norm mb-2">
                        Email
                      </label>
                      <input 
                        className="rounded-2xl w-full p-4 text-norm bg-[#222d4d] placeholder-[#475377] outline-none"
                        type="email" 
                        ref={emailRef}
                        placeholder="HelloWorld@email.com"
                        required />
                    </div>
                    <div className="mb-4 flex flex-col">
                      <label className="text-norm mb-2">
                        Password
                      </label>
                      <input 
                        className="rounded-2xl w-full p-4 text-norm bg-[#222d4d] placeholder-[#475377] outline-none"
                        type="password" 
                        ref={passwordRef}
                        placeholder="*****"
                        required />
                    </div>
                    <div className="mb-4 flex flex-col">
                      <label className="text-norm mb-2">
                        Password Confirm
                      </label>
                      <input 
                        className="rounded-2xl w-full p-4 text-norm bg-[#222d4d] placeholder-[#475377] outline-none"
                        type="password" 
                        ref={passwordConfirmRef}
                        placeholder="*****"
                        required />
                    </div>

                    <div className='flex lg:flex-row flex-col justify-between mt-[30px] items-center'>
                      <div className='lg:w-[70%] w-full flex flex-col'>
                        <span className="text-norm text-center">Already have an Account?</span>
                        <Link className="text-norm underline text-[#da6dc0] hover:text-[#a85194] text-center" to="/login">Login Here</Link>
                      </div>
                      <div className="ml-4">
                        <button
                          className="bg-[#60A0C5] hover:bg-[#4c7f9c] text-norm text-black  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full lg:block hidden"
                          type="submit"
                          disabled={loading}
                        >
                          Register
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className="bg-[#da6dc0] hover:bg-[#a85194] text-norm text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-8 lg:hidden"
                    type="submit"
                    disabled={loading}
                  >
                    Register
                  </button>
                </form>
              </div>
            </div>
            <div className="text-header text-center w-[90%] mb-10 lg:hidden">
              Register to view your application status, hackathon schedule, and more!
            </div>
          </div>
        </div>
      </div>
    </>
    // <>
    //   <div className="flex items-center justify-center h-screen">
    //     <div className="h-[100vh] w-[50%] p-[45px] flex items-start justify-center flex-col">
    //       <div className='mb-8 w-[50%]'>
    //         <img src={MetHacksLogo}/>
    //       </div>
    //       <div className="text-white">
    //         <h1 className="font-Rubik font-bold text-4xl mb-4">
    //           Welcome to the <br /> MetHacks Portal
    //         </h1>
    //         <div className="font-Rubik text-2xl mb-4">
    //           Register to view your application status, hackathon schedule, and more!
    //         </div>
    //       </div>
    //     </div>

    //     <div className="h-[100vh] w-[50%] flex items-center justify-center">
    //       <div className="w-[100%] p-[45px]">
    //         <div className="rounded-lg color-Box w-[100%] p-4">
    //           <div className="p-4">
    //             <div className="text-center text-white font-Rubik font-bold text-[30px] mb-4">
    //               Register
    //             </div>
    //             {error && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">{error}</div>}
    //             <form onSubmit={handleSubmit}>
    //               <div className="mb-4 flex flex-col">
    //                 <label className="text-white font-Rubik mb-2">
    //                   Email
    //                 </label>
    //                 <input 
    //                   className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none"
    //                   type="email" 
    //                   ref={emailRef}
    //                   required />
    //               </div>
    //               <div className="mb-4 flex flex-col">
    //                 <label className="text-white font-Rubik mb-2">
    //                   Password
    //                 </label>
    //                 <input 
    //                   className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none"
    //                   type="password" 
    //                   ref={passwordRef}
    //                   required />
    //               </div>
    //               <div className="mb-4 flex flex-col">
    //                 <label className="text-white font-Rubik mb-2">
    //                   Password Confirmation
    //                 </label>
    //                 <input 
    //                   className="border rounded w-full py-2 px-3 text-gray-700 focus:outline-none"
    //                   type="password" 
    //                   ref={passwordConfirmRef}
    //                   required />
    //               </div>

    //               <div className='flex flex-row justify-between mt-[30px] items-center'>
    //                 <div className='w-[70%] flex flex-col'>
    //                   <span className="text-white text-center">Already have an account?</span>
    //                   <Link className="text-blue-300 underline text-center" to="/login">Log In</Link>
    //                 </div>
    //                 <div>
    //                   <button
    //                     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
    //                     type="submit"
    //                     disabled={loading}
    //                   >
    //                     Register
    //                   </button>
    //                 </div>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default Register;

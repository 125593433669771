import React from 'react'
import MetHacks from "../Assets/MetHacksLogo.png"
import { faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = () => {
  return (
    <>
      <div className="flex flex-col gap-3 text-white mb-8">

        <div className="flex md:flex-row gap-3 flex-col justify-evenly items-center">
          <img src={MetHacks} style={{ width: "225px", }} alt="MetHacks LOGO" />
          <p id="cc">© MetHacks 2024</p>
          <a href="mailto:communications@ruhacks.com">communications@ruhacks.com</a>
          <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf">MLH Code of Conduct</a>
        </div>

        <div className="flex flex-row justify-center gap-3 items-center">
          <a className="text-subHeader" href="https://www.instagram.com/methacks_/"><FontAwesomeIcon icon={faInstagram} size="lg" /></a>
          <a className="text-subHeader" href="https://www.linkedin.com/company/methacks/"><FontAwesomeIcon icon={faLinkedin} size="lg" /></a>
          <a className="text-subHeader" href="https://www.facebook.com/methacks/?paipv=0&eav=AfayAK2uI9owsAxadMZVWQJ4QOolEGUDMf0B-7UjLSUI9nn9TnTi48ELOQog3X_clfw&_rdr" ><FontAwesomeIcon icon={faFacebook} size="lg" /></a>
        </div>

      </div>
    </>
  )
}

export default Footer
import React, { useState, useEffect } from 'react'
import RequiredStar from "../../../Assets/RequiredStar.png"
import Select from "react-select";

const dropdownStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#FFFFFF',
      color: 'black',
    })
  }
  

const DropdownField = ({title, ph_name, getValue, setFunction, dropdownOptions, labelField="label", valueField="value"}) => {
  const [dropdownValue, setDropdownValue] = useState("")

  useEffect(() => {
    setDropdownValue({value: getValue, label: getValue})
  }, [])

  const handleDropdownChange = (values) => {
    setDropdownValue(values)
    setFunction(values.label)
  }

  return (
    <>
      <div className='flex flex-row'>
        <label className="text-white mb-2">
          {title}
        </label>
        <img src={RequiredStar} className='requiredStar-style'/>
      </div>
      <Select
        options={dropdownOptions} 
        labelField={labelField}
        valueField={valueField}
        value={dropdownValue} 
        onChange={(values) => {handleDropdownChange(values)}} 
        styles={dropdownStyles}
        placeholder={ph_name}
      />
    </>
  )
}

export default DropdownField
import React, { useContext, useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [appFirstName, setAppFirstName] = useState("");
  const [loading, setLoading] = useState(true);
  const [appComplete, setAppComplete] = useState(false);

  const appRef = collection(db, "applications");

  const checkAppComplete = async (docID) => {
    const q = query(
      collection(db, "applications"),
      where("userID", "==", docID)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      console.log("Document Data:", doc.data()["firstName"]);
      setAppFirstName(doc.data()["firstName"]);
    });
    return !querySnapshot.empty;
  };


  const signup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      await sendEmailVerification(user);
  
      // console.log('Verification email sent to:', user.email);
    } catch (error) {
      // console.error('Error signing up:', error.message);
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (user.emailVerified) {
        // console.log('Logged in successfully:', user.email);
        await setIsEmailVerified(true)
        return true
      } else {
        // console.log('Email not verified. Please check your inbox for a verification email.');
      }
    } catch (error) {
      // console.error('Error logging in:', error.message);
      return false
    }
  };
  

  const logout = async () => {
    await signOut(auth);
  };

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(auth, email);
  };

  const updateEmail = async (email) => {
    await updateEmail(currentUser, email);
  };

  const updatePassword = async (password) => {
    await updatePassword(currentUser, password);
  };

  useEffect(() => {
    const authInstance = getAuth();
    const unsubscribe = onAuthStateChanged(authInstance, (user) => {
      if (user) {
        setCurrentUser(user);
        setIsEmailVerified(user.emailVerified);
        setLoading(false);
      } else {
        setCurrentUser(null);
        setIsEmailVerified(false);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    isEmailVerified,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    appRef,
    checkAppComplete,
    appFirstName,
    appComplete,
    setAppComplete
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

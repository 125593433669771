const Schools = [
    {
      label: "School Not Listed",
      value: "School Not Listed",
    },
    {
      label: "21st Century Cyber Charter School",
      value: "21st Century Cyber Charter School",
    },
    {
      label: "Aalto University",
      value: "Aalto University",
    },
    {
      label: "Aarhus University",
      value: "Aarhus University",
    },
    {
      label: "Abbey Park High School",
      value: "Abbey Park High School",
    },
    {
      label: "Abbey Park Middle School",
      value: "Abbey Park Middle School",
    },
    {
      label: "Abertay University",
      value: "Abertay University",
    },
    {
      label: "ABES Engineering College",
      value: "ABES Engineering College",
    },
    {
      label: "Abington Senior High School",
      value: "Abington Senior High School",
    },
    {
      label: "Abraham Lincoln High School",
      value: "Abraham Lincoln High School",
    },
    {
      label: "Abraham Lincoln High School - Philadelphia",
      value: "Abraham Lincoln High School - Philadelphia",
    },
    {
      label: "Academy at Palumbo",
      value: "Academy at Palumbo",
    },
    {
      label: "Academy of Technology",
      value: "Academy of Technology",
    },
    {
      label: "Acardia High School, Arizona",
      value: "Acardia High School, Arizona",
    },
    {
      label: "Achariya College of Engineering Technology",
      value: "Achariya College of Engineering Technology",
    },
    {
      label: "Acharya Institute of Technology",
      value: "Acharya Institute of Technology",
    },
    {
      label: "Acharya Institute of Technology (AIT)",
      value: "Acharya Institute of Technology (AIT)",
    },
    {
      label: "Acharya Narendra Dev College, University Of Delhi",
      value: "Acharya Narendra Dev College, University Of Delhi",
    },
    {
      label: "Achievement House Charter School - Online",
      value: "Achievement House Charter School - Online",
    },
    {
      label: "Acropolis Institute of Technology & Research",
      value: "Acropolis Institute of Technology & Research",
    },
    {
      label: "ACT Academy Cyber Charter School",
      value: "ACT Academy Cyber Charter School",
    },
    {
      label: "Acton-Boxborough Regional High School",
      value: "Acton-Boxborough Regional High School",
    },
    {
      label: "Adelphi University",
      value: "Adelphi University",
    },
    {
      label:
        "Aditya Institute of Technology and Management (AITAM College, Tekkali)",
      value:
        "Aditya Institute of Technology and Management (AITAM College, Tekkali)",
    },
    {
      label: "Adlai E. Stevenson High School",
      value: "Adlai E. Stevenson High School",
    },
    {
      label: "Advanced Math and Science Academy Charter School",
      value: "Advanced Math and Science Academy Charter School",
    },
    {
      label: "AGH University of Science and Technology",
      value: "AGH University of Science and Technology",
    },
    {
      label: "Agnes Scott College",
      value: "Agnes Scott College",
    },
    {
      label: "Agora Cyber Charter School",
      value: "Agora Cyber Charter School",
    },
    {
      label: "Alagappa Chettiar Government College of Engineering and Technology",
      value: "Alagappa Chettiar Government College of Engineering and Technology",
    },
    {
      label: "Alagappa College of Technology, Anna University",
      value: "Alagappa College of Technology, Anna University",
    },
    {
      label: "Alameda High School",
      value: "Alameda High School",
    },
    {
      label: "Albany Medical College",
      value: "Albany Medical College",
    },
    {
      label: "Albany State University (GA)",
      value: "Albany State University (GA)",
    },
    {
      label: "Albertian Institute of Science and Technology (AISAT)",
      value: "Albertian Institute of Science and Technology (AISAT)",
    },
    {
      label: "Albright College",
      value: "Albright College",
    },
    {
      label: "Alfa College",
      value: "Alfa College",
    },
    {
      label: "Aligarh Muslim University",
      value: "Aligarh Muslim University",
    },
    {
      label: "Allen High School",
      value: "Allen High School",
    },
    {
      label: "Alwar Institute of Engineering and Technology (AIET)",
      value: "Alwar Institute of Engineering and Technology (AIET)",
    },
    {
      label: "Ambala College of Engineering and Applied Research",
      value: "Ambala College of Engineering and Applied Research",
    },
    {
      label:
        "Ambedkar Institute of Advanced Communication Technologies and Research (AIACTR)",
      value:
        "Ambedkar Institute of Advanced Communication Technologies and Research (AIACTR)",
    },
    {
      label: "AMC Engineering College",
      value: "AMC Engineering College",
    },
    {
      label: "American Heritage School",
      value: "American Heritage School",
    },
    {
      label: "American High School",
      value: "American High School",
    },
    {
      label: "American River College, California",
      value: "American River College, California",
    },
    {
      label: "American University in Dubai",
      value: "American University in Dubai",
    },
    {
      label: "American University, Washington, D.C.",
      value: "American University, Washington, D.C.",
    },
    {
      label: "Amherst College",
      value: "Amherst College",
    },
    {
      label: "Amity School of Engineering and Technology",
      value: "Amity School of Engineering and Technology",
    },
    {
      label: "Amity University",
      value: "Amity University",
    },
    {
      label: "Amrita School of Engineering",
      value: "Amrita School of Engineering",
    },
    {
      label: "Amritsar College of Engineering & Technology",
      value: "Amritsar College of Engineering & Technology",
    },
    {
      label: "Anand Institute of Higher Technology",
      value: "Anand Institute of Higher Technology",
    },
    {
      label: "Ancaster High School",
      value: "Ancaster High School",
    },
    {
      label: "Anchor Bay High School",
      value: "Anchor Bay High School",
    },
    {
      label: "Andhra University College of Engineering",
      value: "Andhra University College of Engineering",
    },
    {
      label: "Andover Central High School",
      value: "Andover Central High School",
    },
    {
      label: "Angadi Institute of Technology & Management (AITM)",
      value: "Angadi Institute of Technology & Management (AITM)",
    },
    {
      label: "Anil Neerukonda Institute of Technology and Sciences",
      value: "Anil Neerukonda Institute of Technology and Sciences",
    },
    {
      label: "Anjalai Ammal Mahalingam Engineering College",
      value: "Anjalai Ammal Mahalingam Engineering College",
    },
    {
      label: "Anna University",
      value: "Anna University",
    },
    {
      label: "Ansal Technical Campus, Dr. A.P.J Abdul Kalam Technical University",
      value: "Ansal Technical Campus, Dr. A.P.J Abdul Kalam Technical University",
    },
    {
      label: "Anurag University, Ghatkesar",
      value: "Anurag University, Ghatkesar",
    },
    {
      label: "Apeejay Stya University",
      value: "Apeejay Stya University",
    },
    {
      label: "APPA Institute of Engineering and Technology",
      value: "APPA Institute of Engineering and Technology",
    },
    {
      label: "Appalachian State University",
      value: "Appalachian State University",
    },
    {
      label: "APS College of Engineering",
      value: "APS College of Engineering",
    },
    {
      label: "Aravali Institute of Technical Studies",
      value: "Aravali Institute of Technical Studies",
    },
    {
      label: "Arcadia High School, California",
      value: "Arcadia High School, California",
    },
    {
      label: "Arcadia University",
      value: "Arcadia University",
    },
    {
      label: "Arizona State University",
      value: "Arizona State University",
    },
    {
      label: "Army Institute Of Technology, Pune",
      value: "Army Institute Of Technology, Pune",
    },
    {
      label: "Art Institute of Philadelphia",
      value: "Art Institute of Philadelphia",
    },
    {
      label: "Arya College of Engineering & I.T.",
      value: "Arya College of Engineering & I.T.",
    },
    {
      label: "Ashoka Institute of Technology and Management",
      value: "Ashoka Institute of Technology and Management",
    },
    {
      label: "Asia Pacific Institute of Information Technology Panipat",
      value: "Asia Pacific Institute of Information Technology Panipat",
    },
    {
      label: "Asia Pacific University of Information & Technology, Kuala Lumpur",
      value: "Asia Pacific University of Information & Technology, Kuala Lumpur",
    },
    {
      label: "Asian School of Business Management (ASBM University)",
      value: "Asian School of Business Management (ASBM University)",
    },
    {
      label: "ASPIRA Bilingual Cyber Charter School",
      value: "ASPIRA Bilingual Cyber Charter School",
    },
    {
      label: "Assam Downtown University",
      value: "Assam Downtown University",
    },
    {
      label: "Assam Engineering College",
      value: "Assam Engineering College",
    },
    {
      label: "Assam University, Silchar",
      value: "Assam University, Silchar",
    },
    {
      label: "Aston University",
      value: "Aston University",
    },
    {
      label:
        "Atal Bihari Vajpayee Indian Institute of Information Technology and Management, Gwalior (ABV-IIITM Gwalior)",
      value:
        "Atal Bihari Vajpayee Indian Institute of Information Technology and Management, Gwalior (ABV-IIITM Gwalior)",
    },
    {
      label: "Atlanta Metropolitan State College",
      value: "Atlanta Metropolitan State College",
    },
    {
      label: "Atlantic Cape Community College",
      value: "Atlantic Cape Community College",
    },
    {
      label: "Atma Ram Sanatan Dharma College",
      value: "Atma Ram Sanatan Dharma College",
    },
    {
      label: "ATME College of Engineering",
      value: "ATME College of Engineering",
    },
    {
      label: "Atria Institute of Technology",
      value: "Atria Institute of Technology",
    },
    {
      label: "Auburn University",
      value: "Auburn University",
    },
    {
      label: "Audisankara College of Engineering and Technology",
      value: "Audisankara College of Engineering and Technology",
    },
    {
      label: "Aurora Group of Institutions",
      value: "Aurora Group of Institutions",
    },
    {
      label: "Austin Community College District",
      value: "Austin Community College District",
    },
    {
      label: "Aviation Career & Technical Education High School",
      value: "Aviation Career & Technical Education High School",
    },
    {
      label: "Avon High School",
      value: "Avon High School",
    },
    {
      label: "B. P. Poddar Institute of Management and Technology",
      value: "B. P. Poddar Institute of Management and Technology",
    },
    {
      label: "B. V. Bhoomaraddi College of Engineering and Technology (KLE Tech)",
      value: "B. V. Bhoomaraddi College of Engineering and Technology (KLE Tech)",
    },
    {
      label: "B.M.S College Of Engineering",
      value: "B.M.S College Of Engineering",
    },
    {
      label: "B.N.M Institute of Technology",
      value: "B.N.M Institute of Technology",
    },
    {
      label: "Babaria Institute of Technology",
      value: "Babaria Institute of Technology",
    },
    {
      label: "Babson College",
      value: "Babson College",
    },
    {
      label: "Babu Banarasi Das National Institute of Technology and Management",
      value: "Babu Banarasi Das National Institute of Technology and Management",
    },
    {
      label: "Babu Banarasi Das Northern India Institute of Technology",
      value: "Babu Banarasi Das Northern India Institute of Technology",
    },
    {
      label: "Babu Banarsi Das Institute of Technology",
      value: "Babu Banarsi Das Institute of Technology",
    },
    {
      label: "Badruka Educational Society",
      value: "Badruka Educational Society",
    },
    {
      label: "Ball State University",
      value: "Ball State University",
    },
    {
      label: "Baltimore Polytechnic Institute",
      value: "Baltimore Polytechnic Institute",
    },
    {
      label: "Bangalore Institute of Technology",
      value: "Bangalore Institute of Technology",
    },
    {
      label: "Bangalore University",
      value: "Bangalore University",
    },
    {
      label: "Bannari Amman Institute of Technology",
      value: "Bannari Amman Institute of Technology",
    },
    {
      label: "Bapuji Institute Of Engineering & Technology (BIET)",
      value: "Bapuji Institute Of Engineering & Technology (BIET)",
    },
    {
      label: "Bard College",
      value: "Bard College",
    },
    {
      label: "Barnard College",
      value: "Barnard College",
    },
    {
      label: "Barton College",
      value: "Barton College",
    },
    {
      label: "Baruch College, CUNY",
      value: "Baruch College, CUNY",
    },
    {
      label: "Basaveshwar Engineering College",
      value: "Basaveshwar Engineering College",
    },
    {
      label: "Baton Rouge Community College",
      value: "Baton Rouge Community College",
    },
    {
      label: "Battlefield High School",
      value: "Battlefield High School",
    },
    {
      label: "Bauman Moscow State Technical University",
      value: "Bauman Moscow State Technical University",
    },
    {
      label: "Bayside High School",
      value: "Bayside High School",
    },
    {
      label: "Bayview Secondary School",
      value: "Bayview Secondary School",
    },
    {
      label: "Beihang University",
      value: "Beihang University",
    },
    {
      label: "Bellevue College, Washington",
      value: "Bellevue College, Washington",
    },
    {
      label: "Benedictine College",
      value: "Benedictine College",
    },
    {
      label: "Benha University",
      value: "Benha University",
    },
    {
      label: "Benjamin Franklin High School - Baltimore",
      value: "Benjamin Franklin High School - Baltimore",
    },
    {
      label: "Benjamin Franklin High School - Philadelphia",
      value: "Benjamin Franklin High School - Philadelphia",
    },
    {
      label: "Bennett College",
      value: "Bennett College",
    },
    {
      label: "Bennett University (Times of India Group)",
      value: "Bennett University (Times of India Group)",
    },
    {
      label: "Bentley University",
      value: "Bentley University",
    },
    {
      label: "Berea College",
      value: "Berea College",
    },
    {
      label: "Bergen Catholic High School",
      value: "Bergen Catholic High School",
    },
    {
      label: "Bergen Community College",
      value: "Bergen Community College",
    },
    {
      label: "Bergen County Academies",
      value: "Bergen County Academies",
    },
    {
      label: "Bergen County Technical High School - Teterboro",
      value: "Bergen County Technical High School - Teterboro",
    },
    {
      label: "Berkshire Community College",
      value: "Berkshire Community College",
    },
    {
      label: "Bhagalpur College of Engineering",
      value: "Bhagalpur College of Engineering",
    },
    {
      label: "Bhagwan Parshuram Institute of Technology",
      value: "Bhagwan Parshuram Institute of Technology",
    },
    {
      label: "Bharat Institute of Engineering and Technology (BIET)",
      value: "Bharat Institute of Engineering and Technology (BIET)",
    },
    {
      label: "Bharathiar University",
      value: "Bharathiar University",
    },
    {
      label: "Bilkent University",
      value: "Bilkent University",
    },
    {
      label: "Bineswar Brahma Engineering College (BBEC)",
      value: "Bineswar Brahma Engineering College (BBEC)",
    },
    {
      label: "Binghamton University",
      value: "Binghamton University",
    },
    {
      label: "Birkbeck, University of London",
      value: "Birkbeck, University of London",
    },
    {
      label: "Birla Institute of Technology and Science, Pilani",
      value: "Birla Institute of Technology and Science, Pilani",
    },
    {
      label: "Birla Institute Of Technology,  Mesra",
      value: "Birla Institute Of Technology,  Mesra",
    },
    {
      label: "Birla Institute of Technology, Patna",
      value: "Birla Institute of Technology, Patna",
    },
    {
      label: "Birla Vishvakarma Mahavidyalaya Engineering College",
      value: "Birla Vishvakarma Mahavidyalaya Engineering College",
    },
    {
      label: "Birmingham City University",
      value: "Birmingham City University",
    },
    {
      label: "Birsa Institute of Technology (BIT), SINDRI",
      value: "Birsa Institute of Technology (BIT), SINDRI",
    },
    {
      label: "BITS Pilani, Hyderabad Campus",
      value: "BITS Pilani, Hyderabad Campus",
    },
    {
      label: "BITS Pilani, K K Birla Goa Campus",
      value: "BITS Pilani, K K Birla Goa Campus",
    },
    {
      label:
        "BLDEA’s V.P. Dr P. G. Halakatti College of Engineering & Technology",
      value:
        "BLDEA’s V.P. Dr P. G. Halakatti College of Engineering & Technology",
    },
    {
      label: "Blinn College",
      value: "Blinn College",
    },
    {
      label: "Bloomfield Hills High School",
      value: "Bloomfield Hills High School",
    },
    {
      label: "Bloomsburg University of Pennsylvania",
      value: "Bloomsburg University of Pennsylvania",
    },
    {
      label: "Blue Mountain Academy",
      value: "Blue Mountain Academy",
    },
    {
      label: "BlueCrest University College",
      value: "BlueCrest University College",
    },
    {
      label: "Bluevale Collegiate Institute",
      value: "Bluevale Collegiate Institute",
    },
    {
      label: "BMIET, Sonipat",
      value: "BMIET, Sonipat",
    },
    {
      label: "BMIIT, Uka Tarsadia University, Bardoli, Surat",
      value: "BMIIT, Uka Tarsadia University, Bardoli, Surat",
    },
    {
      label: "BML Munjal University (BMU)",
      value: "BML Munjal University (BMU)",
    },
    {
      label: "BMS Institute of Technology and Management",
      value: "BMS Institute of Technology and Management",
    },
    {
      label: "Boca Raton Community High School",
      value: "Boca Raton Community High School",
    },
    {
      label: "Boise State University",
      value: "Boise State University",
    },
    {
      label: "Bordentown Regional High School",
      value: "Bordentown Regional High School",
    },
    {
      label: "Borough of Manhattan Community College, CUNY",
      value: "Borough of Manhattan Community College, CUNY",
    },
    {
      label: "Boston College",
      value: "Boston College",
    },
    {
      label: "Boston Latin School",
      value: "Boston Latin School",
    },
    {
      label: "Boston University",
      value: "Boston University",
    },
    {
      label: "Boston University Metropolitan College",
      value: "Boston University Metropolitan College",
    },
    {
      label: "Bourne Grammar School",
      value: "Bourne Grammar School",
    },
    {
      label: "Bournemouth University",
      value: "Bournemouth University",
    },
    {
      label: "Bowdoin College",
      value: "Bowdoin College",
    },
    {
      label: "Bowie State University",
      value: "Bowie State University",
    },
    {
      label: "Boys Latin of Philadelphia Charter School",
      value: "Boys Latin of Philadelphia Charter School",
    },
    {
      label: "Brampton Centennial Secondary School",
      value: "Brampton Centennial Secondary School",
    },
    {
      label: "Brandeis University",
      value: "Brandeis University",
    },
    {
      label: "Brentsville High School",
      value: "Brentsville High School",
    },
    {
      label: "Briar Cliff University",
      value: "Briar Cliff University",
    },
    {
      label: "Briarcliff High School",
      value: "Briarcliff High School",
    },
    {
      label: "Bridgewater State University",
      value: "Bridgewater State University",
    },
    {
      label: "Brigham Young University",
      value: "Brigham Young University",
    },
    {
      label: "British Columbia Institute of Technology",
      value: "British Columbia Institute of Technology",
    },
    {
      label: "Brno University of Technology",
      value: "Brno University of Technology",
    },
    {
      label: "Brock University",
      value: "Brock University",
    },
    {
      label: "Bronx Community College, CUNY",
      value: "Bronx Community College, CUNY",
    },
    {
      label: "Brookdale Community College",
      value: "Brookdale Community College",
    },
    {
      label: "Brooklyn College, CUNY",
      value: "Brooklyn College, CUNY",
    },
    {
      label: "Brooklyn Technical High School",
      value: "Brooklyn Technical High School",
    },
    {
      label: "Brookwood High School",
      value: "Brookwood High School",
    },
    {
      label: "Brown University",
      value: "Brown University",
    },
    {
      label: "Bryn Athyn College",
      value: "Bryn Athyn College",
    },
    {
      label: "Bryn Mawr College",
      value: "Bryn Mawr College",
    },
    {
      label: "Bucknell University",
      value: "Bucknell University",
    },
    {
      label: "Bucks County Community College",
      value: "Bucks County Community College",
    },
    {
      label: "Bundelkhand Institute Of Engineering & Technology (BIET Jhansi)",
      value: "Bundelkhand Institute Of Engineering & Technology (BIET Jhansi)",
    },
    {
      label: "Burlington Township High School",
      value: "Burlington Township High School",
    },
    {
      label: "Business Academy Aarhus",
      value: "Business Academy Aarhus",
    },
    {
      label: "BVRIT Hyderabad College of Engineering for Women",
      value: "BVRIT Hyderabad College of Engineering for Women",
    },
    {
      label: "C. Abdul Hakeem College of Engineering & Technology",
      value: "C. Abdul Hakeem College of Engineering & Technology",
    },
    {
      label: "C. D. Hylton High School",
      value: "C. D. Hylton High School",
    },
    {
      label: "C. K. Pithawala College of Engineering and Technology",
      value: "C. K. Pithawala College of Engineering and Technology",
    },
    {
      label: "C.V. Raman College of Engineering",
      value: "C.V. Raman College of Engineering",
    },
    {
      label: "Cabrini University",
      value: "Cabrini University",
    },
    {
      label: "Cadbury Sixth Form College",
      value: "Cadbury Sixth Form College",
    },
    {
      label: "Cairn University",
      value: "Cairn University",
    },
    {
      label: "Caldwell University",
      value: "Caldwell University",
    },
    {
      label: "California High School",
      value: "California High School",
    },
    {
      label: "California Institute of Technology",
      value: "California Institute of Technology",
    },
    {
      label: "California Polytechnic State University, San Luis Obispo",
      value: "California Polytechnic State University, San Luis Obispo",
    },
    {
      label: "California State Polytechnic University, Pomona",
      value: "California State Polytechnic University, Pomona",
    },
    {
      label: "California State University, Bakersfield",
      value: "California State University, Bakersfield",
    },
    {
      label: "California State University, Channel Islands",
      value: "California State University, Channel Islands",
    },
    {
      label: "California State University, Chico",
      value: "California State University, Chico",
    },
    {
      label: "California State University, Dominguez Hills",
      value: "California State University, Dominguez Hills",
    },
    {
      label: "California State University, East Bay",
      value: "California State University, East Bay",
    },
    {
      label: "California State University, Fresno",
      value: "California State University, Fresno",
    },
    {
      label: "California State University, Fullerton",
      value: "California State University, Fullerton",
    },
    {
      label: "California State University, Humboldt",
      value: "California State University, Humboldt",
    },
    {
      label: "California State University, Long Beach",
      value: "California State University, Long Beach",
    },
    {
      label: "California State University, Los Angeles",
      value: "California State University, Los Angeles",
    },
    {
      label: "California State University, Maritime",
      value: "California State University, Maritime",
    },
    {
      label: "California State University, Monterey Bay",
      value: "California State University, Monterey Bay",
    },
    {
      label: "California State University, Northridge",
      value: "California State University, Northridge",
    },
    {
      label: "California State University, Sacramento",
      value: "California State University, Sacramento",
    },
    {
      label: "California State University, San Bernardino",
      value: "California State University, San Bernardino",
    },
    {
      label: "California State University, San Diego",
      value: "California State University, San Diego",
    },
    {
      label: "California State University, San Francisco",
      value: "California State University, San Francisco",
    },
    {
      label: "California State University, San Jose",
      value: "California State University, San Jose",
    },
    {
      label: "California State University, San Luis Obispo",
      value: "California State University, San Luis Obispo",
    },
    {
      label: "California State University, San Marcos",
      value: "California State University, San Marcos",
    },
    {
      label: "California State University, Sonoma",
      value: "California State University, Sonoma",
    },
    {
      label: "California State University, Stanislaus",
      value: "California State University, Stanislaus",
    },
    {
      label: "California University of Pennsylvania",
      value: "California University of Pennsylvania",
    },
    {
      label: "Calvin College",
      value: "Calvin College",
    },
    {
      label: "Camden County College",
      value: "Camden County College",
    },
    {
      label: "Cameron Heights Collegiate Institute",
      value: "Cameron Heights Collegiate Institute",
    },
    {
      label: "Canada (Cañada) College",
      value: "Canada (Cañada) College",
    },
    {
      label: "Canara Engineering College (CEC)",
      value: "Canara Engineering College (CEC)",
    },
    {
      label: "Canyon Crest Academy",
      value: "Canyon Crest Academy",
    },
    {
      label: "CAPA - Philadelphia High School for Creative and Performing Arts",
      value: "CAPA - Philadelphia High School for Creative and Performing Arts",
    },
    {
      label: "Cardiff Metropolitan University",
      value: "Cardiff Metropolitan University",
    },
    {
      label: "Carleton College",
      value: "Carleton College",
    },
    {
      label: "Carleton University",
      value: "Carleton University",
    },
    {
      label: "Carnegie Mellon University",
      value: "Carnegie Mellon University",
    },
    {
      label: "Carteret High School",
      value: "Carteret High School",
    },
    {
      label: "Carthage College",
      value: "Carthage College",
    },
    {
      label: "Cascadia College",
      value: "Cascadia College",
    },
    {
      label: "Case Western Reserve University",
      value: "Case Western Reserve University",
    },
    {
      label: "Cathedral High School, Los Angeles",
      value: "Cathedral High School, Los Angeles",
    },
    {
      label: "Catholic University of America",
      value: "Catholic University of America",
    },
    {
      label: "Cedar Creek High School",
      value: "Cedar Creek High School",
    },
    {
      label: "Cedar Ridge High School",
      value: "Cedar Ridge High School",
    },
    {
      label: "Cedarville University",
      value: "Cedarville University",
    },
    {
      label: "Cégep André-Laurendeau",
      value: "Cégep André-Laurendeau",
    },
    {
      label: "Cégep de Saint-Laurent",
      value: "Cégep de Saint-Laurent",
    },
    {
      label: "Cégep du Vieux Montréal",
      value: "Cégep du Vieux Montréal",
    },
    {
      label: "Cégep Marie-Victorin",
      value: "Cégep Marie-Victorin",
    },
    {
      label: "Centennial Collegiate Vocational Institute",
      value: "Centennial Collegiate Vocational Institute",
    },
    {
      label: "Centennial High School",
      value: "Centennial High School",
    },
    {
      label: "Central Connecticut State University",
      value: "Central Connecticut State University",
    },
    {
      label: "Central High School - Philadelphia",
      value: "Central High School - Philadelphia",
    },
    {
      label: "Central Institute of Plastics Engineering & Technology (CIPET)",
      value: "Central Institute of Plastics Engineering & Technology (CIPET)",
    },
    {
      label: "Central PA Digital Learning Foundation Charter School",
      value: "Central PA Digital Learning Foundation Charter School",
    },
    {
      label: "Central Peel Secondary School",
      value: "Central Peel Secondary School",
    },
    {
      label: "Central Texas College",
      value: "Central Texas College",
    },
    {
      label: "Centro de Enseñanza Técnica y Superior (CETYS), Campus Ensenada",
      value: "Centro de Enseñanza Técnica y Superior (CETYS), Campus Ensenada",
    },
    {
      label: "Centro de Enseñanza Técnica y Superior (CETYS), Campus Mexicali",
      value: "Centro de Enseñanza Técnica y Superior (CETYS), Campus Mexicali",
    },
    {
      label: "Cerritos College",
      value: "Cerritos College",
    },
    {
      label: "Chaitanya Bharathi Institute of Technology",
      value: "Chaitanya Bharathi Institute of Technology",
    },
    {
      label: "Chalmers University of Technology",
      value: "Chalmers University of Technology",
    },
    {
      label: "Champlain College",
      value: "Champlain College",
    },
    {
      label: "Chandigarh College Of Engineering & Technology (CCET)",
      value: "Chandigarh College Of Engineering & Technology (CCET)",
    },
    {
      label: "Chandigarh University",
      value: "Chandigarh University",
    },
    {
      label: "Channabasaveshwara Institute of Technology",
      value: "Channabasaveshwara Institute of Technology",
    },
    {
      label: "Chaparral Star Academy",
      value: "Chaparral Star Academy",
    },
    {
      label: "Chapel Hill High School",
      value: "Chapel Hill High School",
    },
    {
      label: "Charotar University Of Science And Technology (CHAURSAT)",
      value: "Charotar University Of Science And Technology (CHAURSAT)",
    },
    {
      label: "Charter High School for Architecture and Design - Philadelphia",
      value: "Charter High School for Architecture and Design - Philadelphia",
    },
    {
      label: "Chattahoochee Technical College",
      value: "Chattahoochee Technical College",
    },
    {
      label: "Cherokee High School",
      value: "Cherokee High School",
    },
    {
      label: "Cherry Hill High School East",
      value: "Cherry Hill High School East",
    },
    {
      label: "Cherry Hill High School West",
      value: "Cherry Hill High School West",
    },
    {
      label: "Chestnut Hill College",
      value: "Chestnut Hill College",
    },
    {
      label: "Cheyney University",
      value: "Cheyney University",
    },
    {
      label: "Chinguacousy Secondary School",
      value: "Chinguacousy Secondary School",
    },
    {
      label: "Chitkara Institute of Engineering & Technology (CIET)",
      value: "Chitkara Institute of Engineering & Technology (CIET)",
    },
    {
      label: "Chitkara University",
      value: "Chitkara University",
    },
    {
      label: "Christ College of Engineering and Technology",
      value: "Christ College of Engineering and Technology",
    },
    {
      label: "Christ Knowledge City",
      value: "Christ Knowledge City",
    },
    {
      label: "Christ University",
      value: "Christ University",
    },
    {
      label: "Christ University Faculty of Engineering",
      value: "Christ University Faculty of Engineering",
    },
    {
      label: "Cincinnati State Technical and Community College",
      value: "Cincinnati State Technical and Community College",
    },
    {
      label: "Citrus College",
      value: "Citrus College",
    },
    {
      label: "City College of San Francisco",
      value: "City College of San Francisco",
    },
    {
      label: "City Engineering College",
      value: "City Engineering College",
    },
    {
      label: "City Neighbors High School",
      value: "City Neighbors High School",
    },
    {
      label: "City University London",
      value: "City University London",
    },
    {
      label: "Claremont McKenna College",
      value: "Claremont McKenna College",
    },
    {
      label: "Clarion University of Pennsylvania",
      value: "Clarion University of Pennsylvania",
    },
    {
      label: "Clark Atlanta University",
      value: "Clark Atlanta University",
    },
    {
      label: "Clark University",
      value: "Clark University",
    },
    {
      label: "Clarksburg High School",
      value: "Clarksburg High School",
    },
    {
      label: "Clarkson University",
      value: "Clarkson University",
    },
    {
      label: "Clayton State University",
      value: "Clayton State University",
    },
    {
      label: "Clemson University",
      value: "Clemson University",
    },
    {
      label: "Cleveland State University",
      value: "Cleveland State University",
    },
    {
      label: "Clifton Public Highschool",
      value: "Clifton Public Highschool",
    },
    {
      label: "Cluster Innovation Centre, University of Delhi",
      value: "Cluster Innovation Centre, University of Delhi",
    },
    {
      label: "CMR College of Engineering and Technology,  Hyderabad",
      value: "CMR College of Engineering and Technology,  Hyderabad",
    },
    {
      label: "CMR Engineering College",
      value: "CMR Engineering College",
    },
    {
      label: "CMR Institute of Technology (CMRIT)",
      value: "CMR Institute of Technology (CMRIT)",
    },
    {
      label: "CMR Technical Campus",
      value: "CMR Technical Campus",
    },
    {
      label: "Cochin College of Engineering and Technology",
      value: "Cochin College of Engineering and Technology",
    },
    {
      label: "Cochin University of Science and Technology",
      value: "Cochin University of Science and Technology",
    },
    {
      label: "CODE University of Applied Sciences Berlin",
      value: "CODE University of Applied Sciences Berlin",
    },
    {
      label: "Coe College",
      value: "Coe College",
    },
    {
      label: "Coimbatore Institute of Engineering and Technology (CIET)",
      value: "Coimbatore Institute of Engineering and Technology (CIET)",
    },
    {
      label: "Coimbatore Institute of Technology (CIT)",
      value: "Coimbatore Institute of Technology (CIT)",
    },
    {
      label: "Colegio Simón Bolívar",
      value: "Colegio Simón Bolívar",
    },
    {
      label: "Colgate University",
      value: "Colgate University",
    },
    {
      label: "Collège Ahuntsic",
      value: "Collège Ahuntsic",
    },
    {
      label: "Collège André-Grasset",
      value: "Collège André-Grasset",
    },
    {
      label: "Collège de Bois-de-Boulogne",
      value: "Collège de Bois-de-Boulogne",
    },
    {
      label: "Collège de Maisonneuve",
      value: "Collège de Maisonneuve",
    },
    {
      label: "Collège de Montréal",
      value: "Collège de Montréal",
    },
    {
      label: "Collège de Rosemont",
      value: "Collège de Rosemont",
    },
    {
      label: "Collège Français",
      value: "Collège Français",
    },
    {
      label: "Collège Jean-de-Brébeuf",
      value: "Collège Jean-de-Brébeuf",
    },
    {
      label: "Collège Jean-Eudes",
      value: "Collège Jean-Eudes",
    },
    {
      label: "Collège Lionel-Groulx",
      value: "Collège Lionel-Groulx",
    },
    {
      label:
        "College of Agricultural Engineering and Post Harvest Technology (CAEPHT)",
      value:
        "College of Agricultural Engineering and Post Harvest Technology (CAEPHT)",
    },
    {
      label: "College of Agriculture, Central Agricultural University",
      value: "College of Agriculture, Central Agricultural University",
    },
    {
      label: "College of Charleston",
      value: "College of Charleston",
    },
    {
      label: "College of DuPage",
      value: "College of DuPage",
    },
    {
      label: "College of Engineering & Management Punnapra",
      value: "College of Engineering & Management Punnapra",
    },
    {
      label: "College of Engineering and Management, Kolaghat",
      value: "College of Engineering and Management, Kolaghat",
    },
    {
      label: "College of Engineering Chengannur",
      value: "College of Engineering Chengannur",
    },
    {
      label: "College of Engineering, Pune",
      value: "College of Engineering, Pune",
    },
    {
      label: "College of Staten Island, CUNY",
      value: "College of Staten Island, CUNY",
    },
    {
      label: "College of Technology & Engineering, Udaipur",
      value: "College of Technology & Engineering, Udaipur",
    },
    {
      label: "College of Westchester",
      value: "College of Westchester",
    },
    {
      label: "Collège Regina Assumpta",
      value: "Collège Regina Assumpta",
    },
    {
      label: "Colleyville Heritage High School",
      value: "Colleyville Heritage High School",
    },
    {
      label: "Collins Hill High School",
      value: "Collins Hill High School",
    },
    {
      label: "Colorado School of Mines",
      value: "Colorado School of Mines",
    },
    {
      label: "Colts Neck High School",
      value: "Colts Neck High School",
    },
    {
      label: "Columbia Secondary School",
      value: "Columbia Secondary School",
    },
    {
      label: "Columbia University",
      value: "Columbia University",
    },
    {
      label: "Columbus College of Art and Design",
      value: "Columbus College of Art and Design",
    },
    {
      label: "Columbus State Community College",
      value: "Columbus State Community College",
    },
    {
      label: "Comenius University",
      value: "Comenius University",
    },
    {
      label: "Commonwealth Charter Academy Charter School",
      value: "Commonwealth Charter Academy Charter School",
    },
    {
      label: "Community Academy of Philadelphia Charter School",
      value: "Community Academy of Philadelphia Charter School",
    },
    {
      label: "Community College of Allegheny County",
      value: "Community College of Allegheny County",
    },
    {
      label: "Community College of Baltimore County",
      value: "Community College of Baltimore County",
    },
    {
      label: "Community College of Philadelphia",
      value: "Community College of Philadelphia",
    },
    {
      label: "Community College of Rhode Island",
      value: "Community College of Rhode Island",
    },
    {
      label: "COMSATS Institute of Information Technology",
      value: "COMSATS Institute of Information Technology",
    },
    {
      label: "Concord Academy",
      value: "Concord Academy",
    },
    {
      label: "Concordia University",
      value: "Concordia University",
    },
    {
      label: "Conestoga College",
      value: "Conestoga College",
    },
    {
      label: "Conestoga High School",
      value: "Conestoga High School",
    },
    {
      label: "Connecticut College",
      value: "Connecticut College",
    },
    {
      label: "Conroe ISD Academy of Science and Technology, Texas",
      value: "Conroe ISD Academy of Science and Technology, Texas",
    },
    {
      label: "Constitution High School - Philadelphia",
      value: "Constitution High School - Philadelphia",
    },
    {
      label: "Cooper Union",
      value: "Cooper Union",
    },
    {
      label: "Coral Glades High School",
      value: "Coral Glades High School",
    },
    {
      label: "Cornell College",
      value: "Cornell College",
    },
    {
      label: "Cornell University",
      value: "Cornell University",
    },
    {
      label: "Council Rock High School North",
      value: "Council Rock High School North",
    },
    {
      label: "Council Rock High School South",
      value: "Council Rock High School South",
    },
    {
      label: "County College of Morris",
      value: "County College of Morris",
    },
    {
      label: "Covenant University",
      value: "Covenant University",
    },
    {
      label: "Coventry University",
      value: "Coventry University",
    },
    {
      label: "Cranbrook Schools",
      value: "Cranbrook Schools",
    },
    {
      label: "Cranfield University",
      value: "Cranfield University",
    },
    {
      label: "Creekview High School",
      value: "Creekview High School",
    },
    {
      label: "Cumberland County College",
      value: "Cumberland County College",
    },
    {
      label: "Cummins College of Engineering for Women,  Pune",
      value: "Cummins College of Engineering for Women,  Pune",
    },
    {
      label: "Cupertino High School",
      value: "Cupertino High School",
    },
    {
      label: "D.J. College of Engineering & Technology",
      value: "D.J. College of Engineering & Technology",
    },
    {
      label: "D.K.T.E Society's Textile and Engineering Institute",
      value: "D.K.T.E Society's Textile and Engineering Institute",
    },
    {
      label: "Dalhousie University",
      value: "Dalhousie University",
    },
    {
      label: "Dalmia Institute of Scientific & Industrial Research",
      value: "Dalmia Institute of Scientific & Industrial Research",
    },
    {
      label: "Dartmouth College",
      value: "Dartmouth College",
    },
    {
      label: "Davidson College",
      value: "Davidson College",
    },
    {
      label: "Dawson College",
      value: "Dawson College",
    },
    {
      label: "Dayalbagh Educational Institute",
      value: "Dayalbagh Educational Institute",
    },
    {
      label: "Dayananda Sagar University",
      value: "Dayananda Sagar University",
    },
    {
      label: "DCS,  Ganpat University",
      value: "DCS,  Ganpat University",
    },
    {
      label: "De Anza College",
      value: "De Anza College",
    },
    {
      label:
        "Deenbandhu Chhotu Ram University of Science and Technology, Murthal",
      value:
        "Deenbandhu Chhotu Ram University of Science and Technology, Murthal",
    },
    {
      label: "Deerfield High School",
      value: "Deerfield High School",
    },
    {
      label: "Del Norte High School",
      value: "Del Norte High School",
    },
    {
      label: "Delaware County Community College - Downingtown",
      value: "Delaware County Community College - Downingtown",
    },
    {
      label: "Delaware County Community College - Exton",
      value: "Delaware County Community College - Exton",
    },
    {
      label: "Delaware County Community College - Main Campus (Marple)",
      value: "Delaware County Community College - Main Campus (Marple)",
    },
    {
      label: "Delaware County Community College - Phoenixville",
      value: "Delaware County Community College - Phoenixville",
    },
    {
      label: "Delaware County Community College - Sharon Hill",
      value: "Delaware County Community College - Sharon Hill",
    },
    {
      label: "Delaware County Community College - Upper Darby",
      value: "Delaware County Community College - Upper Darby",
    },
    {
      label: "Delaware County Community College - West Grove",
      value: "Delaware County Community College - West Grove",
    },
    {
      label: "Delaware State University",
      value: "Delaware State University",
    },
    {
      label: "Delaware Technical Community College",
      value: "Delaware Technical Community College",
    },
    {
      label: "Delaware Valley Academy of Medical and Dental Assistants",
      value: "Delaware Valley Academy of Medical and Dental Assistants",
    },
    {
      label: "Delaware Valley University",
      value: "Delaware Valley University",
    },
    {
      label: "Delft University of Technology",
      value: "Delft University of Technology",
    },
    {
      label: "Delhi Technological University",
      value: "Delhi Technological University",
    },
    {
      label: "Denison University",
      value: "Denison University",
    },
    {
      label:
        "Department of Human Resource Management and OB, Central University of Jammu",
      value:
        "Department of Human Resource Management and OB, Central University of Jammu",
    },
    {
      label: "DePaul University",
      value: "DePaul University",
    },
    {
      label: "DePauw University",
      value: "DePauw University",
    },
    {
      label: "Des Moines Area Community College",
      value: "Des Moines Area Community College",
    },
    {
      label: "DeSales University",
      value: "DeSales University",
    },
    {
      label: "Devry University - Philadelphia Center City",
      value: "Devry University - Philadelphia Center City",
    },
    {
      label: "Dharmsinh Desai University",
      value: "Dharmsinh Desai University",
    },
    {
      label:
        "Dhirubhai Ambani Institute of Information and Communication Technology (DA-IICT)",
      value:
        "Dhirubhai Ambani Institute of Information and Communication Technology (DA-IICT)",
    },
    {
      label: "Diablo Valley College",
      value: "Diablo Valley College",
    },
    {
      label: "Dickinson College",
      value: "Dickinson College",
    },
    {
      label: "Digital Harbor High School",
      value: "Digital Harbor High School",
    },
    {
      label: "DIT University",
      value: "DIT University",
    },
    {
      label: "Don Bosco College of Engineering",
      value: "Don Bosco College of Engineering",
    },
    {
      label: "Don Bosco College of Engineering and Technology",
      value: "Don Bosco College of Engineering and Technology",
    },
    {
      label: "Don Bosco Institute of Technology",
      value: "Don Bosco Institute of Technology",
    },
    {
      label: "Doon College of Engineering & Technology",
      value: "Doon College of Engineering & Technology",
    },
    {
      label: "Dougherty Valley High School",
      value: "Dougherty Valley High School",
    },
    {
      label: "Dr B. R. Ambedkar Institute of Technology,  Port Blair",
      value: "Dr B. R. Ambedkar Institute of Technology,  Port Blair",
    },
    {
      label: "Dr. A.P.J. Abdul Kalam Technical University, Lucknow",
      value: "Dr. A.P.J. Abdul Kalam Technical University, Lucknow",
    },
    {
      label: "Dr. Akhilesh Das Gupta Institute of Technology & Management",
      value: "Dr. Akhilesh Das Gupta Institute of Technology & Management",
    },
    {
      label: "Dr. B. R. Ambedkar National Institute of Technology Jalandhar",
      value: "Dr. B. R. Ambedkar National Institute of Technology Jalandhar",
    },
    {
      label: "Dr. B.C. Roy Engineering College, Durgapur",
      value: "Dr. B.C. Roy Engineering College, Durgapur",
    },
    {
      label: "Dr. Babasaheb Ambedkar Marathwada University",
      value: "Dr. Babasaheb Ambedkar Marathwada University",
    },
    {
      label: "Dr. Harisingh Gour University, Sagar University",
      value: "Dr. Harisingh Gour University, Sagar University",
    },
    {
      label: "Dr. K.N. Modi Engineering College",
      value: "Dr. K.N. Modi Engineering College",
    },
    {
      label: "Dr. MGR Educational Research Institute University",
      value: "Dr. MGR Educational Research Institute University",
    },
    {
      label: "Dr. SJS Paul Memorial College of Engineering and Technology (CIT)",
      value: "Dr. SJS Paul Memorial College of Engineering and Technology (CIT)",
    },
    {
      label: "Dr. T. Thimmaiah Institute of Technology",
      value: "Dr. T. Thimmaiah Institute of Technology",
    },
    {
      label: "Drake University",
      value: "Drake University",
    },
    {
      label: "Drew University",
      value: "Drew University",
    },
    {
      label: "Drexel University",
      value: "Drexel University",
    },
    {
      label: "Dublin High School",
      value: "Dublin High School",
    },
    {
      label: "Dublin Jerome High School",
      value: "Dublin Jerome High School",
    },
    {
      label: "Duke University",
      value: "Duke University",
    },
    {
      label: "Dulaney High School",
      value: "Dulaney High School",
    },
    {
      label: "Duquesne University",
      value: "Duquesne University",
    },
    {
      label: "Durant High School",
      value: "Durant High School",
    },
    {
      label: "Durham College",
      value: "Durham College",
    },
    {
      label: "Durham University",
      value: "Durham University",
    },
    {
      label: "Dwarkadas J. Sanghvi College of Engineering",
      value: "Dwarkadas J. Sanghvi College of Engineering",
    },
    {
      label: "Dwight-Englewood School",
      value: "Dwight-Englewood School",
    },
    {
      label: "Earl of March Secondary School",
      value: "Earl of March Secondary School",
    },
    {
      label: "Earlham College",
      value: "Earlham College",
    },
    {
      label: "East Brunswick High School",
      value: "East Brunswick High School",
    },
    {
      label: "East Central University",
      value: "East Central University",
    },
    {
      label: "East Chapel Hill High Schoo",
      value: "East Chapel Hill High Schoo",
    },
    {
      label: "East Los Angeles College",
      value: "East Los Angeles College",
    },
    {
      label: "East Point College of Engineering and Technology",
      value: "East Point College of Engineering and Technology",
    },
    {
      label: "East Stroudsburg High School",
      value: "East Stroudsburg High School",
    },
    {
      label: "East West Institute of Technology",
      value: "East West Institute of Technology",
    },
    {
      label: "EASTERN Center for Arts and Technology",
      value: "EASTERN Center for Arts and Technology",
    },
    {
      label: "Eastern High School - Louisville",
      value: "Eastern High School - Louisville",
    },
    {
      label: "Eastern Michigan University",
      value: "Eastern Michigan University",
    },
    {
      label: "Eastern Regional High School",
      value: "Eastern Regional High School",
    },
    {
      label: "Eastern University - St. Davids",
      value: "Eastern University - St. Davids",
    },
    {
      label: "Eastern University Academy Charter School",
      value: "Eastern University Academy Charter School",
    },
    {
      label: "Eastern Washington University",
      value: "Eastern Washington University",
    },
    {
      label: "Eckerd College",
      value: "Eckerd College",
    },
    {
      label: "ecole centrale marseille",
      value: "ecole centrale marseille",
    },
    {
      label: "École Centrale Paris",
      value: "École Centrale Paris",
    },
    {
      label: "École de technologie supérieure",
      value: "École de technologie supérieure",
    },
    {
      label:
        "École nationale supérieure d’électronique, informatique, télécommunications, mathématique et mécanique de Bordeaux (ENSEIRB-MATMECA)",
      value:
        "École nationale supérieure d’électronique, informatique, télécommunications, mathématique et mécanique de Bordeaux (ENSEIRB-MATMECA)",
    },
    {
      label: "École Polytechnique de Montréal",
      value: "École Polytechnique de Montréal",
    },
    {
      label: "Edina High School",
      value: "Edina High School",
    },
    {
      label: "Edinburgh Napier University",
      value: "Edinburgh Napier University",
    },
    {
      label: "Edison Academy",
      value: "Edison Academy",
    },
    {
      label: "Edison High School",
      value: "Edison High School",
    },
    {
      label: "Edward R. Murrow High School",
      value: "Edward R. Murrow High School",
    },
    {
      label: "Egg Harbor Township High School",
      value: "Egg Harbor Township High School",
    },
    {
      label: "Eidgenössische Technische Hochschule (ETH) Zürich",
      value: "Eidgenössische Technische Hochschule (ETH) Zürich",
    },
    {
      label: "Ekta Incubation Center, West Bengal",
      value: "Ekta Incubation Center, West Bengal",
    },
    {
      label: "El Camino College",
      value: "El Camino College",
    },
    {
      label: "El Centro College",
      value: "El Centro College",
    },
    {
      label: "El Centro de Estudiantes",
      value: "El Centro de Estudiantes",
    },
    {
      label: "Elgin Academy",
      value: "Elgin Academy",
    },
    {
      label: "Elizabeth High School",
      value: "Elizabeth High School",
    },
    {
      label: "Elon University",
      value: "Elon University",
    },
    {
      label: "Embry-Riddle Aeronautical University",
      value: "Embry-Riddle Aeronautical University",
    },
    {
      label: "Emory University",
      value: "Emory University",
    },
    {
      label: "Entrepreneurship Development Center, MIT, Pune",
      value: "Entrepreneurship Development Center, MIT, Pune",
    },
    {
      label: "Entreprpeneurship Development Cell, University of Kerala",
      value: "Entreprpeneurship Development Cell, University of Kerala",
    },
    {
      label: "EPFL | École polytechnique fédérale de Lausanne",
      value: "EPFL | École polytechnique fédérale de Lausanne",
    },
    {
      label: "Episcopal Academy",
      value: "Episcopal Academy",
    },
    {
      label: "EPITECH Bordeaux",
      value: "EPITECH Bordeaux",
    },
    {
      label: "Er.Perumal Manimekalai College of Engineering",
      value: "Er.Perumal Manimekalai College of Engineering",
    },
    {
      label: "Erasmus Hogeschool Brussel",
      value: "Erasmus Hogeschool Brussel",
    },
    {
      label: "Erie Community College",
      value: "Erie Community College",
    },
    {
      label: "Ernest Manning High School",
      value: "Ernest Manning High School",
    },
    {
      label: "Esperanza Academy Charter School",
      value: "Esperanza Academy Charter School",
    },
    {
      label: "Esperanza Cyber Charter School",
      value: "Esperanza Cyber Charter School",
    },
    {
      label: "Evergreen Valley College",
      value: "Evergreen Valley College",
    },
    {
      label: "Evergreen Valley High School",
      value: "Evergreen Valley High School",
    },
    {
      label: "Fachhochschule Dortmund",
      value: "Fachhochschule Dortmund",
    },
    {
      label:
        "Faculty Of Engineering & Technology, Gurukula Kangri Vishwavidyalaya",
      value:
        "Faculty Of Engineering & Technology, Gurukula Kangri Vishwavidyalaya",
    },
    {
      label: "Faculty of science / Ibn Tofail University",
      value: "Faculty of science / Ibn Tofail University",
    },
    {
      label: "Fahaheel Al-Watanieh Indian Private School",
      value: "Fahaheel Al-Watanieh Indian Private School",
    },
    {
      label: "Fairfield University",
      value: "Fairfield University",
    },
    {
      label: "Fairleigh Dickinson University",
      value: "Fairleigh Dickinson University",
    },
    {
      label: "Fairview High School",
      value: "Fairview High School",
    },
    {
      label: "Farmingdale State College",
      value: "Farmingdale State College",
    },
    {
      label: "FernUniversität in Hagen",
      value: "FernUniversität in Hagen",
    },
    {
      label: "Finolex Academy of Management and Technology",
      value: "Finolex Academy of Management and Technology",
    },
    {
      label: "First Philadelphia Preparatory Charter School",
      value: "First Philadelphia Preparatory Charter School",
    },
    {
      label: "Fitchburg State University",
      value: "Fitchburg State University",
    },
    {
      label: "Florida Agricultural & Mechanical (A&M) University",
      value: "Florida Agricultural & Mechanical (A&M) University",
    },
    {
      label: "Florida Atlantic University",
      value: "Florida Atlantic University",
    },
    {
      label: "Florida Gulf Coast University",
      value: "Florida Gulf Coast University",
    },
    {
      label: "Florida Institute Of Technology",
      value: "Florida Institute Of Technology",
    },
    {
      label: "Florida International University",
      value: "Florida International University",
    },
    {
      label: "Florida Polytechnic University",
      value: "Florida Polytechnic University",
    },
    {
      label: "Florida State University",
      value: "Florida State University",
    },
    {
      label: "Fontys Hogeschool",
      value: "Fontys Hogeschool",
    },
    {
      label: "Foothill College",
      value: "Foothill College",
    },
    {
      label: "Fordham University",
      value: "Fordham University",
    },
    {
      label: "Forest Heights Collegiate Institute",
      value: "Forest Heights Collegiate Institute",
    },
    {
      label: "Forest Park High School - Baltimore",
      value: "Forest Park High School - Baltimore",
    },
    {
      label: "Forest Park High School - Forest Park, GA",
      value: "Forest Park High School - Forest Park, GA",
    },
    {
      label: "Forest Park High School - Woodbridge",
      value: "Forest Park High School - Woodbridge",
    },
    {
      label: "Fort Scott Community College",
      value: "Fort Scott Community College",
    },
    {
      label: "Foundation Collegiate Academy",
      value: "Foundation Collegiate Academy",
    },
    {
      label: "Foundation for Innovation and Technology Transfer, IIT Delhi",
      value: "Foundation for Innovation and Technology Transfer, IIT Delhi",
    },
    {
      label: "Fr. Conceicao Rodrigues College of Engineering",
      value: "Fr. Conceicao Rodrigues College of Engineering",
    },
    {
      label: "Francis Holland School",
      value: "Francis Holland School",
    },
    {
      label: "Francis Lewis High School",
      value: "Francis Lewis High School",
    },
    {
      label: "Frankford High School - Philadelphia",
      value: "Frankford High School - Philadelphia",
    },
    {
      label: "Franklin High School",
      value: "Franklin High School",
    },
    {
      label: "Franklin Learning Center - Philadelphia",
      value: "Franklin Learning Center - Philadelphia",
    },
    {
      label: "Franklin Towne Charter High School",
      value: "Franklin Towne Charter High School",
    },
    {
      label: "Franklin W. Olin College of Engineering",
      value: "Franklin W. Olin College of Engineering",
    },
    {
      label: "Frederick Community College",
      value: "Frederick Community College",
    },
    {
      label: "Freedom High School - Bethlehem",
      value: "Freedom High School - Bethlehem",
    },
    {
      label: "Freedom High School - Woodbridge",
      value: "Freedom High School - Woodbridge",
    },
    {
      label: "Freehold High School",
      value: "Freehold High School",
    },
    {
      label: "Freire Charter High School",
      value: "Freire Charter High School",
    },
    {
      label: "Fremont High School",
      value: "Fremont High School",
    },
    {
      label: "Full Sail University",
      value: "Full Sail University",
    },
    {
      label: "Fullerton College",
      value: "Fullerton College",
    },
    {
      label: "G. H. Patel College of Engineering & Technology",
      value: "G. H. Patel College of Engineering & Technology",
    },
    {
      label: "G. Narayanamma Institute of Technology Science (For Women)",
      value: "G. Narayanamma Institute of Technology Science (For Women)",
    },
    {
      label: "G.H. Raisoni College of Engineering",
      value: "G.H. Raisoni College of Engineering",
    },
    {
      label: "Galgotias College of Engineering & Technology",
      value: "Galgotias College of Engineering & Technology",
    },
    {
      label: "Gandhi Institute of Technical Advancement (GITA)",
      value: "Gandhi Institute of Technical Advancement (GITA)",
    },
    {
      label: "Gandhi Institute of Technology and Management, Bengaluru",
      value: "Gandhi Institute of Technology and Management, Bengaluru",
    },
    {
      label: "Gandhi Institute of Technology and Management, Hyderabad",
      value: "Gandhi Institute of Technology and Management, Hyderabad",
    },
    {
      label: "Gandhi Institute of Technology and Management, Visakhapatnam",
      value: "Gandhi Institute of Technology and Management, Visakhapatnam",
    },
    {
      label: "Gandhi Institution of Management Studies",
      value: "Gandhi Institution of Management Studies",
    },
    {
      label: "Ganga International School",
      value: "Ganga International School",
    },
    {
      label: "Ganpat University",
      value: "Ganpat University",
    },
    {
      label: "Gar-Field Senior High School",
      value: "Gar-Field Senior High School",
    },
    {
      label: "Garnet Valley High School",
      value: "Garnet Valley High School",
    },
    {
      label: "Gautam Buddha University",
      value: "Gautam Buddha University",
    },
    {
      label: "Gaya College Of Engineering",
      value: "Gaya College Of Engineering",
    },
    {
      label: "Gayatri Vidya Parishad College of Engineering",
      value: "Gayatri Vidya Parishad College of Engineering",
    },
    {
      label: "GEC, Gandhinagar",
      value: "GEC, Gandhinagar",
    },
    {
      label: "GEC, Patan",
      value: "GEC, Patan",
    },
    {
      label: "Geetanjali Institute of Technical Studies (GITS)",
      value: "Geetanjali Institute of Technical Studies (GITS)",
    },
    {
      label: "Geethanjali College of Engineering and Technology",
      value: "Geethanjali College of Engineering and Technology",
    },
    {
      label: "George C. Marshall High School",
      value: "George C. Marshall High School",
    },
    {
      label: "George Heriot's School",
      value: "George Heriot's School",
    },
    {
      label: "George Mason University",
      value: "George Mason University",
    },
    {
      label: "George Washington High School - Philadelphia",
      value: "George Washington High School - Philadelphia",
    },
    {
      label: "Georgetown University",
      value: "Georgetown University",
    },
    {
      label: "Georgia Institute of Technology",
      value: "Georgia Institute of Technology",
    },
    {
      label: "Georgia State University",
      value: "Georgia State University",
    },
    {
      label: "Germantown Friends School",
      value: "Germantown Friends School",
    },
    {
      label: "Geroge Washington Carver High School - Philadelphia",
      value: "Geroge Washington Carver High School - Philadelphia",
    },
    {
      label: "Ghent University",
      value: "Ghent University",
    },
    {
      label: "Ghousia College of Engineering",
      value: "Ghousia College of Engineering",
    },
    {
      label: "GIDC Degree Engineering College",
      value: "GIDC Degree Engineering College",
    },
    {
      label:
        "Girijananda Chowdhury Institute of Management and Technology (GIMT)",
      value:
        "Girijananda Chowdhury Institute of Management and Technology (GIMT)",
    },
    {
      label: "GITAM Centre for Integrated Rural Development",
      value: "GITAM Centre for Integrated Rural Development",
    },
    {
      label: "Gitam School of Technology",
      value: "Gitam School of Technology",
    },
    {
      label: "GL Bajaj Institute of Technology and Management",
      value: "GL Bajaj Institute of Technology and Management",
    },
    {
      label: "Glassboro High School",
      value: "Glassboro High School",
    },
    {
      label: "Glenaeon Rudolf Steiner School",
      value: "Glenaeon Rudolf Steiner School",
    },
    {
      label: "Glenbrook North High School",
      value: "Glenbrook North High School",
    },
    {
      label: "Glenbrook South High School",
      value: "Glenbrook South High School",
    },
    {
      label: "Glendale Community College",
      value: "Glendale Community College",
    },
    {
      label: "Glenforest Secondary School",
      value: "Glenforest Secondary School",
    },
    {
      label: "Global Academy of Technology",
      value: "Global Academy of Technology",
    },
    {
      label: "GMR Institute of Technology",
      value: "GMR Institute of Technology",
    },
    {
      label: "Goa College of Engineering",
      value: "Goa College of Engineering",
    },
    {
      label: "GOA IT INNOVATION CENTRE",
      value: "GOA IT INNOVATION CENTRE",
    },
    {
      label: "Gokaraju Rangaraju Institute of Engineering and Technology (GRIET)",
      value: "Gokaraju Rangaraju Institute of Engineering and Technology (GRIET)",
    },
    {
      label: "Goldsmiths, University of London",
      value: "Goldsmiths, University of London",
    },
    {
      label: "Gopalan College of Engineering and Management",
      value: "Gopalan College of Engineering and Management",
    },
    {
      label: "Gordon Graydon Memorial Secondary School",
      value: "Gordon Graydon Memorial Secondary School",
    },
    {
      label: "Gottfried Wilhelm Leibniz Universität Hannover",
      value: "Gottfried Wilhelm Leibniz Universität Hannover",
    },
    {
      label: "Government College of Engineering & Technology, Jammu",
      value: "Government College of Engineering & Technology, Jammu",
    },
    {
      label: "Government College Of Engineering, Amravati",
      value: "Government College Of Engineering, Amravati",
    },
    {
      label: "Government College Of Engineering, Aurangabad",
      value: "Government College Of Engineering, Aurangabad",
    },
    {
      label: "Government College of Engineering, Bargur",
      value: "Government College of Engineering, Bargur",
    },
    {
      label: "Government College of Engineering, Kalahandi",
      value: "Government College of Engineering, Kalahandi",
    },
    {
      label: "Government College of Engineering, Kannur",
      value: "Government College of Engineering, Kannur",
    },
    {
      label: "Government College Of Engineering, Karad",
      value: "Government College Of Engineering, Karad",
    },
    {
      label: "Government College of Engineering, Salem",
      value: "Government College of Engineering, Salem",
    },
    {
      label: "Government College of Technology, Coimbatore",
      value: "Government College of Technology, Coimbatore",
    },
    {
      label: "Government Engineering College Palakkad, Sreekrishnapuram",
      value: "Government Engineering College Palakkad, Sreekrishnapuram",
    },
    {
      label: "Government Engineering College, Ajmer",
      value: "Government Engineering College, Ajmer",
    },
    {
      label: "Government Engineering College, Banswara",
      value: "Government Engineering College, Banswara",
    },
    {
      label: "Government Engineering College, Hassan",
      value: "Government Engineering College, Hassan",
    },
    {
      label: "Government Engineering College, Kozhikode",
      value: "Government Engineering College, Kozhikode",
    },
    {
      label: "Government Engineering College, Thrissur",
      value: "Government Engineering College, Thrissur",
    },
    {
      label: "Government Model Engineering College, Thrikkakara",
      value: "Government Model Engineering College, Thrikkakara",
    },
    {
      label: "Government Polytechnic Gandhinagar",
      value: "Government Polytechnic Gandhinagar",
    },
    {
      label:
        "Government Sri Krishnarajendra Silver Jubilee Technological Institute",
      value:
        "Government Sri Krishnarajendra Silver Jubilee Technological Institute",
    },
    {
      label: "Governor's School for Science & Technology",
      value: "Governor's School for Science & Technology",
    },
    {
      label: "Govind Ballabh Pant Institute of Engineering & Technology",
      value: "Govind Ballabh Pant Institute of Engineering & Technology",
    },
    {
      label: "Grady High School",
      value: "Grady High School",
    },
    {
      label: "Grand Rapids Community College",
      value: "Grand Rapids Community College",
    },
    {
      label: "Grand Valley State University",
      value: "Grand Valley State University",
    },
    {
      label: "Graphic Era University",
      value: "Graphic Era University",
    },
    {
      label: "Great Neck South High School",
      value: "Great Neck South High School",
    },
    {
      label: "Greater Lowell Technical High School",
      value: "Greater Lowell Technical High School",
    },
    {
      label: "Green River College",
      value: "Green River College",
    },
    {
      label: "Greenwood College School",
      value: "Greenwood College School",
    },
    {
      label: "Grinnell College",
      value: "Grinnell College",
    },
    {
      label: "GSSS Institute of Engineering & Technology for Women",
      value: "GSSS Institute of Engineering & Technology for Women",
    },
    {
      label: "Guelph Collegiate Vocational Institute",
      value: "Guelph Collegiate Vocational Institute",
    },
    {
      label: "Gujarat Energy Research and Management Institute (GERMI)",
      value: "Gujarat Energy Research and Management Institute (GERMI)",
    },
    {
      label: "Gujarat Technological University",
      value: "Gujarat Technological University",
    },
    {
      label: "Gujarat University",
      value: "Gujarat University",
    },
    {
      label: "Guru Ghasidas Vishwavidyalaya, Bilaspur",
      value: "Guru Ghasidas Vishwavidyalaya, Bilaspur",
    },
    {
      label: "Guru Gobind Singh Indraprastha University",
      value: "Guru Gobind Singh Indraprastha University",
    },
    {
      label:
        "Guru Jambheshwar University of Science and Technology (GJUS&T), HISAR",
      value:
        "Guru Jambheshwar University of Science and Technology (GJUS&T), HISAR",
    },
    {
      label: "Guru Jambheshwar University of Science and Technology, Hisar",
      value: "Guru Jambheshwar University of Science and Technology, Hisar",
    },
    {
      label: "Guru Nanak Dev Engineering College",
      value: "Guru Nanak Dev Engineering College",
    },
    {
      label: "Guru Tegh Bahadur Institute of Technology (GTBIT)",
      value: "Guru Tegh Bahadur Institute of Technology (GTBIT)",
    },
    {
      label: "Gurukula Kangri University",
      value: "Gurukula Kangri University",
    },
    {
      label: "Guttman Community College, CUNY",
      value: "Guttman Community College, CUNY",
    },
    {
      label: "Gwalior Engineering College",
      value: "Gwalior Engineering College",
    },
    {
      label: "Gwynedd Mercy University",
      value: "Gwynedd Mercy University",
    },
    {
      label: "GZS Campus College of Engineering & Technology",
      value: "GZS Campus College of Engineering & Technology",
    },
    {
      label: "H.N. Werkman College",
      value: "H.N. Werkman College",
    },
    {
      label: "Haaga-Helia University of Applied Sciences",
      value: "Haaga-Helia University of Applied Sciences",
    },
    {
      label: "Haldia Institute of Technology",
      value: "Haldia Institute of Technology",
    },
    {
      label: "Hamilton College",
      value: "Hamilton College",
    },
    {
      label: "Hamline University",
      value: "Hamline University",
    },
    {
      label: "Hampshire College",
      value: "Hampshire College",
    },
    {
      label: "Hampton University",
      value: "Hampton University",
    },
    {
      label: "HAN University of Applied Sciences",
      value: "HAN University of Applied Sciences",
    },
    {
      label: "Hanze University of Applied Sciences",
      value: "Hanze University of Applied Sciences",
    },
    {
      label: "Harcourt Butler Technical University, Kanpur",
      value: "Harcourt Butler Technical University, Kanpur",
    },
    {
      label: "Harcum College",
      value: "Harcum College",
    },
    {
      label: "Harper College",
      value: "Harper College",
    },
    {
      label: "Harrisburg University - Harrisburg Campus",
      value: "Harrisburg University - Harrisburg Campus",
    },
    {
      label: "Harrisburg University - Philadelphia Campus",
      value: "Harrisburg University - Philadelphia Campus",
    },
    {
      label: "Harrison Career Institute",
      value: "Harrison Career Institute",
    },
    {
      label: "Harvard Medical School",
      value: "Harvard Medical School",
    },
    {
      label: "Harvard University",
      value: "Harvard University",
    },
    {
      label: "Harvey Mudd University",
      value: "Harvey Mudd University",
    },
    {
      label: "Haryana Engineering College",
      value: "Haryana Engineering College",
    },
    {
      label: "Hasso-Plattner-Institut Academy",
      value: "Hasso-Plattner-Institut Academy",
    },
    {
      label: "Haverford College",
      value: "Haverford College",
    },
    {
      label: "Hazleton Area High School",
      value: "Hazleton Area High School",
    },
    {
      label: "Head-Royce School",
      value: "Head-Royce School",
    },
    {
      label: "Health Careers High School",
      value: "Health Careers High School",
    },
    {
      label: "Heartland Community College",
      value: "Heartland Community College",
    },
    {
      label: "Helwan University",
      value: "Helwan University",
    },
    {
      label: "Henry M. Gunn High School",
      value: "Henry M. Gunn High School",
    },
    {
      label: "Herguan University",
      value: "Herguan University",
    },
    {
      label: "Heritage Institute of Technology",
      value: "Heritage Institute of Technology",
    },
    {
      label: "Het Baarnsch Lyceum",
      value: "Het Baarnsch Lyceum",
    },
    {
      label: "Hi-Tech Institute of Engineering & Technology",
      value: "Hi-Tech Institute of Engineering & Technology",
    },
    {
      label: "Hi-Tech Institute of Technology",
      value: "Hi-Tech Institute of Technology",
    },
    {
      label: "High Technology High School",
      value: "High Technology High School",
    },
    {
      label: "Highland Park High School",
      value: "Highland Park High School",
    },
    {
      label: "Hightstown High School",
      value: "Hightstown High School",
    },
    {
      label: "Hillsborough Community College",
      value: "Hillsborough Community College",
    },
    {
      label: "Hillsborough High School",
      value: "Hillsborough High School",
    },
    {
      label: "Hindustan College of Science & Technology",
      value: "Hindustan College of Science & Technology",
    },
    {
      label: "Hindustan Institute of Technology & Science",
      value: "Hindustan Institute of Technology & Science",
    },
    {
      label: "Hinsdale Central High School",
      value: "Hinsdale Central High School",
    },
    {
      label: "Hiram College",
      value: "Hiram College",
    },
    {
      label: "Hirasugar Institute of Technology, Nidasoshi",
      value: "Hirasugar Institute of Technology, Nidasoshi",
    },
    {
      label: "HKBK College of Engineering",
      value: "HKBK College of Engineering",
    },
    {
      label: "HMR Institute of Technology & Management, GGSIPU",
      value: "HMR Institute of Technology & Management, GGSIPU",
    },
    {
      label: "HMS Institute of Technology",
      value: "HMS Institute of Technology",
    },
    {
      label: "Hofstra University",
      value: "Hofstra University",
    },
    {
      label: "Hogeschool Thomas More",
      value: "Hogeschool Thomas More",
    },
    {
      label: "Hogeschool van Amsterdam",
      value: "Hogeschool van Amsterdam",
    },
    {
      label: "Holton-Arms School",
      value: "Holton-Arms School",
    },
    {
      label: "Holy Family University",
      value: "Holy Family University",
    },
    {
      label: "Homestead High School",
      value: "Homestead High School",
    },
    {
      label: "Hong Kong University of Science and Technology",
      value: "Hong Kong University of Science and Technology",
    },
    {
      label: "Hood College",
      value: "Hood College",
    },
    {
      label: "Horace Furness High School",
      value: "Horace Furness High School",
    },
    {
      label: "Horace Mann School",
      value: "Horace Mann School",
    },
    {
      label: "Hostos Community College, CUNY",
      value: "Hostos Community College, CUNY",
    },
    {
      label: "Houghton High School",
      value: "Houghton High School",
    },
    {
      label: "Houston Community College",
      value: "Houston Community College",
    },
    {
      label: "Howard University",
      value: "Howard University",
    },
    {
      label: "Hudson County Community College",
      value: "Hudson County Community College",
    },
    {
      label: "Hudson Valley Community College",
      value: "Hudson Valley Community College",
    },
    {
      label: "Hunter College High School",
      value: "Hunter College High School",
    },
    {
      label: "Hunter College, CUNY",
      value: "Hunter College, CUNY",
    },
    {
      label: "Huron Heights Secondary School",
      value: "Huron Heights Secondary School",
    },
    {
      label: "Hussian School of Art",
      value: "Hussian School of Art",
    },
    {
      label: "I.K. Gujral Punjab Technical University Jalandhar (IKGPTU)",
      value: "I.K. Gujral Punjab Technical University Jalandhar (IKGPTU)",
    },
    {
      label: "I.T.S Engineering College",
      value: "I.T.S Engineering College",
    },
    {
      label: "IAN Mentoring and Incubation Services",
      value: "IAN Mentoring and Incubation Services",
    },
    {
      label: "IIMT College of Engineering, Greater Noida",
      value: "IIMT College of Engineering, Greater Noida",
    },
    {
      label: "IIMT College Of Medical Sciences, Meerut",
      value: "IIMT College Of Medical Sciences, Meerut",
    },
    {
      label: "IIMT College of Pharmacy, Greater Noida",
      value: "IIMT College of Pharmacy, Greater Noida",
    },
    {
      label: "IIMT Engineering College, Meerut",
      value: "IIMT Engineering College, Meerut",
    },
    {
      label: "IKP Knowledge Park Erstwhile ICICI Knowledge Park",
      value: "IKP Knowledge Park Erstwhile ICICI Knowledge Park",
    },
    {
      label: "Iliria College",
      value: "Iliria College",
    },
    {
      label: "Illinois Institute of Technology",
      value: "Illinois Institute of Technology",
    },
    {
      label: "Illinois State University",
      value: "Illinois State University",
    },
    {
      label: "Imhotep Institute Charter High School",
      value: "Imhotep Institute Charter High School",
    },
    {
      label: "Immaculata University",
      value: "Immaculata University",
    },
    {
      label: "Impact College of Engineering and Applied Science",
      value: "Impact College of Engineering and Applied Science",
    },
    {
      label: "Imperial College London",
      value: "Imperial College London",
    },
    {
      label: "IMS Engineering College",
      value: "IMS Engineering College",
    },
    {
      label: "Inderprastha Engineering College (IPEC)",
      value: "Inderprastha Engineering College (IPEC)",
    },
    {
      label: "Indian Hills Community College",
      value: "Indian Hills Community College",
    },
    {
      label:
        "Indian Institute of Engineering Science and Technology (IIEST), Shibpur",
      value:
        "Indian Institute of Engineering Science and Technology (IIEST), Shibpur",
    },
    {
      label:
        "Indian Institute of Information Technology Design & Manufacturing, Jabalpur",
      value:
        "Indian Institute of Information Technology Design & Manufacturing, Jabalpur",
    },
    {
      label: "Indian Institute of Information Technology, Allahabad",
      value: "Indian Institute of Information Technology, Allahabad",
    },
    {
      label: "Indian Institute of Information Technology, Kalyani",
      value: "Indian Institute of Information Technology, Kalyani",
    },
    {
      label: "Indian Institute of Information Technology, Kottayam",
      value: "Indian Institute of Information Technology, Kottayam",
    },
    {
      label: "Indian Institute of Information Technology, Pune",
      value: "Indian Institute of Information Technology, Pune",
    },
    {
      label: "Indian Institute of Information Technology, Sri City",
      value: "Indian Institute of Information Technology, Sri City",
    },
    {
      label: "Indian Institute of Information Technology, Una",
      value: "Indian Institute of Information Technology, Una",
    },
    {
      label: "Indian Institute of Information Technology, Vadodara",
      value: "Indian Institute of Information Technology, Vadodara",
    },
    {
      label: "Indian Institute of Space Science and Technology (IIST)",
      value: "Indian Institute of Space Science and Technology (IIST)",
    },
    {
      label: "Indian Institute of Technology (ISM), Dhanbad",
      value: "Indian Institute of Technology (ISM), Dhanbad",
    },
    {
      label: "Indian Institute of Technology, BHU",
      value: "Indian Institute of Technology, BHU",
    },
    {
      label: "Indian Institute of Technology, Bhubaneswar",
      value: "Indian Institute of Technology, Bhubaneswar",
    },
    {
      label: "Indian Institute of Technology, Bombay",
      value: "Indian Institute of Technology, Bombay",
    },
    {
      label: "Indian Institute of Technology, Gandhinagar",
      value: "Indian Institute of Technology, Gandhinagar",
    },
    {
      label: "Indian Institute of Technology, Guwahati",
      value: "Indian Institute of Technology, Guwahati",
    },
    {
      label: "Indian Institute of Technology, Gwalior",
      value: "Indian Institute of Technology, Gwalior",
    },
    {
      label: "Indian Institute of Technology, Hyderabad",
      value: "Indian Institute of Technology, Hyderabad",
    },
    {
      label: "Indian Institute of Technology, Jabalpur",
      value: "Indian Institute of Technology, Jabalpur",
    },
    {
      label: "Indian Institute of Technology, Jodhpur",
      value: "Indian Institute of Technology, Jodhpur",
    },
    {
      label: "Indian Institute of Technology, Kanpur",
      value: "Indian Institute of Technology, Kanpur",
    },
    {
      label: "Indian Institute of Technology, Kharagpur",
      value: "Indian Institute of Technology, Kharagpur",
    },
    {
      label: "Indian Institute of Technology, Kota",
      value: "Indian Institute of Technology, Kota",
    },
    {
      label: "Indian Institute of Technology, Madras",
      value: "Indian Institute of Technology, Madras",
    },
    {
      label: "Indian Institute of Technology, Patna",
      value: "Indian Institute of Technology, Patna",
    },
    {
      label: "Indian Institute of Technology, Roorkee",
      value: "Indian Institute of Technology, Roorkee",
    },
    {
      label: "Indian Institute of Technology, Ropar",
      value: "Indian Institute of Technology, Ropar",
    },
    {
      label: "Indiana State University",
      value: "Indiana State University",
    },
    {
      label: "Indiana University",
      value: "Indiana University",
    },
    {
      label: "Indiana University of Pennsylvania",
      value: "Indiana University of Pennsylvania",
    },
    {
      label: "Indiana University-Purdue University Fort Wayne",
      value: "Indiana University-Purdue University Fort Wayne",
    },
    {
      label: "Indiana University–Purdue University Indianapolis",
      value: "Indiana University–Purdue University Indianapolis",
    },
    {
      label: "Indira Gandhi Delhi Technical University for Women",
      value: "Indira Gandhi Delhi Technical University for Women",
    },
    {
      label: "Indira Gandhi Engineering College, Sagar",
      value: "Indira Gandhi Engineering College, Sagar",
    },
    {
      label: "Indira Gandhi Institute of Technology, Sarang",
      value: "Indira Gandhi Institute of Technology, Sarang",
    },
    {
      label: "Indira Gandhi National Open University",
      value: "Indira Gandhi National Open University",
    },
    {
      label: "Indraprastha Institute of Information Technology",
      value: "Indraprastha Institute of Information Technology",
    },
    {
      label: "Indus University, Ahmedabad",
      value: "Indus University, Ahmedabad",
    },
    {
      label: "Insight PA Cyber Charter School",
      value: "Insight PA Cyber Charter School",
    },
    {
      label: "Institut polytechnique de Bordeaux (INP)",
      value: "Institut polytechnique de Bordeaux (INP)",
    },
    {
      label: "Institute for Auto Parts and Hand Tools Technology",
      value: "Institute for Auto Parts and Hand Tools Technology",
    },
    {
      label: "Institute of Aeronautical Engineering (IARE), Hyderabad",
      value: "Institute of Aeronautical Engineering (IARE), Hyderabad",
    },
    {
      label: "Institute of Engineering & Management (IEM)",
      value: "Institute of Engineering & Management (IEM)",
    },
    {
      label: "Institute of Engineering and Rural Technology Allahabad",
      value: "Institute of Engineering and Rural Technology Allahabad",
    },
    {
      label:
        "Institute of Infrastructure Technology Research and Management, Ahmedabad",
      value:
        "Institute of Infrastructure Technology Research and Management, Ahmedabad",
    },
    {
      label: "Institute of Technical Education and Research (ITER), Bhubaneswar",
      value: "Institute of Technical Education and Research (ITER), Bhubaneswar",
    },
    {
      label: "Institute of Technology, Banaras Hindu University",
      value: "Institute of Technology, Banaras Hindu University",
    },
    {
      label: "Institute Of Technology, Nirma University",
      value: "Institute Of Technology, Nirma University",
    },
    {
      label: "Instituto Politécnico Nacional",
      value: "Instituto Politécnico Nacional",
    },
    {
      label: "Instituto Tecnológico Autónomo de México (ITAM)",
      value: "Instituto Tecnológico Autónomo de México (ITAM)",
    },
    {
      label: "Instituto Tecnólogico de La Laguna (ITL)",
      value: "Instituto Tecnólogico de La Laguna (ITL)",
    },
    {
      label: "Instituto Tecnológico Superior de Cintalapa",
      value: "Instituto Tecnológico Superior de Cintalapa",
    },
    {
      label: "Instituto Tecnológico Superior de El Mante",
      value: "Instituto Tecnológico Superior de El Mante",
    },
    {
      label: "Instituto Tecnológico Superior de los Ríos",
      value: "Instituto Tecnológico Superior de los Ríos",
    },
    {
      label: "Instituto Tecnologico Superior de San Martin Texmelucan",
      value: "Instituto Tecnologico Superior de San Martin Texmelucan",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Aguascalientes",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Aguascalientes",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Chiapas",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Chiapas",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Chihuahua",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Chihuahua",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Ciudad de Mexico",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Ciudad de Mexico",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Ciudad Juárez",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Ciudad Juárez",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Cuernavaca",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Cuernavaca",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Cumbres",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Cumbres",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Eugenio Garza Lagüera",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Eugenio Garza Lagüera",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Eugenio Garza Sada",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Eugenio Garza Sada",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Guadalajara",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Guadalajara",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Hidalgo",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Hidalgo",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Irapuato",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Irapuato",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Laguna",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Laguna",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus León",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus León",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Morelia",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Morelia",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Obregón",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Obregón",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Puebla",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Puebla",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Querétaro",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Querétaro",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Saltillo",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Saltillo",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus San Luis Potosí",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus San Luis Potosí",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Santa Catarina",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Santa Catarina",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Santa Fe",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Santa Fe",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Sinaloa",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Sinaloa",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Sonora",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Sonora",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Tampico",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Tampico",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Toluca",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Toluca",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Valle Alto",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Valle Alto",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Veracruz",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Veracruz",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Zacatecas",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM) Campus Zacatecas",
    },
    {
      label:
        "Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO)",
      value:
        "Instituto Tecnológico y de Estudios Superiores de Occidente (ITESO)",
    },
    {
      label: "Instytut Pamięci Narodowej",
      value: "Instytut Pamięci Narodowej",
    },
    {
      label: "International Institute of Information Technology,  Hyderabad",
      value: "International Institute of Information Technology,  Hyderabad",
    },
    {
      label: "International Institute of Information Technology, Bangalore",
      value: "International Institute of Information Technology, Bangalore",
    },
    {
      label: "International Institute Of Information Technology, Naya Raipur",
      value: "International Institute Of Information Technology, Naya Raipur",
    },
    {
      label: "International Leadership Charter High School",
      value: "International Leadership Charter High School",
    },
    {
      label: "International School of Choueifat",
      value: "International School of Choueifat",
    },
    {
      label: "Iowa Central Community College",
      value: "Iowa Central Community College",
    },
    {
      label: "Iowa State University",
      value: "Iowa State University",
    },
    {
      label: "Iowa Western Community College",
      value: "Iowa Western Community College",
    },
    {
      label: "Islamic University of Science and Technology, Pulwama",
      value: "Islamic University of Science and Technology, Pulwama",
    },
    {
      label: "Istanbul University",
      value: "Istanbul University",
    },
    {
      label: "IT University of Copenhagen",
      value: "IT University of Copenhagen",
    },
    {
      label: "Ithaca College",
      value: "Ithaca College",
    },
    {
      label: "ITM University, Gwalior",
      value: "ITM University, Gwalior",
    },
    {
      label: "ITM University, Vadodara",
      value: "ITM University, Vadodara",
    },
    {
      label: "ITMO University",
      value: "ITMO University",
    },
    {
      label: "J.C. Bose University of Science and Technology, YMCA",
      value: "J.C. Bose University of Science and Technology, YMCA",
    },
    {
      label: "J.N.N College of Engineering",
      value: "J.N.N College of Engineering",
    },
    {
      label: "Jabalpur Engineering College",
      value: "Jabalpur Engineering College",
    },
    {
      label: "Jackson Memorial High School",
      value: "Jackson Memorial High School",
    },
    {
      label: "Jacobs University Bremen",
      value: "Jacobs University Bremen",
    },
    {
      label: "Jadavpur University",
      value: "Jadavpur University",
    },
    {
      label: "Jagiellonian University",
      value: "Jagiellonian University",
    },
    {
      label: "Jai Narain Vyas University",
      value: "Jai Narain Vyas University",
    },
    {
      label: "Jaipur Engineering College & Research Centre (JECRC)",
      value: "Jaipur Engineering College & Research Centre (JECRC)",
    },
    {
      label: "Jaipur National University",
      value: "Jaipur National University",
    },
    {
      label: "Jalpaiguri Government Engineering College",
      value: "Jalpaiguri Government Engineering College",
    },
    {
      label: "James Gillespie's High School",
      value: "James Gillespie's High School",
    },
    {
      label: "James Madison High School",
      value: "James Madison High School",
    },
    {
      label: "James Madison University",
      value: "James Madison University",
    },
    {
      label: "Jamia Hamdard",
      value: "Jamia Hamdard",
    },
    {
      label: "Jamia Millia Islamia - JMI,  Jamia Nagar",
      value: "Jamia Millia Islamia - JMI,  Jamia Nagar",
    },
    {
      label: "Jawaharlal Nehru Government Engineering College (JNGEC)",
      value: "Jawaharlal Nehru Government Engineering College (JNGEC)",
    },
    {
      label: "Jawaharlal Nehru Technological University",
      value: "Jawaharlal Nehru Technological University",
    },
    {
      label: "Jaypee Institute of Technology,  Noida",
      value: "Jaypee Institute of Technology,  Noida",
    },
    {
      label: "Jaypee University of Engineering and Technology",
      value: "Jaypee University of Engineering and Technology",
    },
    {
      label: "Jerusalem College of Engineering",
      value: "Jerusalem College of Engineering",
    },
    {
      label: "JK Institute of Applied Physics and Technology",
      value: "JK Institute of Applied Physics and Technology",
    },
    {
      label: "JK Lakshmipat University (JKLU)",
      value: "JK Lakshmipat University (JKLU)",
    },
    {
      label: "Jnanavikas Institute of Technology",
      value: "Jnanavikas Institute of Technology",
    },
    {
      label: "JNTUA College of Engineering,  Pulivendula",
      value: "JNTUA College of Engineering,  Pulivendula",
    },
    {
      label: "JNTUH College of Engineering, HYDERABAD",
      value: "JNTUH College of Engineering, HYDERABAD",
    },
    {
      label: "JNTUK University College of Engineering, Vizianagaram",
      value: "JNTUK University College of Engineering, Vizianagaram",
    },
    {
      label: "Jodhpur Institute of Engineering and Technology (JIET)",
      value: "Jodhpur Institute of Engineering and Technology (JIET)",
    },
    {
      label: "John A. Ferguson Senior High School",
      value: "John A. Ferguson Senior High School",
    },
    {
      label: "John Abbott College",
      value: "John Abbott College",
    },
    {
      label: "John Bartram High School",
      value: "John Bartram High School",
    },
    {
      label: "John F. Kennedy Memorial High School",
      value: "John F. Kennedy Memorial High School",
    },
    {
      label: "John Jay College of Criminal Justice, CUNY",
      value: "John Jay College of Criminal Justice, CUNY",
    },
    {
      label: "John Leggott College",
      value: "John Leggott College",
    },
    {
      label: "John P. Stevens High School",
      value: "John P. Stevens High School",
    },
    {
      label: "Johns Hopkins University",
      value: "Johns Hopkins University",
    },
    {
      label: "Johnson & Wales University",
      value: "Johnson & Wales University",
    },
    {
      label: "Johnson C. Smith University",
      value: "Johnson C. Smith University",
    },
    {
      label: "Jorhat Engineering College",
      value: "Jorhat Engineering College",
    },
    {
      label: "Jorhat Institute of Science and Technology",
      value: "Jorhat Institute of Science and Technology",
    },
    {
      label: "JSS Academy of Technical Education",
      value: "JSS Academy of Technical Education",
    },
    {
      label: "Jules E. Mastbaum Technical High School",
      value: "Jules E. Mastbaum Technical High School",
    },
    {
      label: "Julia R. Masterman School",
      value: "Julia R. Masterman School",
    },
    {
      label: "Jyothy Institute of Technology",
      value: "Jyothy Institute of Technology",
    },
    {
      label: "K S School of Engineering and Management",
      value: "K S School of Engineering and Management",
    },
    {
      label: "K. S Institute of Technology (KSIT), Bengaluru",
      value: "K. S Institute of Technology (KSIT), Bengaluru",
    },
    {
      label: "K.L. College of Engineering",
      value: "K.L. College of Engineering",
    },
    {
      label: "K.L.S Gogte Institute of Technology",
      value: "K.L.S Gogte Institute of Technology",
    },
    {
      label: "K.M.E.A Engineering College",
      value: "K.M.E.A Engineering College",
    },
    {
      label: "K.S Rangasamy College Of Technology",
      value: "K.S Rangasamy College Of Technology",
    },
    {
      label: "K.S. School of Business Management",
      value: "K.S. School of Business Management",
    },
    {
      label: "Kamla Nehru Institute of Technology",
      value: "Kamla Nehru Institute of Technology",
    },
    {
      label: "Kansai University",
      value: "Kansai University",
    },
    {
      label: "Kansas State University",
      value: "Kansas State University",
    },
    {
      label: "Kantipur Engineering College",
      value: "Kantipur Engineering College",
    },
    {
      label: "Karlsruhe Institute of Technology",
      value: "Karlsruhe Institute of Technology",
    },
    {
      label: "Karmaveer Bhaurao Patil College of Engineering",
      value: "Karmaveer Bhaurao Patil College of Engineering",
    },
    {
      label: "Karpagam College of Engineering (KCE)",
      value: "Karpagam College of Engineering (KCE)",
    },
    {
      label: "Karunya Institute of Technology and Sciences",
      value: "Karunya Institute of Technology and Sciences",
    },
    {
      label: "Kashi Institute of Technology",
      value: "Kashi Institute of Technology",
    },
    {
      label: "Kathmandu BernHardt College",
      value: "Kathmandu BernHardt College",
    },
    {
      label: "Kaunas University of Technology",
      value: "Kaunas University of Technology",
    },
    {
      label: "KCG College of Engineering",
      value: "KCG College of Engineering",
    },
    {
      label: "Kean University",
      value: "Kean University",
    },
    {
      label: "Keele University",
      value: "Keele University",
    },
    {
      label: "Kendriya Vidyalaya, AFS, Begumpet",
      value: "Kendriya Vidyalaya, AFS, Begumpet",
    },
    {
      label: "Kennesaw State University",
      value: "Kennesaw State University",
    },
    {
      label: "Kennett High School",
      value: "Kennett High School",
    },
    {
      label: "Kensington High School Complex",
      value: "Kensington High School Complex",
    },
    {
      label: "Kent State University",
      value: "Kent State University",
    },
    {
      label: "Kent State University at Stark",
      value: "Kent State University at Stark",
    },
    {
      label: "Keshav Memorial Institute of Technology,  Hyderabad",
      value: "Keshav Memorial Institute of Technology,  Hyderabad",
    },
    {
      label: "King Edward VI Five Ways School",
      value: "King Edward VI Five Ways School",
    },
    {
      label: "King's College London",
      value: "King's College London",
    },
    {
      label: "Kingsborough Community College, CUNY",
      value: "Kingsborough Community College, CUNY",
    },
    {
      label: "Kingsway Regional High School",
      value: "Kingsway Regional High School",
    },
    {
      label: "KIPP DuBois Charter School",
      value: "KIPP DuBois Charter School",
    },
    {
      label: "Kitchener-Waterloo Collegiate & Vocational School",
      value: "Kitchener-Waterloo Collegiate & Vocational School",
    },
    {
      label: "KJ's Educational Institutes,  Pune",
      value: "KJ's Educational Institutes,  Pune",
    },
    {
      label: "KLE Dr. M.S. Sheshgiri College of Engineering and Technology",
      value: "KLE Dr. M.S. Sheshgiri College of Engineering and Technology",
    },
    {
      label: "KLN College of Engineering",
      value: "KLN College of Engineering",
    },
    {
      label: "KLS Gogte Institute of Technology",
      value: "KLS Gogte Institute of Technology",
    },
    {
      label: "Knox College",
      value: "Knox College",
    },
    {
      label: "KNSIT",
      value: "KNSIT",
    },
    {
      label: "Konark Institute of Science and Technology",
      value: "Konark Institute of Science and Technology",
    },
    {
      label: "Kongu Engineering College",
      value: "Kongu Engineering College",
    },
    {
      label: "Koustuv Group Of Institutions (KISD & COEB)",
      value: "Koustuv Group Of Institutions (KISD & COEB)",
    },
    {
      label: "Kraków University of Economics",
      value: "Kraków University of Economics",
    },
    {
      label: "Krishi Vigyan Kendra, Durgapur",
      value: "Krishi Vigyan Kendra, Durgapur",
    },
    {
      label: "Krishna Engineering College",
      value: "Krishna Engineering College",
    },
    {
      label: "Kshatriya College of Engineering",
      value: "Kshatriya College of Engineering",
    },
    {
      label: "KTH Royal Institute of Technology",
      value: "KTH Royal Institute of Technology",
    },
    {
      label: "Kumaraguru College Of Technology",
      value: "Kumaraguru College Of Technology",
    },
    {
      label: "L D College Of Engineering Library",
      value: "L D College Of Engineering Library",
    },
    {
      label: "L. D. College of Engineering",
      value: "L. D. College of Engineering",
    },
    {
      label: "La Roche College",
      value: "La Roche College",
    },
    {
      label: "La Salle University - Philadelphia",
      value: "La Salle University - Philadelphia",
    },
    {
      label: "La Sierra University",
      value: "La Sierra University",
    },
    {
      label: "Lady Doak College",
      value: "Lady Doak College",
    },
    {
      label: "Lafayette College",
      value: "Lafayette College",
    },
    {
      label: "LaGuardia Community College, CUNY",
      value: "LaGuardia Community College, CUNY",
    },
    {
      label: "Lake Braddock Secondary School",
      value: "Lake Braddock Secondary School",
    },
    {
      label: "Lakeside High School",
      value: "Lakeside High School",
    },
    {
      label: "Lakshmi Narayan College of Technology (LNCT)",
      value: "Lakshmi Narayan College of Technology (LNCT)",
    },
    {
      label: "Lampeter-Strasburg High School",
      value: "Lampeter-Strasburg High School",
    },
    {
      label: "Lancaster University",
      value: "Lancaster University",
    },
    {
      label: "Lankenau High School",
      value: "Lankenau High School",
    },
    {
      label: "Laval University",
      value: "Laval University",
    },
    {
      label: "Lawrence Technological University",
      value: "Lawrence Technological University",
    },
    {
      label: "Lawrence University",
      value: "Lawrence University",
    },
    {
      label: "LBS Institute of Technology for Women (LBSITW)",
      value: "LBS Institute of Technology for Women (LBSITW)",
    },
    {
      label: "Lehigh University",
      value: "Lehigh University",
    },
    {
      label: "Lehman College, CUNY",
      value: "Lehman College, CUNY",
    },
    {
      label: "Leiden University",
      value: "Leiden University",
    },
    {
      label: "Lewis & Clark College",
      value: "Lewis & Clark College",
    },
    {
      label: "Lewis University",
      value: "Lewis University",
    },
    {
      label: "Lexington High School",
      value: "Lexington High School",
    },
    {
      label: "LICET",
      value: "LICET",
    },
    {
      label: "Lick Wilmerding High School",
      value: "Lick Wilmerding High School",
    },
    {
      label: "LIM College",
      value: "LIM College",
    },
    {
      label: "Lincoln Christian University",
      value: "Lincoln Christian University",
    },
    {
      label: "Lincoln Technical Institute - Center City Philadelphia",
      value: "Lincoln Technical Institute - Center City Philadelphia",
    },
    {
      label: "Lincoln Technical Institute - Northeast Philadelphia",
      value: "Lincoln Technical Institute - Northeast Philadelphia",
    },
    {
      label: "Lincoln University",
      value: "Lincoln University",
    },
    {
      label: "Lindenwood University",
      value: "Lindenwood University",
    },
    {
      label: "Linn-Mar High School",
      value: "Linn-Mar High School",
    },
    {
      label: "Lisgar Collegiate Institute",
      value: "Lisgar Collegiate Institute",
    },
    {
      label: "Little Flowers Public Sr Secondary School",
      value: "Little Flowers Public Sr Secondary School",
    },
    {
      label: "Livingston High School",
      value: "Livingston High School",
    },
    {
      label: "Loch Raven High School",
      value: "Loch Raven High School",
    },
    {
      label: "Lodz University of Technology",
      value: "Lodz University of Technology",
    },
    {
      label: "Loknayak Jai Prakash Institute of Technology,  Chhapra",
      value: "Loknayak Jai Prakash Institute of Technology,  Chhapra",
    },
    {
      label: "London Metropolitan University",
      value: "London Metropolitan University",
    },
    {
      label: "London School of Economics and Political Science",
      value: "London School of Economics and Political Science",
    },
    {
      label: "Lone Star College System",
      value: "Lone Star College System",
    },
    {
      label: "Lord Krishna College of Engineering",
      value: "Lord Krishna College of Engineering",
    },
    {
      label: "Lords Institute of Engineering & Technology",
      value: "Lords Institute of Engineering & Technology",
    },
    {
      label: "Los Altos High School",
      value: "Los Altos High School",
    },
    {
      label: "Loughborough University",
      value: "Loughborough University",
    },
    {
      label: "Louisiana State University",
      value: "Louisiana State University",
    },
    {
      label: "Lovely Professional University",
      value: "Lovely Professional University",
    },
    {
      label: "Lowell High School",
      value: "Lowell High School",
    },
    {
      label: "Loyola Marymount University",
      value: "Loyola Marymount University",
    },
    {
      label: "Luleå University of Technology, LTU",
      value: "Luleå University of Technology, LTU",
    },
    {
      label: "Luther College",
      value: "Luther College",
    },
    {
      label: "Lyallpur Khalsa College of Engineering, Jalandhar",
      value: "Lyallpur Khalsa College of Engineering, Jalandhar",
    },
    {
      label: "Lynbrook High School",
      value: "Lynbrook High School",
    },
    {
      label: "M.J.P. Rohilkhand University",
      value: "M.J.P. Rohilkhand University",
    },
    {
      label: "M.S. Ramaiah School of Advance Studies",
      value: "M.S. Ramaiah School of Advance Studies",
    },
    {
      label: "M.V.Jayaraman College of Engineering",
      value: "M.V.Jayaraman College of Engineering",
    },
    {
      label: "Macalester College",
      value: "Macalester College",
    },
    {
      label: "MacArthur High School",
      value: "MacArthur High School",
    },
    {
      label: "Macaulay Honors College, CUNY",
      value: "Macaulay Honors College, CUNY",
    },
    {
      label: "Macomb Community College",
      value: "Macomb Community College",
    },
    {
      label: "Madan Mohan Malaviya University of Technology, Gorakhpur",
      value: "Madan Mohan Malaviya University of Technology, Gorakhpur",
    },
    {
      label: "Madhav Institute of Technology & Science (MITS)",
      value: "Madhav Institute of Technology & Science (MITS)",
    },
    {
      label: "Madison College",
      value: "Madison College",
    },
    {
      label: "Madison West High School",
      value: "Madison West High School",
    },
    {
      label: "Madras Institute Of Technology",
      value: "Madras Institute Of Technology",
    },
    {
      label: "Maggie L. Walker Governor's School",
      value: "Maggie L. Walker Governor's School",
    },
    {
      label: "Mahakal Institute Of Technology",
      value: "Mahakal Institute Of Technology",
    },
    {
      label: "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
      value: "Maharaj Vijayaram Gajapathi Raj College of Engineering (MVGRCE)",
    },
    {
      label: "Maharaja Agrasen Institute of Technology",
      value: "Maharaja Agrasen Institute of Technology",
    },
    {
      label: "Maharaja Surajmal Institute of Technology",
      value: "Maharaja Surajmal Institute of Technology",
    },
    {
      label: "Maharashtra Institute of Technology, Pune",
      value: "Maharashtra Institute of Technology, Pune",
    },
    {
      label: "Mahatma Gandhi Institute for Rural Industrialization (MGIRI)",
      value: "Mahatma Gandhi Institute for Rural Industrialization (MGIRI)",
    },
    {
      label: "Mahatma Gandhi Institute of Technology (MGIT)",
      value: "Mahatma Gandhi Institute of Technology (MGIT)",
    },
    {
      label: "Mahendra Engineering College",
      value: "Mahendra Engineering College",
    },
    {
      label: "Mailam Engineering College",
      value: "Mailam Engineering College",
    },
    {
      label: "Maine South High School",
      value: "Maine South High School",
    },
    {
      label: "Maitreyi College, University of Delhi",
      value: "Maitreyi College, University of Delhi",
    },
    {
      label: "Majhighariani Institute Of technology & Science (MITS)",
      value: "Majhighariani Institute Of technology & Science (MITS)",
    },
    {
      label: "Malaviya National Institute of Technology",
      value: "Malaviya National Institute of Technology",
    },
    {
      label: "Malineni Lakshmaiah Women's Engineering College",
      value: "Malineni Lakshmaiah Women's Engineering College",
    },
    {
      label: "Malla Reddy College of Engineering Technology",
      value: "Malla Reddy College of Engineering Technology",
    },
    {
      label: "Malla Reddy Engineering College (MREC)",
      value: "Malla Reddy Engineering College (MREC)",
    },
    {
      label: "Malla Reddy Institute Of Engineering And Technology (MRIET)",
      value: "Malla Reddy Institute Of Engineering And Technology (MRIET)",
    },
    {
      label: "Malnad College of Engineering",
      value: "Malnad College of Engineering",
    },
    {
      label: "Malvern Preparatory School",
      value: "Malvern Preparatory School",
    },
    {
      label: "Malvern Preparatory School",
      value: "Malvern Preparatory School",
    },
    {
      label: "Manakula Vinayagar Institute of Techology",
      value: "Manakula Vinayagar Institute of Techology",
    },
    {
      label: "Manalapan High School",
      value: "Manalapan High School",
    },
    {
      label: "Manav Rachna International",
      value: "Manav Rachna International",
    },
    {
      label: "Manchester Metropolitan University",
      value: "Manchester Metropolitan University",
    },
    {
      label: "Manhattan College",
      value: "Manhattan College",
    },
    {
      label: "Manhattan High School",
      value: "Manhattan High School",
    },
    {
      label: "Manipal Institute of Technology",
      value: "Manipal Institute of Technology",
    },
    {
      label: "Manipal University",
      value: "Manipal University",
    },
    {
      label: "Manipal University, Jaipur",
      value: "Manipal University, Jaipur",
    },
    {
      label: "Manor College",
      value: "Manor College",
    },
    {
      label: "Mar Athanasius College of Engineering",
      value: "Mar Athanasius College of Engineering",
    },
    {
      label: "Marc Garneau Collegiate Institute",
      value: "Marc Garneau Collegiate Institute",
    },
    {
      label: "Marcellus High School",
      value: "Marcellus High School",
    },
    {
      label: "Mariana Bracetti Academy Charter School",
      value: "Mariana Bracetti Academy Charter School",
    },
    {
      label: "Marianopolis College",
      value: "Marianopolis College",
    },
    {
      label: "Marist College",
      value: "Marist College",
    },
    {
      label: "Maritime Academy Charter School (MACHS)",
      value: "Maritime Academy Charter School (MACHS)",
    },
    {
      label: "Markham District High School",
      value: "Markham District High School",
    },
    {
      label: "Markville Secondary School",
      value: "Markville Secondary School",
    },
    {
      label: "Marlboro High School",
      value: "Marlboro High School",
    },
    {
      label: "Marquette University",
      value: "Marquette University",
    },
    {
      label: "Marshall High School",
      value: "Marshall High School",
    },
    {
      label: "Martin Luther King High School",
      value: "Martin Luther King High School",
    },
    {
      label: "Marymount University",
      value: "Marymount University",
    },
    {
      label: "Masaryk University",
      value: "Masaryk University",
    },
    {
      label: "Massachusetts Institute of Technology",
      value: "Massachusetts Institute of Technology",
    },
    {
      label: "Mastery Charter School - Hardy Williams Academy",
      value: "Mastery Charter School - Hardy Williams Academy",
    },
    {
      label: "Mastery Charter School - Thomas Campus",
      value: "Mastery Charter School - Thomas Campus",
    },
    {
      label: "Mastery Charter School at Lenfest Campus",
      value: "Mastery Charter School at Lenfest Campus",
    },
    {
      label: "Mastery Charter School at Pickett Campus",
      value: "Mastery Charter School at Pickett Campus",
    },
    {
      label: "Mastery Charter School at Shoemaker Campus",
      value: "Mastery Charter School at Shoemaker Campus",
    },
    {
      label: "Mata Gujri College",
      value: "Mata Gujri College",
    },
    {
      label: "Mater Academy High School",
      value: "Mater Academy High School",
    },
    {
      label: "Math, Civics and Sciences Charter School - Philadelphia",
      value: "Math, Civics and Sciences Charter School - Philadelphia",
    },
    {
      label:
        "Mathematics, Science, and Technology Community Charter School (MaST)",
      value:
        "Mathematics, Science, and Technology Community Charter School (MaST)",
    },
    {
      label: "Matrusri Engineering College,  Hyderabad",
      value: "Matrusri Engineering College,  Hyderabad",
    },
    {
      label: "Maulana Abul Kalam Azad University of Technology",
      value: "Maulana Abul Kalam Azad University of Technology",
    },
    {
      label: "Maulana Azad National Institute of Technology",
      value: "Maulana Azad National Institute of Technology",
    },
    {
      label: "Maulana Azad National Institute of Technology Bhopal",
      value: "Maulana Azad National Institute of Technology Bhopal",
    },
    {
      label: "Maumee Valley Country Day School",
      value: "Maumee Valley Country Day School",
    },
    {
      label: "MBM Engineering College, Jodhpur",
      value: "MBM Engineering College, Jodhpur",
    },
    {
      label: "McGill University",
      value: "McGill University",
    },
    {
      label: "McMaster University",
      value: "McMaster University",
    },
    {
      label: "Medgar Evers College, CUNY",
      value: "Medgar Evers College, CUNY",
    },
    {
      label: "Medical University of Silesia",
      value: "Medical University of Silesia",
    },
    {
      label: "Meerut Institute of Engineering and Technology (MIET)",
      value: "Meerut Institute of Engineering and Technology (MIET)",
    },
    {
      label: "Menlo School",
      value: "Menlo School",
    },
    {
      label: "Mepco Schlenk Engineering College",
      value: "Mepco Schlenk Engineering College",
    },
    {
      label: "Merced College",
      value: "Merced College",
    },
    {
      label: "Mercer County Community College",
      value: "Mercer County Community College",
    },
    {
      label: "Mercer University",
      value: "Mercer University",
    },
    {
      label: "Meredith College",
      value: "Meredith College",
    },
    {
      label: "Messiah College",
      value: "Messiah College",
    },
    {
      label: "Metas Adventist School",
      value: "Metas Adventist School",
    },
    {
      label: "Metropolia University of Applied Sciences",
      value: "Metropolia University of Applied Sciences",
    },
    {
      label: "Metropolitan State University",
      value: "Metropolitan State University",
    },
    {
      label: "Metuchen High School",
      value: "Metuchen High School",
    },
    {
      label: "Mewar University Chittorgarh",
      value: "Mewar University Chittorgarh",
    },
    {
      label: "Miami Dade College",
      value: "Miami Dade College",
    },
    {
      label: "Miami Lakes Educational Center",
      value: "Miami Lakes Educational Center",
    },
    {
      label: "Miami University",
      value: "Miami University",
    },
    {
      label: "Michigan State University",
      value: "Michigan State University",
    },
    {
      label: "Michigan Technological University",
      value: "Michigan Technological University",
    },
    {
      label: "Microsoft School of the Future High School",
      value: "Microsoft School of the Future High School",
    },
    {
      label: "Middle Tennessee State University",
      value: "Middle Tennessee State University",
    },
    {
      label: "Middlebury College",
      value: "Middlebury College",
    },
    {
      label: "Middlesex County Academy",
      value: "Middlesex County Academy",
    },
    {
      label: "Middlesex County Academy For Allied Health And Biomedical Sciences",
      value: "Middlesex County Academy For Allied Health And Biomedical Sciences",
    },
    {
      label:
        "Middlesex County Academy for Science, Mathematics & Engineering Technologies",
      value:
        "Middlesex County Academy for Science, Mathematics & Engineering Technologies",
    },
    {
      label: "Middlesex County College",
      value: "Middlesex County College",
    },
    {
      label: "Middlesex University",
      value: "Middlesex University",
    },
    {
      label: "Middleton High School",
      value: "Middleton High School",
    },
    {
      label: "Middletown High School South",
      value: "Middletown High School South",
    },
    {
      label: "Midwood",
      value: "Midwood",
    },
    {
      label: "Miles College",
      value: "Miles College",
    },
    {
      label: "Millburn High School",
      value: "Millburn High School",
    },
    {
      label: "Millburn Middle School",
      value: "Millburn Middle School",
    },
    {
      label: "Millville Senior High School",
      value: "Millville Senior High School",
    },
    {
      label: "Milwaukee School of Engineering",
      value: "Milwaukee School of Engineering",
    },
    {
      label: "Minerva University",
      value: "Minerva University",
    },
    {
      label: "Minnesota State University, Mankato",
      value: "Minnesota State University, Mankato",
    },
    {
      label: "Misrimal Navajee Munoth Jain Engineering College",
      value: "Misrimal Navajee Munoth Jain Engineering College",
    },
    {
      label: "Mission College Boulevard",
      value: "Mission College Boulevard",
    },
    {
      label: "Mission San Jose High School",
      value: "Mission San Jose High School",
    },
    {
      label: "Mississippi State University",
      value: "Mississippi State University",
    },
    {
      label: "Mississippi University for Women",
      value: "Mississippi University for Women",
    },
    {
      label: "Missouri State University",
      value: "Missouri State University",
    },
    {
      label: "Missouri University of Science and Technology",
      value: "Missouri University of Science and Technology",
    },
    {
      label: "Model Institute of Engineering and Technology (MIET)",
      value: "Model Institute of Engineering and Technology (MIET)",
    },
    {
      label: "Modern Engineering and Management Studies",
      value: "Modern Engineering and Management Studies",
    },
    {
      label: "Mody University",
      value: "Mody University",
    },
    {
      label: "Mohammed V University",
      value: "Mohammed V University",
    },
    {
      label: "Molloy College",
      value: "Molloy College",
    },
    {
      label: "Monmouth College",
      value: "Monmouth College",
    },
    {
      label: "Monmouth University",
      value: "Monmouth University",
    },
    {
      label: "Monroe Community College",
      value: "Monroe Community College",
    },
    {
      label: "Monroe Township High School",
      value: "Monroe Township High School",
    },
    {
      label: "Monta Vista High School",
      value: "Monta Vista High School",
    },
    {
      label: "Montana State University",
      value: "Montana State University",
    },
    {
      label: "Montclair High School",
      value: "Montclair High School",
    },
    {
      label: "Montclair State University",
      value: "Montclair State University",
    },
    {
      label: "Montgomery Blair High School",
      value: "Montgomery Blair High School",
    },
    {
      label: "Montgomery College",
      value: "Montgomery College",
    },
    {
      label: "Montgomery County Community College - Central Campus (Blue Bell)",
      value: "Montgomery County Community College - Central Campus (Blue Bell)",
    },
    {
      label: "Montgomery County Community College - West Campus (Pottstown)",
      value: "Montgomery County Community College - West Campus (Pottstown)",
    },
    {
      label: "Montgomery High School",
      value: "Montgomery High School",
    },
    {
      label: "Montville Township High School",
      value: "Montville Township High School",
    },
    {
      label: "Moore College of Art and Design",
      value: "Moore College of Art and Design",
    },
    {
      label: "Moore Middle School",
      value: "Moore Middle School",
    },
    {
      label: "Moorestown High School",
      value: "Moorestown High School",
    },
    {
      label: "Moraine Valley Community College",
      value: "Moraine Valley Community College",
    },
    {
      label: "Morehouse College",
      value: "Morehouse College",
    },
    {
      label: "Morgan State University",
      value: "Morgan State University",
    },
    {
      label: "Morris County School of Technology",
      value: "Morris County School of Technology",
    },
    {
      label: "Morris Hills High School",
      value: "Morris Hills High School",
    },
    {
      label: "Morton College",
      value: "Morton College",
    },
    {
      label: "Moscow Institute of Physics and Technology",
      value: "Moscow Institute of Physics and Technology",
    },
    {
      label: "Moscrop Secondary School",
      value: "Moscrop Secondary School",
    },
    {
      label: "Motilal Nehru National Institute of Technology Allahabad",
      value: "Motilal Nehru National Institute of Technology Allahabad",
    },
    {
      label: "Motivation High School (formerly John Bartram High School)",
      value: "Motivation High School (formerly John Bartram High School)",
    },
    {
      label: "Mount Holyoke College",
      value: "Mount Holyoke College",
    },
    {
      label: "Mountain Lakes High School",
      value: "Mountain Lakes High School",
    },
    {
      label: "Mountain View High School",
      value: "Mountain View High School",
    },
    {
      label: "MSME TDC PPDC Agra",
      value: "MSME TDC PPDC Agra",
    },
    {
      label: "Mt. San Antonio College",
      value: "Mt. San Antonio College",
    },
    {
      label: "Muhlenberg college",
      value: "Muhlenberg college",
    },
    {
      label: "Multi-Cultural Academy Charter School",
      value: "Multi-Cultural Academy Charter School",
    },
    {
      label: "Murrell Dobbins Technical High School",
      value: "Murrell Dobbins Technical High School",
    },
    {
      label: "Muthoot Institute of Technology & Science",
      value: "Muthoot Institute of Technology & Science",
    },
    {
      label: "Muzaffarpur Institute of Technology",
      value: "Muzaffarpur Institute of Technology",
    },
    {
      label: "MVJ College of Engineering",
      value: "MVJ College of Engineering",
    },
    {
      label: "Nagaland University, Dimapur Campus",
      value: "Nagaland University, Dimapur Campus",
    },
    {
      label: "Nalla Malla Reddy Engineering College, Ghatkesar",
      value: "Nalla Malla Reddy Engineering College, Ghatkesar",
    },
    {
      label: "Nanyang Technological University",
      value: "Nanyang Technological University",
    },
    {
      label: "Narsee Monjee College of Commerce and Economics",
      value: "Narsee Monjee College of Commerce and Economics",
    },
    {
      label: "Narsihma Reddy Engineering College",
      value: "Narsihma Reddy Engineering College",
    },
    {
      label: "Nashua High School South",
      value: "Nashua High School South",
    },
    {
      label: "National Engineering College",
      value: "National Engineering College",
    },
    {
      label: "National Institute of Engineering, Mysore",
      value: "National Institute of Engineering, Mysore",
    },
    {
      label: "National Institute of Science and Technology, Odisha",
      value: "National Institute of Science and Technology, Odisha",
    },
    {
      label: "National Institute of Technology, Agartala",
      value: "National Institute of Technology, Agartala",
    },
    {
      label: "National Institute of Technology, Calicut",
      value: "National Institute of Technology, Calicut",
    },
    {
      label: "National Institute of Technology, Delhi",
      value: "National Institute of Technology, Delhi",
    },
    {
      label: "National Institute of Technology, Durgapur",
      value: "National Institute of Technology, Durgapur",
    },
    {
      label: "National Institute of Technology, Goa",
      value: "National Institute of Technology, Goa",
    },
    {
      label: "National Institute of Technology, Hamirpur",
      value: "National Institute of Technology, Hamirpur",
    },
    {
      label: "National Institute of Technology, Jamshedpur",
      value: "National Institute of Technology, Jamshedpur",
    },
    {
      label: "National Institute of Technology, Karnataka",
      value: "National Institute of Technology, Karnataka",
    },
    {
      label: "National Institute of Technology, Kurukshetra",
      value: "National Institute of Technology, Kurukshetra",
    },
    {
      label: "National Institute of Technology, Patna",
      value: "National Institute of Technology, Patna",
    },
    {
      label: "National Institute of Technology, Raipur",
      value: "National Institute of Technology, Raipur",
    },
    {
      label: "National Institute of Technology, Rourkela",
      value: "National Institute of Technology, Rourkela",
    },
    {
      label: "National Institute of Technology, Silchar",
      value: "National Institute of Technology, Silchar",
    },
    {
      label: "National Institute of Technology, Srinagar",
      value: "National Institute of Technology, Srinagar",
    },
    {
      label: "National Institute of Technology, Surat",
      value: "National Institute of Technology, Surat",
    },
    {
      label: "National Institute of Technology, Tiruchirappalli",
      value: "National Institute of Technology, Tiruchirappalli",
    },
    {
      label: "National Institute of Technology, Trichy",
      value: "National Institute of Technology, Trichy",
    },
    {
      label: "National Institute of Technology, Uttarakhand",
      value: "National Institute of Technology, Uttarakhand",
    },
    {
      label: "National Institute of Technology, Warangal",
      value: "National Institute of Technology, Warangal",
    },
    {
      label: "National Institute of Technology, Warangal",
      value: "National Institute of Technology, Warangal",
    },
    {
      label: "National Research University Higher School Of Economics",
      value: "National Research University Higher School Of Economics",
    },
    {
      label: "National University of Singapore",
      value: "National University of Singapore",
    },
    {
      label: "Neotia Institute Of Technology Management and Science (NITMAS)",
      value: "Neotia Institute Of Technology Management and Science (NITMAS)",
    },
    {
      label: "Netaji Subhas Institute of Technology",
      value: "Netaji Subhas Institute of Technology",
    },
    {
      label: "Netaji Subhash Engineering College",
      value: "Netaji Subhash Engineering College",
    },
    {
      label: "Neumann University",
      value: "Neumann University",
    },
    {
      label: "New Albany High School",
      value: "New Albany High School",
    },
    {
      label: "New Foundations Charter School - Philadelphia",
      value: "New Foundations Charter School - Philadelphia",
    },
    {
      label: "New Horizon College of Engineering",
      value: "New Horizon College of Engineering",
    },
    {
      label: "New Jersey City University",
      value: "New Jersey City University",
    },
    {
      label: "New Jersey Institute of Technology",
      value: "New Jersey Institute of Technology",
    },
    {
      label: "New Providence High School",
      value: "New Providence High School",
    },
    {
      label: "New River Community College",
      value: "New River Community College",
    },
    {
      label: "New York City College of Technology, CUNY",
      value: "New York City College of Technology, CUNY",
    },
    {
      label: "New York Institute of Technology",
      value: "New York Institute of Technology",
    },
    {
      label: "New York University",
      value: "New York University",
    },
    {
      label: "New York University Abu Dhabi",
      value: "New York University Abu Dhabi",
    },
    {
      label: "Newark Charter High School",
      value: "Newark Charter High School",
    },
    {
      label: "Newark Charter Junior/Senior High School",
      value: "Newark Charter Junior/Senior High School",
    },
    {
      label: "Newcastle University",
      value: "Newcastle University",
    },
    {
      label: "Newton South High School",
      value: "Newton South High School",
    },
    {
      label: "Niagara College",
      value: "Niagara College",
    },
    {
      label: "NIFT-TEA College of Knitwear Fashion",
      value: "NIFT-TEA College of Knitwear Fashion",
    },
    {
      label: "NIIT University",
      value: "NIIT University",
    },
    {
      label: "Nipissing University",
      value: "Nipissing University",
    },
    {
      label: "Nirma University",
      value: "Nirma University",
    },
    {
      label: "NITK Science & Technology Entrepreneurs' Park (NITK-STEP)",
      value: "NITK Science & Technology Entrepreneurs' Park (NITK-STEP)",
    },
    {
      label: "Nitte Meenakshi Institute of Technology",
      value: "Nitte Meenakshi Institute of Technology",
    },
    {
      label: "Nizam College of Engineering Technology",
      value: "Nizam College of Engineering Technology",
    },
    {
      label: "NMAM Institute of Technology",
      value: "NMAM Institute of Technology",
    },
    {
      label: "Noakhali Science and Technology University",
      value: "Noakhali Science and Technology University",
    },
    {
      label: "Noida Institute of Engineering and Technology",
      value: "Noida Institute of Engineering and Technology",
    },
    {
      label: "Noor-ul-Iman",
      value: "Noor-ul-Iman",
    },
    {
      label: "Norco College",
      value: "Norco College",
    },
    {
      label: "North American University",
      value: "North American University",
    },
    {
      label: "North Andover High School",
      value: "North Andover High School",
    },
    {
      label: "North Brunswick Township High School",
      value: "North Brunswick Township High School",
    },
    {
      label: "North Carolina Agricultural and Technical (A&T) State University",
      value: "North Carolina Agricultural and Technical (A&T) State University",
    },
    {
      label: "North Carolina School of Science and Mathematics",
      value: "North Carolina School of Science and Mathematics",
    },
    {
      label: "North Carolina State University",
      value: "North Carolina State University",
    },
    {
      label: "North Dakota State University",
      value: "North Dakota State University",
    },
    {
      label: "North Hunterdon High School",
      value: "North Hunterdon High School",
    },
    {
      label: "North Park Secondary School",
      value: "North Park Secondary School",
    },
    {
      label: "North Penn High School",
      value: "North Penn High School",
    },
    {
      label: "North Shore Community College",
      value: "North Shore Community College",
    },
    {
      label: "Northeast High School - Philadelphia",
      value: "Northeast High School - Philadelphia",
    },
    {
      label: "Northeastern University",
      value: "Northeastern University",
    },
    {
      label: "Northern Arizona University",
      value: "Northern Arizona University",
    },
    {
      label: "Northern Illinois University",
      value: "Northern Illinois University",
    },
    {
      label: "Northern Kentucky University",
      value: "Northern Kentucky University",
    },
    {
      label: "Northern Michigan University",
      value: "Northern Michigan University",
    },
    {
      label: "Northern Secondary School",
      value: "Northern Secondary School",
    },
    {
      label: "Northern Virginia Community College",
      value: "Northern Virginia Community College",
    },
    {
      label: "Northumbria University",
      value: "Northumbria University",
    },
    {
      label: "Northview High School",
      value: "Northview High School",
    },
    {
      label: "Northwest Missouri State University",
      value: "Northwest Missouri State University",
    },
    {
      label: "Northwest Parkway High School",
      value: "Northwest Parkway High School",
    },
    {
      label: "Northwest Vista College",
      value: "Northwest Vista College",
    },
    {
      label: "Northwestern Oklahoma State University",
      value: "Northwestern Oklahoma State University",
    },
    {
      label: "Northwestern University",
      value: "Northwestern University",
    },
    {
      label: "Northwood Academy/Arts School",
      value: "Northwood Academy/Arts School",
    },
    {
      label: "Nottingham Trent University",
      value: "Nottingham Trent University",
    },
    {
      label: "Novi High School",
      value: "Novi High School",
    },
    {
      label: "NRI Institute of information Science and Technology (NIIST)",
      value: "NRI Institute of information Science and Technology (NIIST)",
    },
    {
      label: "NSS College of Engineering",
      value: "NSS College of Engineering",
    },
    {
      label: "Oakland Community College",
      value: "Oakland Community College",
    },
    {
      label: "Oakland University",
      value: "Oakland University",
    },
    {
      label: "Obafemi Awolowo University Ile-Ife",
      value: "Obafemi Awolowo University Ile-Ife",
    },
    {
      label: "Oberlin College",
      value: "Oberlin College",
    },
    {
      label: "Ocean City High School",
      value: "Ocean City High School",
    },
    {
      label: "Ocean County College",
      value: "Ocean County College",
    },
    {
      label: "Oglethorpe University",
      value: "Oglethorpe University",
    },
    {
      label: "Ohio Christian University",
      value: "Ohio Christian University",
    },
    {
      label: "Ohio University",
      value: "Ohio University",
    },
    {
      label: "Okemos High School",
      value: "Okemos High School",
    },
    {
      label: "Oklahoma State University",
      value: "Oklahoma State University",
    },
    {
      label: "Old Dominion University",
      value: "Old Dominion University",
    },
    {
      label: "Old Westbury, SUNY",
      value: "Old Westbury, SUNY",
    },
    {
      label: "Olney High School",
      value: "Olney High School",
    },
    {
      label: "Onondaga Community College",
      value: "Onondaga Community College",
    },
    {
      label: "Opolska University of Technology",
      value: "Opolska University of Technology",
    },
    {
      label: "Oratary Prep School At Summit",
      value: "Oratary Prep School At Summit",
    },
    {
      label: "Oregon State University",
      value: "Oregon State University",
    },
    {
      label: "Oriental Group of Institutes",
      value: "Oriental Group of Institutes",
    },
    {
      label: "Orissa Engineering College",
      value: "Orissa Engineering College",
    },
    {
      label: "Orleans Technical Institute",
      value: "Orleans Technical Institute",
    },
    {
      label: "Osbourn Park High School",
      value: "Osbourn Park High School",
    },
    {
      label: "Ostbayerische Technische Hochschule Regensburg",
      value: "Ostbayerische Technische Hochschule Regensburg",
    },
    {
      label: "Otterbein University",
      value: "Otterbein University",
    },
    {
      label: "Overbrook High School - Philadelphia",
      value: "Overbrook High School - Philadelphia",
    },
    {
      label: "Oxford Academy High School",
      value: "Oxford Academy High School",
    },
    {
      label: "Oxford Brookes University",
      value: "Oxford Brookes University",
    },
    {
      label: "P.D.A. College of Engineering",
      value: "P.D.A. College of Engineering",
    },
    {
      label: "Pace University",
      value: "Pace University",
    },
    {
      label: "Pacific University,  Udaipur",
      value: "Pacific University,  Udaipur",
    },
    {
      label: "Palo Alto High School",
      value: "Palo Alto High School",
    },
    {
      label: "Palomar College",
      value: "Palomar College",
    },
    {
      label: "Pandit Deendayal Petroleum University",
      value: "Pandit Deendayal Petroleum University",
    },
    {
      label: "Panjab University,  SSG Regional Centre",
      value: "Panjab University,  SSG Regional Centre",
    },
    {
      label: "Parala Maharaja Engineering College,  Berhampur",
      value: "Parala Maharaja Engineering College,  Berhampur",
    },
    {
      label: "Paramount International School",
      value: "Paramount International School",
    },
    {
      label: "Park College of Engineering and Technology",
      value: "Park College of Engineering and Technology",
    },
    {
      label: "Parkview High School",
      value: "Parkview High School",
    },
    {
      label: "Parkway Center City High School",
      value: "Parkway Center City High School",
    },
    {
      label: "Parkway West High School",
      value: "Parkway West High School",
    },
    {
      label: "Parsippany High School",
      value: "Parsippany High School",
    },
    {
      label: "Parsons School of Design",
      value: "Parsons School of Design",
    },
    {
      label: "Parul Institute of Engineering & Technology",
      value: "Parul Institute of Engineering & Technology",
    },
    {
      label: "Pasadena City College",
      value: "Pasadena City College",
    },
    {
      label: "Pascal English School, Cyprus",
      value: "Pascal English School, Cyprus",
    },
    {
      label: "Pathways School Noida",
      value: "Pathways School Noida",
    },
    {
      label: "Patriot High School - Nokesville",
      value: "Patriot High School - Nokesville",
    },
    {
      label: "Patriot High School - Riverside",
      value: "Patriot High School - Riverside",
    },
    {
      label: "Paul Robeson High School (formerly John Bartram High School)",
      value: "Paul Robeson High School (formerly John Bartram High School)",
    },
    {
      label: "PDM College of Engineering",
      value: "PDM College of Engineering",
    },
    {
      label: "Peirce College",
      value: "Peirce College",
    },
    {
      label: "Penn State Erie, The Behrend College",
      value: "Penn State Erie, The Behrend College",
    },
    {
      label: "Penncrest High School",
      value: "Penncrest High School",
    },
    {
      label: "Pennington School",
      value: "Pennington School",
    },
    {
      label: "Pennsylvania Academy of the Fine Arts",
      value: "Pennsylvania Academy of the Fine Arts",
    },
    {
      label: "Pennsylvania Cyber Charter School",
      value: "Pennsylvania Cyber Charter School",
    },
    {
      label: "Pennsylvania Distance Learning Charter School - Online",
      value: "Pennsylvania Distance Learning Charter School - Online",
    },
    {
      label: "Pennsylvania Institute of Technology - Center City Philadelphia",
      value: "Pennsylvania Institute of Technology - Center City Philadelphia",
    },
    {
      label: "Pennsylvania Institute of Technology - Media",
      value: "Pennsylvania Institute of Technology - Media",
    },
    {
      label: "Pennsylvania Leadership Charter School - Online",
      value: "Pennsylvania Leadership Charter School - Online",
    },
    {
      label: "Pennsylvania Virtual Charter School",
      value: "Pennsylvania Virtual Charter School",
    },
    {
      label: "Periyar Maniammai Institute of Science & Technology (PMU)",
      value: "Periyar Maniammai Institute of Science & Technology (PMU)",
    },
    {
      label: "Perth Amboy High School",
      value: "Perth Amboy High School",
    },
    {
      label: "Perth Amboy Vocational Technical School",
      value: "Perth Amboy Vocational Technical School",
    },
    {
      label: "PES College of Engineering, Mandya",
      value: "PES College of Engineering, Mandya",
    },
    {
      label: "PES University",
      value: "PES University",
    },
    {
      label: "PESIT, Bangalore South Campus",
      value: "PESIT, Bangalore South Campus",
    },
    {
      label: "PGP College of Engineering Technology",
      value: "PGP College of Engineering Technology",
    },
    {
      label: "Philadelphia Academy Charter School",
      value: "Philadelphia Academy Charter School",
    },
    {
      label: "Philadelphia Electrical and Technology Charter School",
      value: "Philadelphia Electrical and Technology Charter School",
    },
    {
      label: "Philadelphia High School for Girls",
      value: "Philadelphia High School for Girls",
    },
    {
      label:
        "Philadelphia Performing Arts Charter School (String Theory High School) - Vine Street Campus",
      value:
        "Philadelphia Performing Arts Charter School (String Theory High School) - Vine Street Campus",
    },
    {
      label: "Piedmont High School",
      value: "Piedmont High School",
    },
    {
      label: "Pierre Elliott Trudeau High School",
      value: "Pierre Elliott Trudeau High School",
    },
    {
      label: "Pima Community College",
      value: "Pima Community College",
    },
    {
      label: "Pingree School",
      value: "Pingree School",
    },
    {
      label: "Piscataway Township High School",
      value: "Piscataway Township High School",
    },
    {
      label: "Pittsburgh Technical College - Philadelphia",
      value: "Pittsburgh Technical College - Philadelphia",
    },
    {
      label: "Pittsburgh Technical Institute",
      value: "Pittsburgh Technical Institute",
    },
    {
      label: "Plano East Senior High School",
      value: "Plano East Senior High School",
    },
    {
      label: "Plovdiv Medical University",
      value: "Plovdiv Medical University",
    },
    {
      label: "Point Pleasant Beach High School",
      value: "Point Pleasant Beach High School",
    },
    {
      label: "Pokhara University",
      value: "Pokhara University",
    },
    {
      label: "Politecnico di Milano",
      value: "Politecnico di Milano",
    },
    {
      label: "Polsko-Japońska Akademia Technik Komputerowych",
      value: "Polsko-Japońska Akademia Technik Komputerowych",
    },
    {
      label: "Pomona College",
      value: "Pomona College",
    },
    {
      label: "Pondicherry Engineering College",
      value: "Pondicherry Engineering College",
    },
    {
      label: "Poolesville High School",
      value: "Poolesville High School",
    },
    {
      label: "Poornima College of Engineering",
      value: "Poornima College of Engineering",
    },
    {
      label: "Poornima Group of Institutions",
      value: "Poornima Group of Institutions",
    },
    {
      label: "Poornima Institute Of Engineering And Technology",
      value: "Poornima Institute Of Engineering And Technology",
    },
    {
      label: "Pope John Paul II High School",
      value: "Pope John Paul II High School",
    },
    {
      label: "Port Credit Secondary School",
      value: "Port Credit Secondary School",
    },
    {
      label: "Porter-Gaud School",
      value: "Porter-Gaud School",
    },
    {
      label: "Portland State University",
      value: "Portland State University",
    },
    {
      label: "Potomac Senior High School",
      value: "Potomac Senior High School",
    },
    {
      label: "Potsdam, SUNY",
      value: "Potsdam, SUNY",
    },
    {
      label: "Poznań University of Technology",
      value: "Poznań University of Technology",
    },
    {
      label: "Pranveer Singh Institute of Technology",
      value: "Pranveer Singh Institute of Technology",
    },
    {
      label: "Prathyusha Engineering College",
      value: "Prathyusha Engineering College",
    },
    {
      label: "Presidency School, Surat.",
      value: "Presidency School, Surat.",
    },
    {
      label: "Preston High School",
      value: "Preston High School",
    },
    {
      label: "Preston University",
      value: "Preston University",
    },
    {
      label: "Princeton Day School",
      value: "Princeton Day School",
    },
    {
      label: "Princeton High School",
      value: "Princeton High School",
    },
    {
      label: "Princeton International School Of Mathematics And Science",
      value: "Princeton International School Of Mathematics And Science",
    },
    {
      label: "Princeton University",
      value: "Princeton University",
    },
    {
      label: "Priyadarshini College Of Engineering (PEC),  Nagpur",
      value: "Priyadarshini College Of Engineering (PEC),  Nagpur",
    },
    {
      label: "Proudhadevaraya Institute Of Technology",
      value: "Proudhadevaraya Institute Of Technology",
    },
    {
      label: "PSG College of Technology,  Coimbatore",
      value: "PSG College of Technology,  Coimbatore",
    },
    {
      label: "PSG-Science & Technology Entrepreneurial Park (PSG-STEP)",
      value: "PSG-Science & Technology Entrepreneurial Park (PSG-STEP)",
    },
    {
      label: "Pune Institute of Computer Technology",
      value: "Pune Institute of Computer Technology",
    },
    {
      label: "Punjab Engineering College (PEC)",
      value: "Punjab Engineering College (PEC)",
    },
    {
      label: "Punjab Institute of Management & Technology",
      value: "Punjab Institute of Management & Technology",
    },
    {
      label: "Punjab Institute Of Medical Sciences (PIMS)",
      value: "Punjab Institute Of Medical Sciences (PIMS)",
    },
    {
      label: "Punjab Institute of Technology, Rajpura",
      value: "Punjab Institute of Technology, Rajpura",
    },
    {
      label: "Punjab University, Patiala",
      value: "Punjab University, Patiala",
    },
    {
      label: "Purdue University",
      value: "Purdue University",
    },
    {
      label: "Queen Mary University of London",
      value: "Queen Mary University of London",
    },
    {
      label: "Queen's University",
      value: "Queen's University",
    },
    {
      label: "Queens College, CUNY",
      value: "Queens College, CUNY",
    },
    {
      label: "Queensborough Community College, CUNY",
      value: "Queensborough Community College, CUNY",
    },
    {
      label: "R N S Institute of Technology (RNSIT)",
      value: "R N S Institute of Technology (RNSIT)",
    },
    {
      label: "R. R. Institute of Technology",
      value: "R. R. Institute of Technology",
    },
    {
      label: "R.L.Jalappa Institute of technology",
      value: "R.L.Jalappa Institute of technology",
    },
    {
      label: "R.V. College Of Engineering",
      value: "R.V. College Of Engineering",
    },
    {
      label: "R.V. College of Engineering (RVCE)",
      value: "R.V. College of Engineering (RVCE)",
    },
    {
      label: "R.V.R. & J.C. College of Engineering",
      value: "R.V.R. & J.C. College of Engineering",
    },
    {
      label:
        "Radharaman Institute of Research Technology (RIRT), Radharaman Group",
      value:
        "Radharaman Institute of Research Technology (RIRT), Radharaman Group",
    },
    {
      label: "Radharaman Institute of Technology & Science (RITS), Bhopal",
      value: "Radharaman Institute of Technology & Science (RITS), Bhopal",
    },
    {
      label: "Radnor High School",
      value: "Radnor High School",
    },
    {
      label: "Raj Kumar Goel Engineering College",
      value: "Raj Kumar Goel Engineering College",
    },
    {
      label: "Rajagiri School of Engineering and Technology",
      value: "Rajagiri School of Engineering and Technology",
    },
    {
      label: "Rajarajeswari College of Engineering (RRCE)",
      value: "Rajarajeswari College of Engineering (RRCE)",
    },
    {
      label: "Rajdhani College of Engineering & Management",
      value: "Rajdhani College of Engineering & Management",
    },
    {
      label: "Rajendra Mane College of Engineering and Technology (RMCET)",
      value: "Rajendra Mane College of Engineering and Technology (RMCET)",
    },
    {
      label: "Rajiv Gandhi College of Engineering and Technology",
      value: "Rajiv Gandhi College of Engineering and Technology",
    },
    {
      label: "Rajiv Gandhi Institute of Technology (RIT), Kottayam",
      value: "Rajiv Gandhi Institute of Technology (RIT), Kottayam",
    },
    {
      label: "Rajiv Gandhi University of Knowledge Technologies (RGUKT), Basar",
      value: "Rajiv Gandhi University of Knowledge Technologies (RGUKT), Basar",
    },
    {
      label: "Rajkiya Engineering College, Ambedkar Nagar",
      value: "Rajkiya Engineering College, Ambedkar Nagar",
    },
    {
      label: "Raksha Shakti University",
      value: "Raksha Shakti University",
    },
    {
      label: "RAM-EESH INSTITUTE OF ENGINEERING TECHNOLOGY",
      value: "RAM-EESH INSTITUTE OF ENGINEERING TECHNOLOGY",
    },
    {
      label: "Ramaiah Institute of Technology",
      value: "Ramaiah Institute of Technology",
    },
    {
      label: "Ramapo College of New Jersey",
      value: "Ramapo College of New Jersey",
    },
    {
      label: "Ramapo High School",
      value: "Ramapo High School",
    },
    {
      label: "Ramrao Adik Institute of Technology (RAIT), DY Patil University",
      value: "Ramrao Adik Institute of Technology (RAIT), DY Patil University",
    },
    {
      label: "Rani Laxmi Bai Public School",
      value: "Rani Laxmi Bai Public School",
    },
    {
      label: "Raritan High School",
      value: "Raritan High School",
    },
    {
      label: "Raritan Valley Community College",
      value: "Raritan Valley Community College",
    },
    {
      label: "Ravenscroft School",
      value: "Ravenscroft School",
    },
    {
      label: "Ravenwood High School",
      value: "Ravenwood High School",
    },
    {
      label: "Reach Cyber Charter School",
      value: "Reach Cyber Charter School",
    },
    {
      label: "Red Bank Regional High School",
      value: "Red Bank Regional High School",
    },
    {
      label: "Reed College",
      value: "Reed College",
    },
    {
      label: "Regional College For Education Research and Technology, Jaipur",
      value: "Regional College For Education Research and Technology, Jaipur",
    },
    {
      label: "Regis High School",
      value: "Regis High School",
    },
    {
      label: "Rensselaer Polytechnic Institute",
      value: "Rensselaer Polytechnic Institute",
    },
    {
      label: "REVA University",
      value: "REVA University",
    },
    {
      label: "Rheinisch-Westfälische Technische Hochschule Aachen (RWTH)",
      value: "Rheinisch-Westfälische Technische Hochschule Aachen (RWTH)",
    },
    {
      label: "Rhode Island College",
      value: "Rhode Island College",
    },
    {
      label: "Rhode Island School of Design",
      value: "Rhode Island School of Design",
    },
    {
      label: "Rhodes College",
      value: "Rhodes College",
    },
    {
      label: "Rice University",
      value: "Rice University",
    },
    {
      label: "Richard Montgomery High School",
      value: "Richard Montgomery High School",
    },
    {
      label: "Richard Stockton University",
      value: "Richard Stockton University",
    },
    {
      label: "Richardson High School",
      value: "Richardson High School",
    },
    {
      label: "Richland College",
      value: "Richland College",
    },
    {
      label: "Richmond Hill High School",
      value: "Richmond Hill High School",
    },
    {
      label: "Rider University",
      value: "Rider University",
    },
    {
      label: "Ridge High School",
      value: "Ridge High School",
    },
    {
      label: "Ridgewood High School",
      value: "Ridgewood High School",
    },
    {
      label: "Riga Technical University",
      value: "Riga Technical University",
    },
    {
      label: "RIMT Institute of Engineering and Technology",
      value: "RIMT Institute of Engineering and Technology",
    },
    {
      label: "River Dell High School",
      value: "River Dell High School",
    },
    {
      label: "RMK College of Engineering",
      value: "RMK College of Engineering",
    },
    {
      label: "RNS Institute of Technology",
      value: "RNS Institute of Technology",
    },
    {
      label: "Robbinsville High School",
      value: "Robbinsville High School",
    },
    {
      label: "Robert Gordon University",
      value: "Robert Gordon University",
    },
    {
      label: "Rochester Institute of Technology",
      value: "Rochester Institute of Technology",
    },
    {
      label: "Rock Ridge High School",
      value: "Rock Ridge High School",
    },
    {
      label: "Roger Williams University",
      value: "Roger Williams University",
    },
    {
      label: "Rollins College",
      value: "Rollins College",
    },
    {
      label: "Roosevelt High School",
      value: "Roosevelt High School",
    },
    {
      label: "Rosa Parks Middle School",
      value: "Rosa Parks Middle School",
    },
    {
      label: "Rose-Hulman Institute of Technology",
      value: "Rose-Hulman Institute of Technology",
    },
    {
      label: "Rosemont College",
      value: "Rosemont College",
    },
    {
      label: "Rowan College at Burlington County - Mount Holly",
      value: "Rowan College at Burlington County - Mount Holly",
    },
    {
      label: "Rowan College at Burlington County - Pemberton",
      value: "Rowan College at Burlington County - Pemberton",
    },
    {
      label: "Rowan College at Burlington County - Willingboro",
      value: "Rowan College at Burlington County - Willingboro",
    },
    {
      label: "Rowan College at Gloucester County - Mount Laurel",
      value: "Rowan College at Gloucester County - Mount Laurel",
    },
    {
      label: "Rowan University",
      value: "Rowan University",
    },
    {
      label: "Roxborough High School",
      value: "Roxborough High School",
    },
    {
      label: "Roxbury High School",
      value: "Roxbury High School",
    },
    {
      label: "Royal Holloway, University of London",
      value: "Royal Holloway, University of London",
    },
    {
      label: "RPIIT Technical Campus",
      value: "RPIIT Technical Campus",
    },
    {
      label: "Rudbecksgymnasiet",
      value: "Rudbecksgymnasiet",
    },
    {
      label: "Rungta College of Engineering and Technology, Bhilai",
      value: "Rungta College of Engineering and Technology, Bhilai",
    },
    {
      label: "Rustamji Institute of Technology",
      value: "Rustamji Institute of Technology",
    },
    {
      label: "Rutgers Preparatory School",
      value: "Rutgers Preparatory School",
    },
    {
      label: "Rutgers University - Newark",
      value: "Rutgers University - Newark",
    },
    {
      label: "Rutgers University – Camden",
      value: "Rutgers University – Camden",
    },
    {
      label: "Rutgers, The State University of New Jersey",
      value: "Rutgers, The State University of New Jersey",
    },
    {
      label: "Ryde School",
      value: "Ryde School",
    },
    {
      label: "Rye High School",
      value: "Rye High School",
    },
    {
      label: "Ryerson University",
      value: "Ryerson University",
    },
    {
      label: "S A Engineering College",
      value: "S A Engineering College",
    },
    {
      label: "S G Balekundri Institute of Technology",
      value: "S G Balekundri Institute of Technology",
    },
    {
      label: "Sachdeva Institute of Technology",
      value: "Sachdeva Institute of Technology",
    },
    {
      label: "Sagar Institute of Science & Technology (SISTec)",
      value: "Sagar Institute of Science & Technology (SISTec)",
    },
    {
      label: "Saginaw Valley State University",
      value: "Saginaw Valley State University",
    },
    {
      label: "Sahrdaya College of Engineering and Technology",
      value: "Sahrdaya College of Engineering and Technology",
    },
    {
      label: "Sai Vidya Institute of Technology",
      value: "Sai Vidya Institute of Technology",
    },
    {
      label: "Saint Joseph High School",
      value: "Saint Joseph High School",
    },
    {
      label: "Saint Joseph's College of Maine",
      value: "Saint Joseph's College of Maine",
    },
    {
      label: "Saint Joseph's Preparatory School - Philadelphia",
      value: "Saint Joseph's Preparatory School - Philadelphia",
    },
    {
      label: "Saint Joseph's University - Philadelphia",
      value: "Saint Joseph's University - Philadelphia",
    },
    {
      label: "Saint Paul College",
      value: "Saint Paul College",
    },
    {
      label: "Saint Peter's Preparatory School",
      value: "Saint Peter's Preparatory School",
    },
    {
      label: "Saint Peter's University",
      value: "Saint Peter's University",
    },
    {
      label: "SAL Engineering and Technical Institute",
      value: "SAL Engineering and Technical Institute",
    },
    {
      label: "Salem Community College",
      value: "Salem Community College",
    },
    {
      label: "Salem State University",
      value: "Salem State University",
    },
    {
      label: "Sambhram Institute of Technology",
      value: "Sambhram Institute of Technology",
    },
    {
      label: "Samrat Ashok Technological Institute (S.A.I.T)",
      value: "Samrat Ashok Technological Institute (S.A.I.T)",
    },
    {
      label: "Samuel Fels High School - Philadelphia",
      value: "Samuel Fels High School - Philadelphia",
    },
    {
      label: "San Diego State University",
      value: "San Diego State University",
    },
    {
      label: "San Francisco State University",
      value: "San Francisco State University",
    },
    {
      label: "San Jose State University",
      value: "San Jose State University",
    },
    {
      label: "San Marcos High School",
      value: "San Marcos High School",
    },
    {
      label: "San Marin High School",
      value: "San Marin High School",
    },
    {
      label: "San Mateo High School",
      value: "San Mateo High School",
    },
    {
      label: "Sankofa Freedom Academy Charter School",
      value: "Sankofa Freedom Academy Charter School",
    },
    {
      label: "Sant Longowal Institute of Engineering and Technology",
      value: "Sant Longowal Institute of Engineering and Technology",
    },
    {
      label: "Santa Barbara City College",
      value: "Santa Barbara City College",
    },
    {
      label: "Santa Clara University",
      value: "Santa Clara University",
    },
    {
      label: "Santa Margarita Catholic High School",
      value: "Santa Margarita Catholic High School",
    },
    {
      label: "Santa Rosa Junior College",
      value: "Santa Rosa Junior College",
    },
    {
      label: "Saratoga High School",
      value: "Saratoga High School",
    },
    {
      label: "Sardar Patel Institute Of Technology",
      value: "Sardar Patel Institute Of Technology",
    },
    {
      label: "Sardar Patel University",
      value: "Sardar Patel University",
    },
    {
      label: "Sardar Vallabhbhai National Institute of Technology, Surat",
      value: "Sardar Vallabhbhai National Institute of Technology, Surat",
    },
    {
      label: "Sardar Vallabhbhai Patel Institute of Technology, Vasad",
      value: "Sardar Vallabhbhai Patel Institute of Technology, Vasad",
    },
    {
      label: "Sarvajanik College of Engineering & Technology",
      value: "Sarvajanik College of Engineering & Technology",
    },
    {
      label: "SASTRA University",
      value: "SASTRA University",
    },
    {
      label: "Saurashtra University Rajkot",
      value: "Saurashtra University Rajkot",
    },
    {
      label: "Savannah State University",
      value: "Savannah State University",
    },
    {
      label: "Savitribai Phule Pune University",
      value: "Savitribai Phule Pune University",
    },
    {
      label: "School of Engineering and Technology, Mizoram University",
      value: "School of Engineering and Technology, Mizoram University",
    },
    {
      label: "School of Engineering, Cochin University of Science and Technology",
      value: "School of Engineering, Cochin University of Science and Technology",
    },
    {
      label: "School of Professional Studies, CUNY",
      value: "School of Professional Studies, CUNY",
    },
    {
      label: "School of Visual Arts, New York",
      value: "School of Visual Arts, New York",
    },
    {
      label: "Science and Technology Entrepreneurs Park (STEP), Harcourt",
      value: "Science and Technology Entrepreneurs Park (STEP), Harcourt",
    },
    {
      label:
        "Science and TechnologyEntrepreneurs Park, Indian Institute of Technology",
      value:
        "Science and TechnologyEntrepreneurs Park, Indian Institute of Technology",
    },
    {
      label: "Science Leadership Academy",
      value: "Science Leadership Academy",
    },
    {
      label: "Scranton High School",
      value: "Scranton High School",
    },
    {
      label: "Seneca College",
      value: "Seneca College",
    },
    {
      label: "Seton Hall University",
      value: "Seton Hall University",
    },
    {
      label: "Seven Lakes High School",
      value: "Seven Lakes High School",
    },
    {
      label: "Seventh Day Adventist High School",
      value: "Seventh Day Adventist High School",
    },
    {
      label: "Shaker High School",
      value: "Shaker High School",
    },
    {
      label: "Shankersinh Vaghela Bapu Institute of Technology",
      value: "Shankersinh Vaghela Bapu Institute of Technology",
    },
    {
      label: "Sharda University",
      value: "Sharda University",
    },
    {
      label: "Sheffield Hallam University",
      value: "Sheffield Hallam University",
    },
    {
      label: "Shelton High School",
      value: "Shelton High School",
    },
    {
      label: "Sherwood Convent School",
      value: "Sherwood Convent School",
    },
    {
      label: "Sherwood High School",
      value: "Sherwood High School",
    },
    {
      label: "Shiv Nadar University",
      value: "Shiv Nadar University",
    },
    {
      label:
        "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology (SDM)",
      value:
        "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology (SDM)",
    },
    {
      label: "Shri Govindram Seksaria Institute of Technology and Science",
      value: "Shri Govindram Seksaria Institute of Technology and Science",
    },
    {
      label:
        "Shri Guru Gobind Singhji Institute of Engineering and Technology (SGGS)",
      value:
        "Shri Guru Gobind Singhji Institute of Engineering and Technology (SGGS)",
    },
    {
      label: "Shri Guru Ram Rai Public School",
      value: "Shri Guru Ram Rai Public School",
    },
    {
      label: "Shri Mata Vaishno Devi University(SMVDU)",
      value: "Shri Mata Vaishno Devi University(SMVDU)",
    },
    {
      label: "Shri Ramswaroop College Of Engineering and Management",
      value: "Shri Ramswaroop College Of Engineering and Management",
    },
    {
      label: "Shri Ramswaroop Memorial Group of Professional Colleges (SRMGPC)",
      value: "Shri Ramswaroop Memorial Group of Professional Colleges (SRMGPC)",
    },
    {
      label: "Shri Sant Gajanan Maharaj College of Engineering, Shegaon (SSGMCE)",
      value: "Shri Sant Gajanan Maharaj College of Engineering, Shegaon (SSGMCE)",
    },
    {
      label: "Shri Shankaracharya Technical Campus",
      value: "Shri Shankaracharya Technical Campus",
    },
    {
      label: "Shri Vaishnav Institute of Technology and Science",
      value: "Shri Vaishnav Institute of Technology and Science",
    },
    {
      label: "Shri Venkateshwara College of Engineering",
      value: "Shri Venkateshwara College of Engineering",
    },
    {
      label: "Shridevi Institute of Engineering & Technology",
      value: "Shridevi Institute of Engineering & Technology",
    },
    {
      label: "Shriram Institute for Industrial Research",
      value: "Shriram Institute for Industrial Research",
    },
    {
      label: "Siddaganga Institute Of Technology, Tumakuru",
      value: "Siddaganga Institute Of Technology, Tumakuru",
    },
    {
      label: "Siena College",
      value: "Siena College",
    },
    {
      label: "Sikkim Manipal Institute of Technology",
      value: "Sikkim Manipal Institute of Technology",
    },
    {
      label: "Silesian University of Technology",
      value: "Silesian University of Technology",
    },
    {
      label: "Silicon Institute of Technology",
      value: "Silicon Institute of Technology",
    },
    {
      label: "Siliguri Institute of Technology",
      value: "Siliguri Institute of Technology",
    },
    {
      label: "Silver Oak College of Engineering & Technology",
      value: "Silver Oak College of Engineering & Technology",
    },
    {
      label: "Simmons College",
      value: "Simmons College",
    },
    {
      label: "Simón Bolívar University",
      value: "Simón Bolívar University",
    },
    {
      label: "Simon Fraser University",
      value: "Simon Fraser University",
    },
    {
      label: "Simon Gratz High School",
      value: "Simon Gratz High School",
    },
    {
      label: "Simpson College",
      value: "Simpson College",
    },
    {
      label: "Simsbury High School",
      value: "Simsbury High School",
    },
    {
      label: "Sinclair Community College",
      value: "Sinclair Community College",
    },
    {
      label: "Singapore University of Technology and Design",
      value: "Singapore University of Technology and Design",
    },
    {
      label: "Sinhgad Institute of Technology",
      value: "Sinhgad Institute of Technology",
    },
    {
      label: "Sir John A. Macdonald Secondary School",
      value: "Sir John A. Macdonald Secondary School",
    },
    {
      label: "Sir M Visvesvaraya Institute of Technology (Sir MVIT)",
      value: "Sir M Visvesvaraya Institute of Technology (Sir MVIT)",
    },
    {
      label: "Sir Padampat Singhania University",
      value: "Sir Padampat Singhania University",
    },
    {
      label:
        "Sitarambhai Naranji Patel Institute of Technology & Research Centre",
      value:
        "Sitarambhai Naranji Patel Institute of Technology & Research Centre",
    },
    {
      label: "SJB Institute of Technology",
      value: "SJB Institute of Technology",
    },
    {
      label: "Skidmore College",
      value: "Skidmore College",
    },
    {
      label: "SKR Engineering College",
      value: "SKR Engineering College",
    },
    {
      label: "Slippery Rock University of Pennsylvania",
      value: "Slippery Rock University of Pennsylvania",
    },
    {
      label: "Slovak University of Technology in Bratislava (STU)",
      value: "Slovak University of Technology in Bratislava (STU)",
    },
    {
      label: "Smith College",
      value: "Smith College",
    },
    {
      label: "SOAS University of London",
      value: "SOAS University of London",
    },
    {
      label: "Society for Development of Composites",
      value: "Society for Development of Composites",
    },
    {
      label: "Solebury School",
      value: "Solebury School",
    },
    {
      label: "Sona College of Technology",
      value: "Sona College of Technology",
    },
    {
      label: "Souderton Area High School",
      value: "Souderton Area High School",
    },
    {
      label: "South Brunswick High School",
      value: "South Brunswick High School",
    },
    {
      label: "South Carolina State University",
      value: "South Carolina State University",
    },
    {
      label: "South Dakota School of Mines and Technology",
      value: "South Dakota School of Mines and Technology",
    },
    {
      label: "South Hills School of Business & Technology",
      value: "South Hills School of Business & Technology",
    },
    {
      label: "South Lakes High School",
      value: "South Lakes High School",
    },
    {
      label: "South Philadelphia High School",
      value: "South Philadelphia High School",
    },
    {
      label: "South Texas College",
      value: "South Texas College",
    },
    {
      label: "Southeastern Louisiana University",
      value: "Southeastern Louisiana University",
    },
    {
      label: "Southern Connecticut State University",
      value: "Southern Connecticut State University",
    },
    {
      label: "Southern Illinois University Carbondale",
      value: "Southern Illinois University Carbondale",
    },
    {
      label: "Southern Illinois University Edwardsville",
      value: "Southern Illinois University Edwardsville",
    },
    {
      label: "Southern Methodist University",
      value: "Southern Methodist University",
    },
    {
      label: "Southern Oregon University",
      value: "Southern Oregon University",
    },
    {
      label: "Southern University and A&M College",
      value: "Southern University and A&M College",
    },
    {
      label: "Southern Utah University",
      value: "Southern Utah University",
    },
    {
      label: "Southwestern College",
      value: "Southwestern College",
    },
    {
      label: "Spelman College",
      value: "Spelman College",
    },
    {
      label: "Spelman College",
      value: "Spelman College",
    },
    {
      label: "Spotswood High School",
      value: "Spotswood High School",
    },
    {
      label: "Spring Arbor University",
      value: "Spring Arbor University",
    },
    {
      label: "Springside Chestnut Hill Academy",
      value: "Springside Chestnut Hill Academy",
    },
    {
      label: "Sree Chitra Thirunal College of Engineering",
      value: "Sree Chitra Thirunal College of Engineering",
    },
    {
      label: "Sreenidhi Institute of Science & Technology",
      value: "Sreenidhi Institute of Science & Technology",
    },
    {
      label: "Sri Jayachamarajendra College of Engineering",
      value: "Sri Jayachamarajendra College of Engineering",
    },
    {
      label: "Sri Krishna College of Engineering and Technology (SKCET)",
      value: "Sri Krishna College of Engineering and Technology (SKCET)",
    },
    {
      label: "Sri Krishna College of Technology, Coimbatore",
      value: "Sri Krishna College of Technology, Coimbatore",
    },
    {
      label: "Sri Lanka Institute of Information Technology (SLIIT)",
      value: "Sri Lanka Institute of Information Technology (SLIIT)",
    },
    {
      label: "Sri Manakula Vinayagar Engineering",
      value: "Sri Manakula Vinayagar Engineering",
    },
    {
      label: "Sri Ramakrishna Engineering College (SREC)",
      value: "Sri Ramakrishna Engineering College (SREC)",
    },
    {
      label: "Sri Revana Siddeshwara Institute of Technology",
      value: "Sri Revana Siddeshwara Institute of Technology",
    },
    {
      label: "Sri Siddhartha Institute of Technology",
      value: "Sri Siddhartha Institute of Technology",
    },
    {
      label: "Sri Sivasubramaniya Nadar College of Engineering",
      value: "Sri Sivasubramaniya Nadar College of Engineering",
    },
    {
      label: "Sri Vishnu Educational Society",
      value: "Sri Vishnu Educational Society",
    },
    {
      label: "Srinivas Institute of Technology (SIT)",
      value: "Srinivas Institute of Technology (SIT)",
    },
    {
      label: "SRM Easwari Engineering College, Chennai",
      value: "SRM Easwari Engineering College, Chennai",
    },
    {
      label: "SRM University",
      value: "SRM University",
    },
    {
      label: "SRM University, Sonepat",
      value: "SRM University, Sonepat",
    },
    {
      label: "SS College of Engineering",
      value: "SS College of Engineering",
    },
    {
      label: "St Brendan High School",
      value: "St Brendan High School",
    },
    {
      label: "St Edwards University",
      value: "St Edwards University",
    },
    {
      label: "St Joseph Engineering College",
      value: "St Joseph Engineering College",
    },
    {
      label: "St Mary's Catholic High School – Croydon",
      value: "St Mary's Catholic High School – Croydon",
    },
    {
      label: "St Mary's CE High School – Cheshunt",
      value: "St Mary's CE High School – Cheshunt",
    },
    {
      label: "St Paul's Catholic College – Sunbury-on-Thames",
      value: "St Paul's Catholic College – Sunbury-on-Thames",
    },
    {
      label: "St. Charles Borromeo Seminary",
      value: "St. Charles Borromeo Seminary",
    },
    {
      label: "St. Cloud State University",
      value: "St. Cloud State University",
    },
    {
      label: "St. David Catholic Secondary School",
      value: "St. David Catholic Secondary School",
    },
    {
      label: "St. John's University, New York",
      value: "St. John's University, New York",
    },
    {
      label: "St. Mark's School, Hong Kong",
      value: "St. Mark's School, Hong Kong",
    },
    {
      label: "St. Mary's Convent School",
      value: "St. Mary's Convent School",
    },
    {
      label: "St. Mary's Ryken High School",
      value: "St. Mary's Ryken High School",
    },
    {
      label: "St. Michael College of Engineering & Technology",
      value: "St. Michael College of Engineering & Technology",
    },
    {
      label: "St. Peter's Institute of Higher Education and Research",
      value: "St. Peter's Institute of Higher Education and Research",
    },
    {
      label: "St. Pious X Degree & PG College for women",
      value: "St. Pious X Degree & PG College for women",
    },
    {
      label: "St. Raymond High School for Boys And Girls",
      value: "St. Raymond High School for Boys And Girls",
    },
    {
      label: "St. Theresa of Lisieux Catholic High School",
      value: "St. Theresa of Lisieux Catholic High School",
    },
    {
      label: "St. Xavier's Senior Secondary School, Jaipur",
      value: "St. Xavier's Senior Secondary School, Jaipur",
    },
    {
      label: "St.Martin's Engineering College",
      value: "St.Martin's Engineering College",
    },
    {
      label: "Stanford University",
      value: "Stanford University",
    },
    {
      label: "Stanley College of Engineering and Technology for Women",
      value: "Stanley College of Engineering and Technology for Women",
    },
    {
      label: "Star Technical Institute",
      value: "Star Technical Institute",
    },
    {
      label: "Startup Incubation and Innovation Centre, IIT Kanpur",
      value: "Startup Incubation and Innovation Centre, IIT Kanpur",
    },
    {
      label: "Staten Island Technical High School",
      value: "Staten Island Technical High School",
    },
    {
      label: "Steinert High School",
      value: "Steinert High School",
    },
    {
      label: "Stephen F. Austin State University",
      value: "Stephen F. Austin State University",
    },
    {
      label: "Stetson University",
      value: "Stetson University",
    },
    {
      label: "Stevens Institute of Technology",
      value: "Stevens Institute of Technology",
    },
    {
      label: "Stevenson University",
      value: "Stevenson University",
    },
    {
      label: "Stockholm University",
      value: "Stockholm University",
    },
    {
      label: "Stockton University",
      value: "Stockton University",
    },
    {
      label: "Stonehill College",
      value: "Stonehill College",
    },
    {
      label: "Stonewall Jackson High School - Manassas",
      value: "Stonewall Jackson High School - Manassas",
    },
    {
      label: "Stonewall Jackson High School - Quicksburg",
      value: "Stonewall Jackson High School - Quicksburg",
    },
    {
      label: "Stony Brook University, SUNY",
      value: "Stony Brook University, SUNY",
    },
    {
      label: "Strawberry Mansion High School",
      value: "Strawberry Mansion High School",
    },
    {
      label: "Strayer University - Bensalem",
      value: "Strayer University - Bensalem",
    },
    {
      label: "Strayer University - Philadelphia Center City",
      value: "Strayer University - Philadelphia Center City",
    },
    {
      label: "Stuyvesant High School",
      value: "Stuyvesant High School",
    },
    {
      label: "Sulphur High School",
      value: "Sulphur High School",
    },
    {
      label: "SUNY Polytechnic Institute",
      value: "SUNY Polytechnic Institute",
    },
    {
      label: "SUPINFO International University",
      value: "SUPINFO International University",
    },
    {
      label: "Susq-Cyber Charter School",
      value: "Susq-Cyber Charter School",
    },
    {
      label: "Susquehanna University",
      value: "Susquehanna University",
    },
    {
      label: "Sussex County Community College",
      value: "Sussex County Community College",
    },
    {
      label: "Suyash Institute of Information Technology",
      value: "Suyash Institute of Information Technology",
    },
    {
      label: "SVS College of Engineering",
      value: "SVS College of Engineering",
    },
    {
      label:
        "Swami Keshvanand Institute of Technology,  Management & Gramothan (SKIT)",
      value:
        "Swami Keshvanand Institute of Technology,  Management & Gramothan (SKIT)",
    },
    {
      label: "Swansea University",
      value: "Swansea University",
    },
    {
      label: "Swarthmore College",
      value: "Swarthmore College",
    },
    {
      label: "Syed Ammal Engineering College",
      value: "Syed Ammal Engineering College",
    },
    {
      label: "Symbiosis International University",
      value: "Symbiosis International University",
    },
    {
      label: "Synergy Institute of Engineering and Technology",
      value: "Synergy Institute of Engineering and Technology",
    },
    {
      label: "Syracuse University",
      value: "Syracuse University",
    },
    {
      label: "T K M College of Engineering",
      value: "T K M College of Engineering",
    },
    {
      label: "Tacoma Community College",
      value: "Tacoma Community College",
    },
    {
      label: "Tacony Academy Charter School",
      value: "Tacony Academy Charter School",
    },
    {
      label: "Tadeusz Kościuszko University of Technology",
      value: "Tadeusz Kościuszko University of Technology",
    },
    {
      label: "Tallinn University",
      value: "Tallinn University",
    },
    {
      label: "Tallinn University of Technology",
      value: "Tallinn University of Technology",
    },
    {
      label: "Talmudical Yeshiva of Philadelphia",
      value: "Talmudical Yeshiva of Philadelphia",
    },
    {
      label: "Tamil Nadu Agricultural University (TNAU)",
      value: "Tamil Nadu Agricultural University (TNAU)",
    },
    {
      label: "Tamilnadu College of Engineering",
      value: "Tamilnadu College of Engineering",
    },
    {
      label: "Tampere University of Applied Sciences",
      value: "Tampere University of Applied Sciences",
    },
    {
      label: "Tampere University of Technology",
      value: "Tampere University of Technology",
    },
    {
      label: "Tarleton State University",
      value: "Tarleton State University",
    },
    {
      label: "TECH Freire Charter High School",
      value: "TECH Freire Charter High School",
    },
    {
      label: "Technische Universität München",
      value: "Technische Universität München",
    },
    {
      label: "Techno India College of Technology",
      value: "Techno India College of Technology",
    },
    {
      label: "Techno India University",
      value: "Techno India University",
    },
    {
      label: "Tecnológico de Estudio Superiores de Ixtapaluca",
      value: "Tecnológico de Estudio Superiores de Ixtapaluca",
    },
    {
      label: "Tecnológico de Estudios Superiores de Ecatepec",
      value: "Tecnológico de Estudios Superiores de Ecatepec",
    },
    {
      label: "Tecnológico de Estudios Superiores de Jilotepec",
      value: "Tecnológico de Estudios Superiores de Jilotepec",
    },
    {
      label: "Teesside University",
      value: "Teesside University",
    },
    {
      label: "Temple University",
      value: "Temple University",
    },
    {
      label: "Temple University - Ambler",
      value: "Temple University - Ambler",
    },
    {
      label: "Temple University - Harrisburg",
      value: "Temple University - Harrisburg",
    },
    {
      label: "Temple University - Health Sciences Campus",
      value: "Temple University - Health Sciences Campus",
    },
    {
      label: "Temple University - Rome",
      value: "Temple University - Rome",
    },
    {
      label: "Temple University - Tokyo",
      value: "Temple University - Tokyo",
    },
    {
      label: "Tenafly High School",
      value: "Tenafly High School",
    },
    {
      label: "Tennessee State University",
      value: "Tennessee State University",
    },
    {
      label: "Texas A&M University",
      value: "Texas A&M University",
    },
    {
      label: "Texas A&M University – Central Texas",
      value: "Texas A&M University – Central Texas",
    },
    {
      label: "Texas A&M University – Corpus Christi",
      value: "Texas A&M University – Corpus Christi",
    },
    {
      label: "Texas A&M University – Kingsville",
      value: "Texas A&M University – Kingsville",
    },
    {
      label: "Texas Christian University",
      value: "Texas Christian University",
    },
    {
      label: "Texas Southern University",
      value: "Texas Southern University",
    },
    {
      label: "Texas Southmost College",
      value: "Texas Southmost College",
    },
    {
      label: "Texas State University",
      value: "Texas State University",
    },
    {
      label: "Texas Tech University",
      value: "Texas Tech University",
    },
    {
      label: "Tezpur University",
      value: "Tezpur University",
    },
    {
      label: "Thadomal Shahani Engineering College",
      value: "Thadomal Shahani Engineering College",
    },
    {
      label: "Thakur College of Engineering and Technology",
      value: "Thakur College of Engineering and Technology",
    },
    {
      label: "Thanthai Periyar Government Institute of Technology",
      value: "Thanthai Periyar Government Institute of Technology",
    },
    {
      label: "Thapar Institute of Engineering and Technology",
      value: "Thapar Institute of Engineering and Technology",
    },
    {
      label: "THDC Institute of Hydropower Engineering and Technology, Tehri",
      value: "THDC Institute of Hydropower Engineering and Technology, Tehri",
    },
    {
      label: "The Arts Academy at Benjamin Rush",
      value: "The Arts Academy at Benjamin Rush",
    },
    {
      label: "The British University In Egypt",
      value: "The British University In Egypt",
    },
    {
      label: "The Bronx High School of Science",
      value: "The Bronx High School of Science",
    },
    {
      label: "The City College of New York, CUNY",
      value: "The City College of New York, CUNY",
    },
    {
      label: "The College at Brockport, SUNY",
      value: "The College at Brockport, SUNY",
    },
    {
      label: "The College of New Jersey",
      value: "The College of New Jersey",
    },
    {
      label: "The College of Saint Rose",
      value: "The College of Saint Rose",
    },
    {
      label: "The Curtis Institute of Music",
      value: "The Curtis Institute of Music",
    },
    {
      label: "The Federal University of Technology,  Akure",
      value: "The Federal University of Technology,  Akure",
    },
    {
      label: "The George Washington University",
      value: "The George Washington University",
    },
    {
      label: "The Governor's School @ Innovation Park",
      value: "The Governor's School @ Innovation Park",
    },
    {
      label: "The Harker School",
      value: "The Harker School",
    },
    {
      label: "The Hill School",
      value: "The Hill School",
    },
    {
      label: "The Katholieke Universiteit Leuven",
      value: "The Katholieke Universiteit Leuven",
    },
    {
      label: "The Lawrenceville School",
      value: "The Lawrenceville School",
    },
    {
      label: "The LNM Institute of Information Technology",
      value: "The LNM Institute of Information Technology",
    },
    {
      label: "The Maharaja Sayajirao University of Baroda",
      value: "The Maharaja Sayajirao University of Baroda",
    },
    {
      label: "The Mount Tabor Training College",
      value: "The Mount Tabor Training College",
    },
    {
      label: "The Ohio State University",
      value: "The Ohio State University",
    },
    {
      label: "The Open University",
      value: "The Open University",
    },
    {
      label: "The Oxford College of Engineering",
      value: "The Oxford College of Engineering",
    },
    {
      label: "The Pennsylvania State University",
      value: "The Pennsylvania State University",
    },
    {
      label: "The Pennsylvania State University – Abington Campus",
      value: "The Pennsylvania State University – Abington Campus",
    },
    {
      label: "The Pennsylvania State University – Berks",
      value: "The Pennsylvania State University – Berks",
    },
    {
      label: "The Pennsylvania State University – Brandywine",
      value: "The Pennsylvania State University – Brandywine",
    },
    {
      label: "The Pennsylvania State University – Harrisburg",
      value: "The Pennsylvania State University – Harrisburg",
    },
    {
      label: "The Pennsylvania State University – York Campus",
      value: "The Pennsylvania State University – York Campus",
    },
    {
      label: "The Roxbury Latin School",
      value: "The Roxbury Latin School",
    },
    {
      label: "The Savannah College of Art and Design",
      value: "The Savannah College of Art and Design",
    },
    {
      label: "The SRM University",
      value: "The SRM University",
    },
    {
      label: "The Technical University of Denmark",
      value: "The Technical University of Denmark",
    },
    {
      label: "The Technische Universität Berlin",
      value: "The Technische Universität Berlin",
    },
    {
      label: "The Université de Sherbrooke",
      value: "The Université de Sherbrooke",
    },
    {
      label: "The University of Aberdeen",
      value: "The University of Aberdeen",
    },
    {
      label: "The University of Akron",
      value: "The University of Akron",
    },
    {
      label: "The University of Alabama",
      value: "The University of Alabama",
    },
    {
      label: "The University of Alabama at Birmingham",
      value: "The University of Alabama at Birmingham",
    },
    {
      label: "The University of Alberta",
      value: "The University of Alberta",
    },
    {
      label: "The University of Applied Sciences Upper Austria",
      value: "The University of Applied Sciences Upper Austria",
    },
    {
      label: "The University of Arizona",
      value: "The University of Arizona",
    },
    {
      label: "The University of Arkansas",
      value: "The University of Arkansas",
    },
    {
      label: "The University of Bath",
      value: "The University of Bath",
    },
    {
      label: "The University of Bedfordshire",
      value: "The University of Bedfordshire",
    },
    {
      label: "The University of Birmingham",
      value: "The University of Birmingham",
    },
    {
      label: "The University of Bolton",
      value: "The University of Bolton",
    },
    {
      label: "The University of Bonn",
      value: "The University of Bonn",
    },
    {
      label: "The University of Bristol",
      value: "The University of Bristol",
    },
    {
      label: "The University of British Columbia",
      value: "The University of British Columbia",
    },
    {
      label: "The University of Calgary",
      value: "The University of Calgary",
    },
    {
      label: "The University of Calicut",
      value: "The University of Calicut",
    },
    {
      label: "The University of California, Berkeley",
      value: "The University of California, Berkeley",
    },
    {
      label: "The University of California, Davis",
      value: "The University of California, Davis",
    },
    {
      label: "The University of California, Irvine",
      value: "The University of California, Irvine",
    },
    {
      label: "The University of California, Los Angeles",
      value: "The University of California, Los Angeles",
    },
    {
      label: "The University of California, Merced",
      value: "The University of California, Merced",
    },
    {
      label: "The University of California, Riverside",
      value: "The University of California, Riverside",
    },
    {
      label: "The University of California, San Diego",
      value: "The University of California, San Diego",
    },
    {
      label: "The University of California, Santa Barbara",
      value: "The University of California, Santa Barbara",
    },
    {
      label: "The University of California, Santa Cruz",
      value: "The University of California, Santa Cruz",
    },
    {
      label: "The University of Cambridge",
      value: "The University of Cambridge",
    },
    {
      label: "The University of Central Florida",
      value: "The University of Central Florida",
    },
    {
      label: "The University of Chicago",
      value: "The University of Chicago",
    },
    {
      label: "The University of Colorado Boulder",
      value: "The University of Colorado Boulder",
    },
    {
      label: "The University of Colorado Colorado Springs",
      value: "The University of Colorado Colorado Springs",
    },
    {
      label: "The University of Connecticut",
      value: "The University of Connecticut",
    },
    {
      label: "The University of Dallas",
      value: "The University of Dallas",
    },
    {
      label: "The University of Delaware",
      value: "The University of Delaware",
    },
    {
      label: "The University of Denver",
      value: "The University of Denver",
    },
    {
      label: "The University of Derby",
      value: "The University of Derby",
    },
    {
      label: "The University of Dundee",
      value: "The University of Dundee",
    },
    {
      label: "The University of Edinburgh",
      value: "The University of Edinburgh",
    },
    {
      label: "The University of Essex",
      value: "The University of Essex",
    },
    {
      label: "The University of Evansville",
      value: "The University of Evansville",
    },
    {
      label: "The University of Exeter",
      value: "The University of Exeter",
    },
    {
      label: "The University of Falmouth",
      value: "The University of Falmouth",
    },
    {
      label: "The University of Florida",
      value: "The University of Florida",
    },
    {
      label: "The University of Gdańsk",
      value: "The University of Gdańsk",
    },
    {
      label: "The University of Georgia",
      value: "The University of Georgia",
    },
    {
      label: "The University of Glasgow",
      value: "The University of Glasgow",
    },
    {
      label: "The University of Groningen",
      value: "The University of Groningen",
    },
    {
      label: "The University of Guelph",
      value: "The University of Guelph",
    },
    {
      label: "The University of Houston",
      value: "The University of Houston",
    },
    {
      label: "The University of Houston – Clear Lake",
      value: "The University of Houston – Clear Lake",
    },
    {
      label: "The University of Houston – Downtown",
      value: "The University of Houston – Downtown",
    },
    {
      label: "The University of Huddersfield",
      value: "The University of Huddersfield",
    },
    {
      label: "The University of Idaho",
      value: "The University of Idaho",
    },
    {
      label: "The University of Illinois at Chicago",
      value: "The University of Illinois at Chicago",
    },
    {
      label: "The University of Illinois at Urbana-Champaign",
      value: "The University of Illinois at Urbana-Champaign",
    },
    {
      label: "The University of Information Technology and Management in Rzeszow",
      value: "The University of Information Technology and Management in Rzeszow",
    },
    {
      label: "The University of Iowa",
      value: "The University of Iowa",
    },
    {
      label: "The University of Kansas",
      value: "The University of Kansas",
    },
    {
      label: "The University of Kent",
      value: "The University of Kent",
    },
    {
      label: "The University of Kentucky",
      value: "The University of Kentucky",
    },
    {
      label: "The University of La Verne",
      value: "The University of La Verne",
    },
    {
      label: "The University of Leeds",
      value: "The University of Leeds",
    },
    {
      label: "The University of Leicester",
      value: "The University of Leicester",
    },
    {
      label: "The University of Lincoln",
      value: "The University of Lincoln",
    },
    {
      label: "The University of Liverpool",
      value: "The University of Liverpool",
    },
    {
      label: "The University of Ljubljana",
      value: "The University of Ljubljana",
    },
    {
      label: "The University of Louisiana at Lafayette",
      value: "The University of Louisiana at Lafayette",
    },
    {
      label: "The University of Louisiana at Monroe",
      value: "The University of Louisiana at Monroe",
    },
    {
      label: "The University of Louisville",
      value: "The University of Louisville",
    },
    {
      label: "The University of Málaga",
      value: "The University of Málaga",
    },
    {
      label: "The University of Manchester",
      value: "The University of Manchester",
    },
    {
      label: "The University of Manitoba",
      value: "The University of Manitoba",
    },
    {
      label: "The University of Maryland, Baltimore County",
      value: "The University of Maryland, Baltimore County",
    },
    {
      label: "The University of Maryland, College Park",
      value: "The University of Maryland, College Park",
    },
    {
      label: "The University of Massachusetts Amherst",
      value: "The University of Massachusetts Amherst",
    },
    {
      label: "The University of Massachusetts Boston",
      value: "The University of Massachusetts Boston",
    },
    {
      label: "The University of Massachusetts Dartmouth",
      value: "The University of Massachusetts Dartmouth",
    },
    {
      label: "The University of Massachusetts Lowell",
      value: "The University of Massachusetts Lowell",
    },
    {
      label: "The University of Miami",
      value: "The University of Miami",
    },
    {
      label: "The University of Michigan",
      value: "The University of Michigan",
    },
    {
      label: "The University of Michigan-Dearborn",
      value: "The University of Michigan-Dearborn",
    },
    {
      label: "The University of Michigan-Flint",
      value: "The University of Michigan-Flint",
    },
    {
      label: "The University of Minnesota",
      value: "The University of Minnesota",
    },
    {
      label: "The University of Mississippi",
      value: "The University of Mississippi",
    },
    {
      label: "The University of Missouri",
      value: "The University of Missouri",
    },
    {
      label: "The University of Missouri-Kansas City",
      value: "The University of Missouri-Kansas City",
    },
    {
      label: "The University of Missouri-St. Louis",
      value: "The University of Missouri-St. Louis",
    },
    {
      label: "The University of Nebraska-Lincoln",
      value: "The University of Nebraska-Lincoln",
    },
    {
      label: "The University of New Brunswick",
      value: "The University of New Brunswick",
    },
    {
      label: "The University of New Hampshire",
      value: "The University of New Hampshire",
    },
    {
      label: "The University of New Haven",
      value: "The University of New Haven",
    },
    {
      label: "The University of North Carolina at Chapel Hill",
      value: "The University of North Carolina at Chapel Hill",
    },
    {
      label: "The University of North Carolina at Charlotte",
      value: "The University of North Carolina at Charlotte",
    },
    {
      label: "The University of North Carolina at Greensboro",
      value: "The University of North Carolina at Greensboro",
    },
    {
      label: "The University of North Texas",
      value: "The University of North Texas",
    },
    {
      label: "The University of Northampton",
      value: "The University of Northampton",
    },
    {
      label: "The University of Notre Dame",
      value: "The University of Notre Dame",
    },
    {
      label: "The University of Nottingham",
      value: "The University of Nottingham",
    },
    {
      label: "The University of Oklahoma",
      value: "The University of Oklahoma",
    },
    {
      label: "The University of Ontario Institute of Technology",
      value: "The University of Ontario Institute of Technology",
    },
    {
      label: "The University of Oregon",
      value: "The University of Oregon",
    },
    {
      label: "The University of Ottawa",
      value: "The University of Ottawa",
    },
    {
      label: "The University of Oulu",
      value: "The University of Oulu",
    },
    {
      label: "The University of Oxford",
      value: "The University of Oxford",
    },
    {
      label: "The University of Pennsylvania",
      value: "The University of Pennsylvania",
    },
    {
      label: "The University of Petroleum and Energy Studies",
      value: "The University of Petroleum and Energy Studies",
    },
    {
      label: "The University of Phoenix",
      value: "The University of Phoenix",
    },
    {
      label: "The University of Pittsburgh",
      value: "The University of Pittsburgh",
    },
    {
      label: "The University of Portland",
      value: "The University of Portland",
    },
    {
      label: "The University of Portsmouth",
      value: "The University of Portsmouth",
    },
    {
      label: "The University of Puerto Rico, Mayagüez Campus",
      value: "The University of Puerto Rico, Mayagüez Campus",
    },
    {
      label: "The University of Puerto Rico, Río Piedras Campus",
      value: "The University of Puerto Rico, Río Piedras Campus",
    },
    {
      label: "The University of Richmond",
      value: "The University of Richmond",
    },
    {
      label: "The University of Rochester",
      value: "The University of Rochester",
    },
    {
      label: "The University of Salford",
      value: "The University of Salford",
    },
    {
      label: "The University of San Francisco",
      value: "The University of San Francisco",
    },
    {
      label: "The University of Sharjah",
      value: "The University of Sharjah",
    },
    {
      label: "The University of Sheffield",
      value: "The University of Sheffield",
    },
    {
      label: "The University of Silesia in Katowice",
      value: "The University of Silesia in Katowice",
    },
    {
      label: "The University of South Carolina",
      value: "The University of South Carolina",
    },
    {
      label: "The University of South Florida",
      value: "The University of South Florida",
    },
    {
      label: "The University of Southampton",
      value: "The University of Southampton",
    },
    {
      label: "The University of Southern California",
      value: "The University of Southern California",
    },
    {
      label: "The University of Southern Denmark",
      value: "The University of Southern Denmark",
    },
    {
      label: "The University of St Andrews",
      value: "The University of St Andrews",
    },
    {
      label: "The University of St. Gallen",
      value: "The University of St. Gallen",
    },
    {
      label: "The University of St. Thomas",
      value: "The University of St. Thomas",
    },
    {
      label: "The University of Stirling",
      value: "The University of Stirling",
    },
    {
      label: "The University of Strathclyde",
      value: "The University of Strathclyde",
    },
    {
      label: "The University of Stuttgart",
      value: "The University of Stuttgart",
    },
    {
      label: "The University of Surrey",
      value: "The University of Surrey",
    },
    {
      label: "The University of Sussex",
      value: "The University of Sussex",
    },
    {
      label: "The University of Tampa",
      value: "The University of Tampa",
    },
    {
      label: "The University of Tennessee",
      value: "The University of Tennessee",
    },
    {
      label: "The University of Texas – Pan American",
      value: "The University of Texas – Pan American",
    },
    {
      label: "The University of Texas at Arlington",
      value: "The University of Texas at Arlington",
    },
    {
      label: "The University of Texas at Austin",
      value: "The University of Texas at Austin",
    },
    {
      label: "The University of Texas at Dallas",
      value: "The University of Texas at Dallas",
    },
    {
      label: "The University of Texas at El Paso",
      value: "The University of Texas at El Paso",
    },
    {
      label: "The University of Texas at San Antonio",
      value: "The University of Texas at San Antonio",
    },
    {
      label: "The University of Texas at Tyler",
      value: "The University of Texas at Tyler",
    },
    {
      label: "The University of Texas of the Permian Basin",
      value: "The University of Texas of the Permian Basin",
    },
    {
      label: "The University of Texas Rio Grande Valley",
      value: "The University of Texas Rio Grande Valley",
    },
    {
      label: "The University of the District of Columbia",
      value: "The University of the District of Columbia",
    },
    {
      label: "The University of the District of Columbia",
      value: "The University of the District of Columbia",
    },
    {
      label: "The University of the Pacific",
      value: "The University of the Pacific",
    },
    {
      label: "The University of the South - Sewanee",
      value: "The University of the South - Sewanee",
    },
    {
      label: "The University of Toledo",
      value: "The University of Toledo",
    },
    {
      label: "The University of Toronto",
      value: "The University of Toronto",
    },
    {
      label: "The University of Toronto Mississauga",
      value: "The University of Toronto Mississauga",
    },
    {
      label: "The University of Toronto Scarborough",
      value: "The University of Toronto Scarborough",
    },
    {
      label: "The University of Tulsa",
      value: "The University of Tulsa",
    },
    {
      label: "The University of Utah",
      value: "The University of Utah",
    },
    {
      label: "The University of Vermont",
      value: "The University of Vermont",
    },
    {
      label: "The University of Victoria",
      value: "The University of Victoria",
    },
    {
      label: "The University of Virginia",
      value: "The University of Virginia",
    },
    {
      label: "The University of Warsaw",
      value: "The University of Warsaw",
    },
    {
      label: "The University of Warwick",
      value: "The University of Warwick",
    },
    {
      label: "The University of Washington",
      value: "The University of Washington",
    },
    {
      label: "The University of Washington Bothell",
      value: "The University of Washington Bothell",
    },
    {
      label: "The University of Waterloo",
      value: "The University of Waterloo",
    },
    {
      label: "The University of West Georgia",
      value: "The University of West Georgia",
    },
    {
      label: "The University of Western Ontario",
      value: "The University of Western Ontario",
    },
    {
      label: "The University of Westminster",
      value: "The University of Westminster",
    },
    {
      label: "The University of Windsor",
      value: "The University of Windsor",
    },
    {
      label: "The University of Wisconsin-Eau Claire",
      value: "The University of Wisconsin-Eau Claire",
    },
    {
      label: "The University of Wisconsin-Green Bay",
      value: "The University of Wisconsin-Green Bay",
    },
    {
      label: "The University of Wisconsin-La Crosse",
      value: "The University of Wisconsin-La Crosse",
    },
    {
      label: "The University of Wisconsin-Madison",
      value: "The University of Wisconsin-Madison",
    },
    {
      label: "The University of Wisconsin-Milwaukee",
      value: "The University of Wisconsin-Milwaukee",
    },
    {
      label: "The University of Wisconsin-Oshkosh",
      value: "The University of Wisconsin-Oshkosh",
    },
    {
      label: "The University of Wisconsin-Parkside",
      value: "The University of Wisconsin-Parkside",
    },
    {
      label: "The University of Wisconsin-Platteville",
      value: "The University of Wisconsin-Platteville",
    },
    {
      label: "The University of Wisconsin-River Falls",
      value: "The University of Wisconsin-River Falls",
    },
    {
      label: "The University of Wisconsin-Stevens Point",
      value: "The University of Wisconsin-Stevens Point",
    },
    {
      label: "The University of Wisconsin-Stout",
      value: "The University of Wisconsin-Stout",
    },
    {
      label: "The University of Wisconsin-Superior",
      value: "The University of Wisconsin-Superior",
    },
    {
      label: "The University of Wisconsin-Whitewater",
      value: "The University of Wisconsin-Whitewater",
    },
    {
      label: "The University of Wolverhampton",
      value: "The University of Wolverhampton",
    },
    {
      label: "The University of Wrocław",
      value: "The University of Wrocław",
    },
    {
      label: "The University of York",
      value: "The University of York",
    },
    {
      label: "The University of Zagreb",
      value: "The University of Zagreb",
    },
    {
      label: "The Workshop School - Philadelphia",
      value: "The Workshop School - Philadelphia",
    },
    {
      label: "Thiagarajar College of Engineering (TCE), Madurai",
      value: "Thiagarajar College of Engineering (TCE), Madurai",
    },
    {
      label: "Thomas A. Edison High School - Philadelphia",
      value: "Thomas A. Edison High School - Philadelphia",
    },
    {
      label: "Thomas Edison State College",
      value: "Thomas Edison State College",
    },
    {
      label: "Thomas Jefferson High School for Science and Technology",
      value: "Thomas Jefferson High School for Science and Technology",
    },
    {
      label:
        "Thomas Jefferson University - East Falls (formerly Philadelphia University)",
      value:
        "Thomas Jefferson University - East Falls (formerly Philadelphia University)",
    },
    {
      label: "Thomas Jefferson University - Philadelphia Center City",
      value: "Thomas Jefferson University - Philadelphia Center City",
    },
    {
      label: "Thomas Nelson Community College",
      value: "Thomas Nelson Community College",
    },
    {
      label: "Thomas S. Wootton High School",
      value: "Thomas S. Wootton High School",
    },
    {
      label: "Thompson Institute - Philadelphia",
      value: "Thompson Institute - Philadelphia",
    },
    {
      label: "Tiruchirappalli Regional Engineering College Science Technology",
      value: "Tiruchirappalli Regional Engineering College Science Technology",
    },
    {
      label: "Tongji University",
      value: "Tongji University",
    },
    {
      label: "Towson High School",
      value: "Towson High School",
    },
    {
      label: "Towson University",
      value: "Towson University",
    },
    {
      label: "Trent University",
      value: "Trent University",
    },
    {
      label: "Trident Academy of Technology",
      value: "Trident Academy of Technology",
    },
    {
      label: "Trinity College",
      value: "Trinity College",
    },
    {
      label: "Trinity International University",
      value: "Trinity International University",
    },
    {
      label: "Trinity Valley School",
      value: "Trinity Valley School",
    },
    {
      label: "Troy Athens High School",
      value: "Troy Athens High School",
    },
    {
      label: "Troy High School",
      value: "Troy High School",
    },
    {
      label: "Troy University",
      value: "Troy University",
    },
    {
      label: "Truman State University",
      value: "Truman State University",
    },
    {
      label: "Tshwane University of Technology",
      value: "Tshwane University of Technology",
    },
    {
      label: "TU/e Technische Universiteit Eindhoven University of Technology",
      value: "TU/e Technische Universiteit Eindhoven University of Technology",
    },
    {
      label: "Tufts University",
      value: "Tufts University",
    },
    {
      label: "Tulane University",
      value: "Tulane University",
    },
    {
      label: "Tunis El Manar University",
      value: "Tunis El Manar University",
    },
    {
      label: "Turner Fenton Secondary School",
      value: "Turner Fenton Secondary School",
    },
    {
      label: "Ulster University",
      value: "Ulster University",
    },
    {
      label: "UNAM FES Aragón",
      value: "UNAM FES Aragón",
    },
    {
      label: "Union County College",
      value: "Union County College",
    },
    {
      label: "Union County Magnet High School",
      value: "Union County Magnet High School",
    },
    {
      label: "Union County Vocational-Technical Schools",
      value: "Union County Vocational-Technical Schools",
    },
    {
      label: "Union University",
      value: "Union University",
    },
    {
      label: "Unionville High School",
      value: "Unionville High School",
    },
    {
      label: "United College of Engineering and Research",
      value: "United College of Engineering and Research",
    },
    {
      label: "United Institute of Technology",
      value: "United Institute of Technology",
    },
    {
      label: "Universidad Autónoma de Baja California (UABC), Tijuana",
      value: "Universidad Autónoma de Baja California (UABC), Tijuana",
    },
    {
      label: "Universidad Autónoma de Coahuila",
      value: "Universidad Autónoma de Coahuila",
    },
    {
      label: "Universidad Autónoma de Madrid",
      value: "Universidad Autónoma de Madrid",
    },
    {
      label: "Universidad Autónoma de Nuevo León",
      value: "Universidad Autónoma de Nuevo León",
    },
    {
      label: "Universidad Autónoma de San Luis Potosí",
      value: "Universidad Autónoma de San Luis Potosí",
    },
    {
      label: "Universidad Autónoma de Tlaxcala",
      value: "Universidad Autónoma de Tlaxcala",
    },
    {
      label: "Universidad Autónoma del Estado de México",
      value: "Universidad Autónoma del Estado de México",
    },
    {
      label: "Universidad Autónoma del Estado de Morelos",
      value: "Universidad Autónoma del Estado de Morelos",
    },
    {
      label: "Universidad Autónoma del Perú",
      value: "Universidad Autónoma del Perú",
    },
    {
      label: "Universidad Autónoma Metropolitana",
      value: "Universidad Autónoma Metropolitana",
    },
    {
      label: "Universidad Centro de Estudios Cortazar",
      value: "Universidad Centro de Estudios Cortazar",
    },
    {
      label: "Universidad de Guadalajara",
      value: "Universidad de Guadalajara",
    },
    {
      label: "Universidad de Guanajuato",
      value: "Universidad de Guanajuato",
    },
    {
      label: "Universidad de La Laguna",
      value: "Universidad de La Laguna",
    },
    {
      label: "Universidad de La Salle Bajío",
      value: "Universidad de La Salle Bajío",
    },
    {
      label: "Universidad de Monterrey",
      value: "Universidad de Monterrey",
    },
    {
      label: "Universidad del Desarrollo",
      value: "Universidad del Desarrollo",
    },
    {
      label: "Universidad del Valle de México",
      value: "Universidad del Valle de México",
    },
    {
      label: "Universidad en Línea, Mexico",
      value: "Universidad en Línea, Mexico",
    },
    {
      label: "Universidad Iberoamericana",
      value: "Universidad Iberoamericana",
    },
    {
      label: "Universidad Interamericana de Puerto Rico",
      value: "Universidad Interamericana de Puerto Rico",
    },
    {
      label: "Universidad Nacional Autónoma de México",
      value: "Universidad Nacional Autónoma de México",
    },
    {
      label: "Universidad Panamericana",
      value: "Universidad Panamericana",
    },
    {
      label: "Universidad Politécnica de Guanajuato",
      value: "Universidad Politécnica de Guanajuato",
    },
    {
      label: "Universidad Politécnica de Querétaro",
      value: "Universidad Politécnica de Querétaro",
    },
    {
      label: "Universidad TecMilenio",
      value: "Universidad TecMilenio",
    },
    {
      label: "Universidad Tecnológica de México",
      value: "Universidad Tecnológica de México",
    },
    {
      label: "Universidad Tecnológica de Puebla",
      value: "Universidad Tecnológica de Puebla",
    },
    {
      label: "Universidad Tecnológica de Torreón",
      value: "Universidad Tecnológica de Torreón",
    },
    {
      label: "Universidad Veracruzana",
      value: "Universidad Veracruzana",
    },
    {
      label: "Universitat Autònoma de Barcelona, UAB",
      value: "Universitat Autònoma de Barcelona, UAB",
    },
    {
      label: "Universitat de Barcelona",
      value: "Universitat de Barcelona",
    },
    {
      label: "Universitat Oberta de Catalunya, UOC",
      value: "Universitat Oberta de Catalunya, UOC",
    },
    {
      label: "Universitat Politècnica de Catalunya",
      value: "Universitat Politècnica de Catalunya",
    },
    {
      label: "Universitat Politècnica de Catalunya, UPC",
      value: "Universitat Politècnica de Catalunya, UPC",
    },
    {
      label: "Universitat Pompeu Fabra",
      value: "Universitat Pompeu Fabra",
    },
    {
      label: "Universität Regensburg",
      value: "Universität Regensburg",
    },
    {
      label: "Universität Zürich",
      value: "Universität Zürich",
    },
    {
      label: "Universitatea Politehnica Timişoara",
      value: "Universitatea Politehnica Timişoara",
    },
    {
      label: "Université de Bordeaux",
      value: "Université de Bordeaux",
    },
    {
      label: "Université de Mons",
      value: "Université de Mons",
    },
    {
      label: "Université du Québec à Montréal",
      value: "Université du Québec à Montréal",
    },
    {
      label: "University at Albany, SUNY",
      value: "University at Albany, SUNY",
    },
    {
      label: "University at Binghamton, SUNY",
      value: "University at Binghamton, SUNY",
    },
    {
      label: "University at Buffalo, SUNY",
      value: "University at Buffalo, SUNY",
    },
    {
      label: "University at New Paltz, SUNY",
      value: "University at New Paltz, SUNY",
    },
    {
      label: "University at Oneonta, SUNY",
      value: "University at Oneonta, SUNY",
    },
    {
      label: "University at Orange, SUNY",
      value: "University at Orange, SUNY",
    },
    {
      label: "University at Oswego, SUNY",
      value: "University at Oswego, SUNY",
    },
    {
      label: "University at Plattsburgh, SUNY",
      value: "University at Plattsburgh, SUNY",
    },
    {
      label: "University Campus Suffolk",
      value: "University Campus Suffolk",
    },
    {
      label: "University College London",
      value: "University College London",
    },
    {
      label: "University College of Engineering and Technology,  Bikaner",
      value: "University College of Engineering and Technology,  Bikaner",
    },
    {
      label: "University Institute of Engineering and Technology CSJMU, Kanpur",
      value: "University Institute of Engineering and Technology CSJMU, Kanpur",
    },
    {
      label: "University Institute of Information Technology, Shimla",
      value: "University Institute of Information Technology, Shimla",
    },
    {
      label: "University Institute of Technology, Burdwan",
      value: "University Institute of Technology, Burdwan",
    },
    {
      label: "University Institute of Technology, RGPV",
      value: "University Institute of Technology, RGPV",
    },
    {
      label: "University of Basel",
      value: "University of Basel",
    },
    {
      label: "University of Białystok",
      value: "University of Białystok",
    },
    {
      label: "University of Cincinnati",
      value: "University of Cincinnati",
    },
    {
      label: "University of Cincinnati Clermont College",
      value: "University of Cincinnati Clermont College",
    },
    {
      label: "University of Duisburg-Essen",
      value: "University of Duisburg-Essen",
    },
    {
      label: "University of Gothenburg",
      value: "University of Gothenburg",
    },
    {
      label: "University of Helsinki",
      value: "University of Helsinki",
    },
    {
      label: "University of Hull",
      value: "University of Hull",
    },
    {
      label: "University of London",
      value: "University of London",
    },
    {
      label: "University of Mary Washington",
      value: "University of Mary Washington",
    },
    {
      label: "University of Maryland University College",
      value: "University of Maryland University College",
    },
    {
      label: "University of North America",
      value: "University of North America",
    },
    {
      label: "University of North Florida",
      value: "University of North Florida",
    },
    {
      label: "University of North Georgia",
      value: "University of North Georgia",
    },
    {
      label: "University of Petroleum and Energy Studies (UPES), Dehradun",
      value: "University of Petroleum and Energy Studies (UPES), Dehradun",
    },
    {
      label: "University of Pikeville",
      value: "University of Pikeville",
    },
    {
      label: "University of Queensland",
      value: "University of Queensland",
    },
    {
      label: "University of Roehampton",
      value: "University of Roehampton",
    },
    {
      label: "University of Saskatchewan",
      value: "University of Saskatchewan",
    },
    {
      label: "University of Science and Technology Houari Boumediene",
      value: "University of Science and Technology Houari Boumediene",
    },
    {
      label: "University of Southampton",
      value: "University of Southampton",
    },
    {
      label: "University of Southern Indiana",
      value: "University of Southern Indiana",
    },
    {
      label: "University of Sunderland",
      value: "University of Sunderland",
    },
    {
      label: "University of Tartu",
      value: "University of Tartu",
    },
    {
      label: "University of the Arts - Philadelphia",
      value: "University of the Arts - Philadelphia",
    },
    {
      label: "University of the People",
      value: "University of the People",
    },
    {
      label: "University of the Sciences in Philadelphia",
      value: "University of the Sciences in Philadelphia",
    },
    {
      label: "University of Trento",
      value: "University of Trento",
    },
    {
      label: "University of Udaipur",
      value: "University of Udaipur",
    },
    {
      label: "University of Valley Forge",
      value: "University of Valley Forge",
    },
    {
      label: "University of Washington Tacoma",
      value: "University of Washington Tacoma",
    },
    {
      label: "University of West Florida",
      value: "University of West Florida",
    },
    {
      label: "University School of Information, Communication and Technology",
      value: "University School of Information, Communication and Technology",
    },
    {
      label: "University Visvesvaraya College of Engineering (UVCE)",
      value: "University Visvesvaraya College of Engineering (UVCE)",
    },
    {
      label: "Upper Canada College",
      value: "Upper Canada College",
    },
    {
      label: "Upper Darby High School",
      value: "Upper Darby High School",
    },
    {
      label: "Upper Iowa University",
      value: "Upper Iowa University",
    },
    {
      label: "Upper Moreland High School",
      value: "Upper Moreland High School",
    },
    {
      label: "Urbana High School",
      value: "Urbana High School",
    },
    {
      label: "Ursinus College",
      value: "Ursinus College",
    },
    {
      label: "Utah State University",
      value: "Utah State University",
    },
    {
      label: "Utica College",
      value: "Utica College",
    },
    {
      label: "Utkal University",
      value: "Utkal University",
    },
    {
      label: "Uttaranchal Institute of Technology",
      value: "Uttaranchal Institute of Technology",
    },
    {
      label: "Vadodara Institute of Engineering",
      value: "Vadodara Institute of Engineering",
    },
    {
      label: "Valencia College",
      value: "Valencia College",
    },
    {
      label: "Valley Christian High School",
      value: "Valley Christian High School",
    },
    {
      label: "Valley High School",
      value: "Valley High School",
    },
    {
      label:
        "Vallurupalli Nageswara Rao Vignana Jyothi Institute of Engg. Technology (VNRVJIET)",
      value:
        "Vallurupalli Nageswara Rao Vignana Jyothi Institute of Engg. Technology (VNRVJIET)",
    },
    {
      label: "Vanderbilt University",
      value: "Vanderbilt University",
    },
    {
      label: "Vanier College",
      value: "Vanier College",
    },
    {
      label: "vardhaman college of engineering",
      value: "vardhaman college of engineering",
    },
    {
      label: "Vasavi College Of Engineering",
      value: "Vasavi College Of Engineering",
    },
    {
      label: "Vassar College",
      value: "Vassar College",
    },
    {
      label: "Veer Narmad South Gujarat University",
      value: "Veer Narmad South Gujarat University",
    },
    {
      label: "Veer Surendra Sai University of Technology",
      value: "Veer Surendra Sai University of Technology",
    },
    {
      label: "Veer Surendra Sai University of Technology, Burla",
      value: "Veer Surendra Sai University of Technology, Burla",
    },
    {
      label:
        "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
      value:
        "Vel Tech Multi Tech Dr.Rangarajan Dr.Sakunthala Engineering College",
    },
    {
      label:
        "Vel Tech Rangarajan Dr.Sagunthala R&D Institute of Science and Technology",
      value:
        "Vel Tech Rangarajan Dr.Sagunthala R&D Institute of Science and Technology",
    },
    {
      label: "Velammal College of Engineering and Technology",
      value: "Velammal College of Engineering and Technology",
    },
    {
      label: "Velammal Institute of Technology",
      value: "Velammal Institute of Technology",
    },
    {
      label: "Vellore Institute of Technology",
      value: "Vellore Institute of Technology",
    },
    {
      label: "Vellore Institute of Technology, Chennai",
      value: "Vellore Institute of Technology, Chennai",
    },
    {
      label: "Vemana Institute Of Technology",
      value: "Vemana Institute Of Technology",
    },
    {
      label: "Veterans Memorial Early College High School",
      value: "Veterans Memorial Early College High School",
    },
    {
      label: "VIA University College",
      value: "VIA University College",
    },
    {
      label: "Victoria Park Collegiate Institute",
      value: "Victoria Park Collegiate Institute",
    },
    {
      label: "Vidya College of Engineering",
      value: "Vidya College of Engineering",
    },
    {
      label: "Vidyakunj International School",
      value: "Vidyakunj International School",
    },
    {
      label: "Vidyavardhaka College of Engineering",
      value: "Vidyavardhaka College of Engineering",
    },
    {
      label: "Vignan Institute of Technology and Science",
      value: "Vignan Institute of Technology and Science",
    },
    {
      label: "Vikas College of Engineering & Technology, Vijayawada",
      value: "Vikas College of Engineering & Technology, Vijayawada",
    },
    {
      label: "Villanova University",
      value: "Villanova University",
    },
    {
      label: "Villgro Innovations Foundation IITM Research Park",
      value: "Villgro Innovations Foundation IITM Research Park",
    },
    {
      label: "Vinayaka Mission's Kirupananda Variyar Engineering College",
      value: "Vinayaka Mission's Kirupananda Variyar Engineering College",
    },
    {
      label: "Vincennes University",
      value: "Vincennes University",
    },
    {
      label: "Vincent Massey Secondary School",
      value: "Vincent Massey Secondary School",
    },
    {
      label: "Virginia Commonwealth University",
      value: "Virginia Commonwealth University",
    },
    {
      label: "Virginia Tech",
      value: "Virginia Tech",
    },
    {
      label: "Virtual High School @ PWCS",
      value: "Virtual High School @ PWCS",
    },
    {
      label: "Vishwakarma Government Engineering College",
      value: "Vishwakarma Government Engineering College",
    },
    {
      label: "Visvesvaraya National Institute of Technology",
      value: "Visvesvaraya National Institute of Technology",
    },
    {
      label: "Visvesvaraya Technological University",
      value: "Visvesvaraya Technological University",
    },
    {
      label: "Vivekanand Education Society's Institute of Technology (VESIT)",
      value: "Vivekanand Education Society's Institute of Technology (VESIT)",
    },
    {
      label: "Vivekanand Institute of Technology & Sciences",
      value: "Vivekanand Institute of Technology & Sciences",
    },
    {
      label: "Vivekananda College for BCA",
      value: "Vivekananda College for BCA",
    },
    {
      label: "Vivekananda Institute of Biotechnology",
      value: "Vivekananda Institute of Biotechnology",
    },
    {
      label: "Vivekananda Institute of Technology",
      value: "Vivekananda Institute of Technology",
    },
    {
      label: "Vizag Institute of Technology",
      value: "Vizag Institute of Technology",
    },
    {
      label: "VNS Group of Institutions",
      value: "VNS Group of Institutions",
    },
    {
      label: "Vrije Universiteit Amsterdam",
      value: "Vrije Universiteit Amsterdam",
    },
    {
      label: "Wake Forest University",
      value: "Wake Forest University",
    },
    {
      label: "Walchand College of Engineering",
      value: "Walchand College of Engineering",
    },
    {
      label: "Walnut Hill College",
      value: "Walnut Hill College",
    },
    {
      label: "Walt Whitman High School",
      value: "Walt Whitman High School",
    },
    {
      label: "Walter Biddle Saul High School",
      value: "Walter Biddle Saul High School",
    },
    {
      label: "Ward Melville High School",
      value: "Ward Melville High School",
    },
    {
      label: "Wardlaw + Hartridge School",
      value: "Wardlaw + Hartridge School",
    },
    {
      label: "Warren County Technical High School",
      value: "Warren County Technical High School",
    },
    {
      label: "Warsaw School of Economics",
      value: "Warsaw School of Economics",
    },
    {
      label: "Warsaw University of Technology",
      value: "Warsaw University of Technology",
    },
    {
      label: "Wartburg College",
      value: "Wartburg College",
    },
    {
      label: "Washington and Lee University",
      value: "Washington and Lee University",
    },
    {
      label: "Washington State University",
      value: "Washington State University",
    },
    {
      label: "Washington Township High School",
      value: "Washington Township High School",
    },
    {
      label: "Washington University in St. Louis",
      value: "Washington University in St. Louis",
    },
    {
      label: "Waterloo Collegiate Institute",
      value: "Waterloo Collegiate Institute",
    },
    {
      label: "Waunakee High School",
      value: "Waunakee High School",
    },
    {
      label: "Wayne State University",
      value: "Wayne State University",
    },
    {
      label: "Webb Bridge Middle School",
      value: "Webb Bridge Middle School",
    },
    {
      label: "Wellesley College",
      value: "Wellesley College",
    },
    {
      label: "Wellington C. Mepham Highschool",
      value: "Wellington C. Mepham Highschool",
    },
    {
      label: "Wells College",
      value: "Wells College",
    },
    {
      label: "Wentworth Institute of Technology",
      value: "Wentworth Institute of Technology",
    },
    {
      label: "Wesleyan University",
      value: "Wesleyan University",
    },
    {
      label: "West Chester University",
      value: "West Chester University",
    },
    {
      label: "West Essex Regional High School",
      value: "West Essex Regional High School",
    },
    {
      label: "West Morris Mendham High School",
      value: "West Morris Mendham High School",
    },
    {
      label: "West Philadelphia High School",
      value: "West Philadelphia High School",
    },
    {
      label: "West Potomac High School",
      value: "West Potomac High School",
    },
    {
      label: "West Scranton High School",
      value: "West Scranton High School",
    },
    {
      label: "West Windsor-Plainsboro High School North",
      value: "West Windsor-Plainsboro High School North",
    },
    {
      label: "West Windsor-Plainsboro High School South",
      value: "West Windsor-Plainsboro High School South",
    },
    {
      label: "Westdale Secondary School",
      value: "Westdale Secondary School",
    },
    {
      label: "Western Connecticut State University",
      value: "Western Connecticut State University",
    },
    {
      label: "Western Governors University",
      value: "Western Governors University",
    },
    {
      label: "Western Kentucky University",
      value: "Western Kentucky University",
    },
    {
      label: "Western Michigan University",
      value: "Western Michigan University",
    },
    {
      label: "Western New England University",
      value: "Western New England University",
    },
    {
      label: "Western Technical College",
      value: "Western Technical College",
    },
    {
      label: "Western University",
      value: "Western University",
    },
    {
      label: "Western Washington University",
      value: "Western Washington University",
    },
    {
      label: "Westfield High School",
      value: "Westfield High School",
    },
    {
      label: "Westminster College",
      value: "Westminster College",
    },
    {
      label: "Westminster School",
      value: "Westminster School",
    },
    {
      label: "Westwood High School",
      value: "Westwood High School",
    },
    {
      label: "Whitefish Bay High School",
      value: "Whitefish Bay High School",
    },
    {
      label: "Whitworth University",
      value: "Whitworth University",
    },
    {
      label: "Wichita State University",
      value: "Wichita State University",
    },
    {
      label: "Widener University",
      value: "Widener University",
    },
    {
      label: "Wilbert Tucker Woodson High School",
      value: "Wilbert Tucker Woodson High School",
    },
    {
      label: "Wilfrid Laurier University",
      value: "Wilfrid Laurier University",
    },
    {
      label: "Wilkes University",
      value: "Wilkes University",
    },
    {
      label: "William & Mary",
      value: "William & Mary",
    },
    {
      label: "William L. Sayre High School",
      value: "William L. Sayre High School",
    },
    {
      label: "William Lyon Mackenzie Collegiate Institute",
      value: "William Lyon Mackenzie Collegiate Institute",
    },
    {
      label: "William Paterson University",
      value: "William Paterson University",
    },
    {
      label: "William W. Bodine High School",
      value: "William W. Bodine High School",
    },
    {
      label: "Williams College",
      value: "Williams College",
    },
    {
      label: "Williamson Free School of Mechanical Trades",
      value: "Williamson Free School of Mechanical Trades",
    },
    {
      label: "Wilmington University",
      value: "Wilmington University",
    },
    {
      label: "Wiltshire College",
      value: "Wiltshire College",
    },
    {
      label: "Winona State University",
      value: "Winona State University",
    },
    {
      label: "Winston Churchill High School",
      value: "Winston Churchill High School",
    },
    {
      label: "Winthrop University",
      value: "Winthrop University",
    },
    {
      label: "Woodbridge High School - Bridgeville",
      value: "Woodbridge High School - Bridgeville",
    },
    {
      label: "Woodbridge High School - Irvine",
      value: "Woodbridge High School - Irvine",
    },
    {
      label: "Woodbridge High School - London",
      value: "Woodbridge High School - London",
    },
    {
      label: "Woodbridge High School - Woodbridge, NJ",
      value: "Woodbridge High School - Woodbridge, NJ",
    },
    {
      label: "Woodbridge High School - Woodbridge, ON",
      value: "Woodbridge High School - Woodbridge, ON",
    },
    {
      label: "Woodbridge High School - Woodbridge, VA",
      value: "Woodbridge High School - Woodbridge, VA",
    },
    {
      label: "Worcester Polytechnic Institute",
      value: "Worcester Polytechnic Institute",
    },
    {
      label: "Worcester State University",
      value: "Worcester State University",
    },
    {
      label: "World Communications Charter School",
      value: "World Communications Charter School",
    },
    {
      label: "Wright State University",
      value: "Wright State University",
    },
    {
      label: "Wrocław University of Economics",
      value: "Wrocław University of Economics",
    },
    {
      label: "Wrocław University of Technology",
      value: "Wrocław University of Technology",
    },
    {
      label: "Wuhan University",
      value: "Wuhan University",
    },
    {
      label: "Wyższa Szkoła Biznesu – National-Louis University",
      value: "Wyższa Szkoła Biznesu – National-Louis University",
    },
    {
      label: "Xavier Institute of Management Entrepreneurship Development (XIME)",
      value: "Xavier Institute of Management Entrepreneurship Development (XIME)",
    },
    {
      label:
        "Xavier Research Foundation Loyola Centre for Research and Development St Xavier's College",
      value:
        "Xavier Research Foundation Loyola Centre for Research and Development St Xavier's College",
    },
    {
      label: "Xavier University",
      value: "Xavier University",
    },
    {
      label: "Yale University",
      value: "Yale University",
    },
    {
      label: "Yale-NUS College",
      value: "Yale-NUS College",
    },
    {
      label: "Yeshiva University",
      value: "Yeshiva University",
    },
    {
      label: "York College of Pennsylvania",
      value: "York College of Pennsylvania",
    },
    {
      label: "York College, CUNY",
      value: "York College, CUNY",
    },
    {
      label: "York University",
      value: "York University",
    },
    {
      label: "Youngstown State University",
      value: "Youngstown State University",
    },
    {
      label: "YouthBuild Philadelphia Charter School",
      value: "YouthBuild Philadelphia Charter School",
    },
    {
      label: "Zakir Hussain College of Engineering and Technology, AMU",
      value: "Zakir Hussain College of Engineering and Technology, AMU",
    },
    {
      label: "Zespół Szkół im. Jana Pawła II w Niepołomicach",
      value: "Zespół Szkół im. Jana Pawła II w Niepołomicach",
    },
    {
      label: "Zespół Szkół Łączności, Monte Cassino 31",
      value: "Zespół Szkół Łączności, Monte Cassino 31",
    },
    {
      label: "Zespół Szkół nr 1 im. Jana Pawła II w Przysusze",
      value: "Zespół Szkół nr 1 im. Jana Pawła II w Przysusze",
    },
    {
      label: "Zespół szkół nr 1 im. Stanisława Staszica w Bochni",
      value: "Zespół szkół nr 1 im. Stanisława Staszica w Bochni",
    },
    {
      label: "Zespół Szkół Nr.2 im. Jana Pawła II w Miechowie",
      value: "Zespół Szkół Nr.2 im. Jana Pawła II w Miechowie",
    },
  ];
  
  export default Schools;
  
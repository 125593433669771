import React, { useContext, useState } from "react";

const QuestionaireContext = React.createContext();

export function useQuestionaire() {
  return useContext(QuestionaireContext);
}

export function QuestionaireProvider({ children }) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [DOB, setDOB] = useState("")
  const [sponsorEmail, setSponsorEmail] = useState(true)
  const [phone, setPhone] = useState("")
  const [school, setSchool] = useState("")
  // const [levelOfStudy, setLevelOfStudy] = useState("")
  const [gradYear, setGradYear] = useState("")
  const [countryResidence, setCountryResidence] = useState("")
  const [cityResidence, setCityResidence] = useState("")
  const [ruhackBefore, setRuhacksBefore] = useState("")
  const [hackathonsBefore, setHackathonsBefore] = useState("")
  const [dietary, setDietary] = useState("")
  const [underRepresented, setUnderRepresented] = useState("")
  const [gender, setGender] = useState("")
  const [pronouns, setPronouns] = useState("")
  const [ethnicity, setEthnicity] = useState("")
  const [sexuality, setSexuality] = useState("")
  const [highestEdu, setHighestEdu] = useState("")
  const [program, setProgram] = useState("")

  const qOne = {
    firstName: { get: firstName, set: setFirstName, required: true },
    lastName: { get: lastName, set: setLastName, required: true },
    email: { get: email, set: setEmail, required: true },
    sponsorEmail: { get: sponsorEmail, set: setSponsorEmail, required: false },
    DOB: { get: DOB, set: setDOB, required: true },
    phone: { get: phone, set: setPhone, required: true },
    school: { get: school, set: setSchool, required: true },
    gradYear: { get: gradYear, set: setGradYear, required: true },
    countryResidence: { get: countryResidence, set: setCountryResidence, required: true },
    cityResidence: { get: cityResidence, set: setCityResidence, required: true },
    ruhackBefore: { get: ruhackBefore, set: setRuhacksBefore, required: true },
    hackathonsBefore: { get: hackathonsBefore, set: setHackathonsBefore, required: true },
    dietary: { get: dietary, set: setDietary, required: true },
    underRepresented: { get: underRepresented, set: setUnderRepresented, required: true },
    gender: { get: gender, set: setGender, required: true },
    pronouns: { get: pronouns, set: setPronouns, required: true },
    ethnicity: { get: ethnicity, set: setEthnicity, required: true },
    sexuality: { get: sexuality, set: setSexuality, required: true },
    highestEdu: { get: highestEdu, set: setHighestEdu, required: true },
    program: { get: program, set: setProgram, required: true },
  }

  const [questionOne, setQuestionOne] = useState("")
  const [questionTwo, setQuestionTwo] = useState("")
  const [linkedin, setLinkedin] = useState("")
  const [github, setGithub] = useState("")
  const [personalWebsite, setPersonalWebsite] = useState("")
  const [resume, setResume] = useState(null)
  const [discordID, setDiscordID] = useState("")
  const [shirtSize, setShirtSize] = useState("")
  const [shipAdd1, setShipAdd1] = useState("")
  const [shipAdd2, setShipAdd2] = useState("")
  const [shipCity, setShipCity] = useState("")
  const [shipProvince, setShipProvince] = useState("")
  const [shipCountry, setShipCountry] = useState("")
  const [postalCode, setPostalCode] = useState("")

  const qTwo = {
    questionOne: { get: questionOne, set: setQuestionOne, required: true },
    questionTwo: { get: questionTwo, set: setQuestionTwo, required: true },
    linkedin: { get: linkedin, set: setLinkedin, required: false },
    github: { get: github, set: setGithub, required: false },
    personalWebsite: { get: personalWebsite, set: setPersonalWebsite, required: false },
    resume: { get: resume, set: setResume, required: false },
    discordID: { get: discordID, set: setDiscordID, required: true },
    shirtSize: { get: shirtSize, set: setShirtSize, required: true },
    shipAdd1: { get: shipAdd1, set: setShipAdd1, required: true },
    shipAdd2: { get: shipAdd2, set: setShipAdd2, required: false },
    shipCity: { get: shipCity, set: setShipCity, required: true },
    shipProvince: { get: shipProvince, set: setShipProvince, required: true },
    shipCountry: { get: shipCountry, set: setShipCountry, required: true },
    postalCode: { get: postalCode, set: setPostalCode, required: true }
  }

  const [agreeOne, setAgreeOne] = useState(false)
  const [agreeTwo, setAgreeTwo] = useState(false)
  const [agreeThree, setAgreeThree] = useState(false)

  const [teamOneName, setTeamOneName] = useState('')
  const [teamOneEmail, setTeamOneEmail] = useState('')
  const [teamTwoName, setTeamTwoName] = useState('')
  const [teamTwoEmail, setTeamTwoEmail] = useState('')
  const [teamThreeName, setTeamThreeName] = useState('')
  const [teamThreeEmail, setTeamThreeEmail] = useState('')

  const qThree = {
    agreeOne: { get: agreeOne, set: setAgreeOne, required: true },
    agreeTwo: { get: agreeTwo, set: setAgreeTwo, required: true },
    agreeThree: { get: agreeThree, set: setAgreeThree, required: true },
    teamOneName: { get: teamOneName, set: setTeamOneName, required: false },
    teamOneEmail: { get: teamOneEmail, set: setTeamOneEmail, required: false },
    teamTwoName: { get: teamTwoName, set: setTeamTwoName, required: false },
    teamTwoEmail: { get: teamTwoEmail, set: setTeamTwoEmail, required: false },
    teamThreeName: { get: teamThreeName, set: setTeamThreeName, required: false },
    teamThreeEmail: { get: teamThreeEmail, set: setTeamThreeEmail, required: false }
  }

  const [qOneError, setQOneError] = useState(false)
  const [qTwoError, setQTwoError] = useState(false)
  const [qThreeError, setQThreeError] = useState(false)

  const errorMsg = {
    qOne: { get: qOneError, set: setQOneError },
    qTwo: { get: qTwoError, set: setQTwoError },
    qThree: { get: qThreeError, set: setQThreeError }
  }

  const [currentAppPage, setCurrentAppPage] = useState(0)

  const appPage = { get: currentAppPage, set: setCurrentAppPage }

  const value = {
    qOne,
    qTwo,
    qThree,
    appPage,
    errorMsg
  };

  return (
    <QuestionaireContext.Provider value={value}>
      {children}
    </QuestionaireContext.Provider>
  );
}

import React, { useRef, useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import MetHacksLogo from "../../Assets/MetHacksLogoSmall.png"
import MascotComputer from "../../Assets/mascot_computer.png"
import BackArrow from "../../Assets/BackArrow.svg"

const Login = () => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async(e) => {
    e.preventDefault()

    setError('')
    setLoading(true)
    const verified = await login(emailRef.current.value, passwordRef.current.value)
    if (verified) {
      console.log("Successfully logged in wooooo")
      navigate('/')
    } else {
      setError("Failed to login")
    }
    setLoading(false)

  }

  return (
    <>
      <div className="lg:h-[100vh] md:m-0">
        <div className="flex flex-col item-center justify-center lg:h-full lg:my-0 my-10 lg:px-24">
          <div className="flex lg:flex-row flex-col w-full items-center justify-center">
            
            <div className="lg:w-[50%] w-[90%] lg:px-20">
              {/* <button 
                className="md:flex hidden flex-row w-full gap-1 text-white"
              >
                <img src={BackArrow} className="h-[24x]"/>
                Back
              </button> */}
              <div className="flex flex-col justify-center item-center h-full lg:gap-5 gap-1">
                <img src={MascotComputer} className="w-[70%] lg:block hidden"/>
                <div className="flex flex-row items-center justify-center">
                  <img src={MetHacksLogo} className="md:w-[100px] w-[75px] lg:hidden"/>
                </div>
                <div className="font-bold text-title text-center lg:text-start">
                  Welcome to the <br /> MetHacks Portal
                </div>
                <div className="text-header text-center lg:text-start md:block hidden">
                  Register to view your application status, hackathon schedule, and more!
                </div>
              </div>
            </div>

            <div className="lg:w-[50%] w-[100%] flex items-center justify-center lg:my-0 my-8">
              <div className="lg:w-[100%] w-[90%] lg:px-10">
                <form onSubmit={handleSubmit}>
                  <div className="rounded-3xl dark-Box lg:px-20 px-8 lg:py-12 py-6">
                    <div className="text-center font-bold text-bigHeader lg:mb-16 mb-12">
                      Login
                    </div>
                    {error && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">{error}</div>}
                    <div className="mb-4 flex flex-col">
                      <label className="text-norm mb-2">
                        Email
                      </label>
                      <input 
                        className="rounded-2xl w-full p-4 text-norm bg-[#222d4d] placeholder-[#475377] outline-none"
                        type="email" 
                        ref={emailRef}
                        placeholder="HelloWorld@email.com"
                        required />
                    </div>
                    <div className="mb-4 flex flex-col">
                      <label className="text-norm mb-2">
                        Password
                      </label>
                      <input 
                        className="rounded-2xl w-full p-4 text-norm bg-[#222d4d] placeholder-[#475377] outline-none"
                        type="password" 
                        ref={passwordRef}
                        placeholder="*****"
                        required />
                        <Link className="text-norm text-[#da6dc0] hover:text-[#a85194] mt-3 text-right" to="/forgot-password">Forgot Password?</Link>
                    </div>

                    <div className='flex lg:flex-row flex-col justify-between mt-[30px] items-center'>
                      <div className='lg:w-[70%] w-full flex flex-col'>
                        <span className="text-norm text-center">Need an Account?</span>
                        <Link className="text-norm underline text-[#60A0C5] hover:text-[#4c7f9c] text-center" to="/register">Register Here</Link>
                      </div>
                      <div>
                        <button
                          className="bg-[#da6dc0] hover:bg-[#a85194] text-norm text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full lg:block hidden"
                          type="submit"
                          disabled={loading}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className="bg-[#da6dc0] hover:bg-[#a85194] text-norm text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-8 lg:hidden"
                    type="submit"
                    disabled={loading}
                  >
                    Login
                  </button>
                </form>
              </div>
            </div>
            <div className="text-header text-center w-[90%] mb-10 lg:hidden">
              Register to view your application status, hackathon schedule, and more!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

export const DietaryRestrictions = [
    {
      label: "None",
      value: "None"
    },
    {
        label: "Vegetarian",
        value: "Vegetarian"
      },
      {
        label: "Vegan",
        value: "Vegan"
      },
      {
        label: "Celiac Disease",
        value: "Celiac Disease"
      },
      {
        label: "Allergies",
        value: "Allergies"
      },
      {
        label: "Kosher",
        value: "Kosher"
      },
      {
        label: "Halal",
        value: "Halal"
      },
      {
        label: "Other (Please Specify)",
        value: "Other (Please Specify)"
      }
]

export const UnderRepresented = [
    {
        label: "Yes",
        value: "Yes"
      },
      {
        label: "No",
        value: "No"
      },
      {
        label: "Unsure",
        value: "Unsure"
      }
]

export const Genders = [
    {
        label: "Man",
        value: "Man"
      },
      {
        label: "Women",
        value: "Women"
      },
      {
        label: "Non-Binary",
        value: "Non-Binary"
      },
      {
        label: "Prefer to self-describe",
        value: "Prefer to self-describe"
      },
      {
        label: "Prefer Not to Answer",
        value: "Prefer Not to Answer"
      }  
];

export const Pronouns = [
    {
        label: "She/Her",
        value: "She/Her"
      },
      {
        label: "He/Him",
        value: "He/Him"
      },
      {
        label: "They/Them",
        value: "They/Them"
      },
      {
        label: "She/They",
        value: "She/They"
      },
      {
        label: "He/They",
        value: "He/They"
      },
      {
        label: "Prefer Not to Answer",
        value: "Prefer Not to Answer"
      },
      {
        label: "Other (Please Specify)",
        value: "Other (Please Specify)"
      }
]

export const RaceAndEthnicity = [
    {
        label: "Asian Indian",
        value: "Asian Indian"
      },
      {
        label: "Black or African",
        value: "Black or African"
      },
      {
        label: "Chinese",
        value: "Chinese"
      },
      {
        label: "Filipino",
        value: "Filipino"
      },
      {
        label: "Guamanian or Chamorro",
        value: "Guamanian or Chamorro"
      },
      {
        label: "Hispanic / Latino / Spanish Origin",
        value: "Hispanic / Latino / Spanish Origin"
      },
      {
        label: "Japanese",
        value: "Japanese"
      },
      {
        label: "Korean",
        value: "Korean"
      },
      {
        label: "Middle Eastern",
        value: "Middle Eastern"
      },
      {
        label: "Native American or Alaskan Native",
        value: "Native American or Alaskan Native"
      },
      {
        label: "Native Hawaiian",
        value: "Native Hawaiian"
      },
      {
        label: "Samoan",
        value: "Samoan"
      },
      {
        label: "Vietnamese",
        value: "Vietnamese"
      },
      {
        label: "White",
        value: "White"
      },
      {
        label: "Other Asian (Thai, Cambodian, etc)",
        value: "Other Asian (Thai, Cambodian, etc)"
      },
      {
        label: "Other Pacific Islander",
        value: "Other Pacific Islander"
      },
      {
        label: "Other (Please Specify)",
        value: "Other (Please Specify)"
      },
      {
        label: "Prefer Not to Answer",
        value: "Prefer Not to Answer"
      }
]

export const Sexuality = [
    {
        label: "Heterosexual or straight",
        value: "Heterosexual or straight"
    },
    {
        label: "Gay or lesbian",
        value: "Gay or lesbian"
    },
    {
        label: "Bisexual",
        value: "Bisexual"
    },
    {
        label: "Different identity (Please Specify)",
        value: "Different identity (Please Specify)"
    },
    {
        label: "Prefer Not to Answer",
        value: "Prefer Not to Answer"
    }
]

export const EducationLevel = [
    {
        label: "Less than Secondary / High School",
        value: "Less than Secondary / High School"
    },
    {
        label: "Secondary / High School",
        value: "Secondary / High School"
    },
    {
        label: "Undergraduate University (2 year - community college or similar)",
        value: "Undergraduate University (2 year - community college or similar) "
    },
    {
        label: "Undergraduate University (3+ year)",
        value: "Undergraduate University (3+ year)"
    },
    {
        label: "Graduate University (Masters, Professional, Doctoral, etc)",
        value: "Graduate University (Masters, Professional, Doctoral, etc)"
    },
    {
        label: "Code School / Bootcamp",
        value: "Code School / Bootcamp"
    },
    {
        label: "Other Vocational / Trade Program or Apprenticeship",
        value: "Other Vocational / Trade Program or Apprenticeship"
    },
    {
        label: "Other (please specify)",
        value: "Other (please specify)"
    },
    {
        label: "I’m not currently a student",
        value: "I’m not currently a student"
    },
    {
        label: "Prefer not to answer",
        value: "Prefer not to answer"
    }
]

export const ShirtSize = [
    {
        label: "xs",
        value: "xs"
    },
    {
        label: "s",
        value: "s"
    },
    {
        label: "m",
        value: "m"
    },
    {
        label: "l",
        value: "l"
    },
    {
        label: "xl",
        value: "xl"
    }
]

export const MajorOfStudy = [
    {
        label: "Computer science, computer engineering, or software engineering",
        value: "Computer science, computer engineering, or software engineering"
    },
    {
        label: "Another engineering discipline (such as civil, electrical, mechanical, etc.)",
        value: "Another engineering discipline (such as civil, electrical, mechanical, etc.)"
    },
    {
        label: "Information systems, information technology, or system administration",
        value: "Information systems, information technology, or system administration"
    },
    {
        label: "A natural science (such as biology, chemistry, physics, etc.)",
        value: "A natural science (such as biology, chemistry, physics, etc.)"
    },
    {
        label: "Mathematics or statistics",
        value: "Mathematics or statistics"
    },
    {
        label: "Web development or web design",
        value: "Web development or web design"
    },
    {
        label: "Business discipline (such as accounting, finance, marketing, etc.)",
        value: "Business discipline (such as accounting, finance, marketing, etc.)"
    },
    {
        label: "Humanities discipline (such as literature, history, philosophy, etc.)",
        value: "Humanities discipline (such as literature, history, philosophy, etc.)"
    },
    {
        label: "Social science (such as anthropology, psychology, political science, etc.)",
        value: "Social science (such as anthropology, psychology, political science, etc.)"
    },
    {
        label: "Fine arts or performing arts (such as graphic design, music, studio art, etc.)",
        value: "Fine arts or performing arts (such as graphic design, music, studio art, etc.)"
    },
    {
        label: "Health science (such as nursing, pharmacy, radiology, etc.)",
        value: "Health science (such as nursing, pharmacy, radiology, etc.)"
    },
    {
        label: "Other (please specify)",
        value: "Other (please specify)"
    },
    {
        label: "Undecided / No Declared Major",
        value: "Undecided / No Declared Major"
    },
    {
        label: "My school does not offer majors / primary areas of study",
        value: "My school does not offer majors / primary areas of study"
    },
    {
        label: "Prefer not to answer",
        value: "Prefer not to answer"
    }
]

export const GraduationYear = [
  {
    label: "2024",
    value: "2024"
  },
  {
    label: "2025",
    value: "2025"
  },
  {
    label: "2026",
    value: "2026"
  },
  {
    label: "2027",
    value: "2027"
  },
  {
    label: "2028",
    value: "2028"
  },
  {
    label: "2029+",
    value: "2029+"
  }
]

export const HackathonNumber = [
  {
    label: "0",
    value: "0"
  },
  {
    label: "1-2",
    value: "1-2"
  },
  {
    label: "3-5",
    value: "3-5"
  },
  {
    label: "6-8",
    value: "6-8"
  },
  {
    label: "9+",
    value: "9+"
  }
]

export const RUHacksAttended = [
  {
    label: "No",
    value: "No"
  },
  {
    label: "Yes",
    value: "Yes"
  },
]

export const Countries = [
  {
    label: "Afghanistan",
    value: "Afghanistan"
  },
  {
    label: "Åland Islands",
    value: "Åland Islands"
  },
  {
    label: "Albania",
    value: "Albania"
  },
  {
    label: "Algeria",
    value: "Algeria"
  },
  {
    label: "American Samoa",
    value: "American Samoa"
  },
  {
    label: "Andorra",
    value: "Andorra"
  },
  {
    label: "Angola",
    value: "Angola"
  },
  {
    label: "Anguilla",
    value: "Anguilla"
  },
  {
    label: "Antarctica",
    value: "Antarctica"
  },
  {
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda"
  },
  {
    label: "Argentina",
    value: "Argentina"
  },
  {
    label: "Armenia",
    value: "Armenia"
  },
  {
    label: "Aruba",
    value: "Aruba"
  },
  {
    label: "Australia",
    value: "Australia"
  },
  {
    label: "Austria",
    value: "Austria"
  },
  {
    label: "Azerbaijan",
    value: "Azerbaijan"
  },
  {
    label: "Bahamas",
    value: "Bahamas"
  },
  {
    label: "Bahrain",
    value: "Bahrain"
  },
  {
    label: "Bangladesh",
    value: "Bangladesh"
  },
  {
    label: "Barbados",
    value: "Barbados"
  },
  {
    label: "Belarus",
    value: "Belarus"
  },
  {
    label: "Belgium",
    value: "Belgium"
  },
  {
    label: "Belize",
    value: "Belize"
  },
  {
    label: "Benin",
    value: "Benin"
  },
  {
    label: "Bermuda",
    value: "Bermuda"
  },
  {
    label: "Bhutan",
    value: "Bhutan"
  },
  {
    label: "Bolivia (Plurinational State of)",
    value: "Bolivia (Plurinational State of)"
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba"
  },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina"
  },
  {
    label: "Botswana",
    value: "Botswana"
  },
  {
    label: "Bouvet Island",
    value: "Bouvet Island"
  },
  {
    label: "Brazil",
    value: "Brazil"
  },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory"
  },
  {
    label: "Brunei Darussalam",
    value: "Brunei Darussalam"
  },
  {
    label: "Bulgaria",
    value: "Bulgaria"
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso"
  },
  {
    label: "Burundi",
    value: "Burundi"
  },
  {
    label: "Cabo Verde",
    value: "Cabo Verde"
  },
  {
    label: "Cambodia",
    value: "Cambodia"
  },
  {
    label: "Cameroon",
    value: "Cameroon"
  },
  {
    label: "Canada",
    value: "Canada"
  },
  {
    label: "Cayman Islands",
    value: "Cayman Islands"
  },
  {
    label: "Central African Republic",
    value: "Central African Republic"
  },
  {
    label: "Chad",
    value: "Chad"
  },
  {
    label: "Chile",
    value: "Chile"
  },
  {
    label: "China",
    value: "China"
  },
  {
    label: "Christmas Island",
    value: "Christmas Island"
  },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands"
  },
  {
    label: "Colombia",
    value: "Colombia"
  },
  {
    label: "Comoros",
    value: "Comoros"
  },
  {
    label: "Congo",
    value: "Congo"
  },
  {
    label: "Congo, Democratic Republic of the",
    value: "Congo, Democratic Republic of the"
  },
  {
    label: "Cook Islands",
    value: "Cook Islands"
  },
  {
    label: "Costa Rica",
    value: "Costa Rica"
  },
  {
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire"
  },
  {
    label: "Croatia",
    value: "Croatia"
  },
  {
    label: "Cuba",
    value: "Cuba"
  },
  {
    label: "Curaçao",
    value: "Curaçao"
  },
  {
    label: "Cyprus",
    value: "Cyprus"
  },
  {
    label: "Czechia",
    value: "Czechia"
  },
  {
    label: "Denmark",
    value: "Denmark"
  },
  {
    label: "Djibouti",
    value: "Djibouti"
  },
  {
    label: "Dominica",
    value: "Dominica"
  },
  {
    label: "Dominican Republic",
    value: "Dominican Republic"
  },
  {
    label: "Ecuador",
    value: "Ecuador"
  },
  {
    label: "Egypt",
    value: "Egypt"
  },
  {
    label: "El Salvador",
    value: "El Salvador"
  },
  {
    label: "Equatorial Guinea",
    value: "Equatorial Guinea"
  },
  {
    label: "Eritrea",
    value: "Eritrea"
  },
  {
    label: "Estonia",
    value: "Estonia"
  },
  {
    label: "Eswatini",
    value: "Eswatini"
  },
  {
    label: "Ethiopia",
    value: "Ethiopia"
  },
  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)"
  },
  {
    label: "Faroe Islands",
    value: "Faroe Islands"
  },
  {
    label: "Fiji",
    value: "Fiji"
  },
  {
    label: "Finland",
    value: "Finland"
  },
  {
    label: "France",
    value: "France"
  },
  {
    label: "French Guiana",
    value: "French Guiana"
  },
  {
    label: "French Polynesia",
    value: "French Polynesia"
  },
  {
    label: "French Southern Territories",
    value: "French Southern Territories"
  },
  {
    label: "Gabon",
    value: "Gabon"
  },
  {
    label: "Gambia",
    value: "Gambia"
  },
  {
    label: "Georgia",
    value: "Georgia"
  },
  {
    label: "Germany",
    value: "Germany"
  },
  {
    label: "Ghana",
    value: "Ghana"
  },
  {
    label: "Gibraltar",
    value: "Gibraltar"
  },
  {
    label: "Greece",
    value: "Greece"
  },
  {
    label: "Greenland",
    value: "Greenland"
  },
  {
    label: "Grenada",
    value: "Grenada"
  },
  {
    label: "Guadeloupe",
    value: "Guadeloupe"
  },
  {
    label: "Guam",
    value: "Guam"
  },
  {
    label: "Guatemala",
    value: "Guatemala"
  },
  {
    label: "Guernsey",
    value: "Guernsey"
  },
  {
    label: "Guinea",
    value: "Guinea"
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau"
  },
  {
    label: "Guyana",
    value: "Guyana"
  },
  {
    label: "Haiti",
    value: "Haiti"
  },
  {
    label: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands"
  },
  {
    label: "Holy See",
    value: "Holy See"
  },
  {
    label: "Honduras",
    value: "Honduras"
  },
  {
    label: "Hong Kong",
    value: "Hong Kong"
  },
  {
    label: "Hungary",
    value: "Hungary"
  },
  {
    label: "Iceland",
    value: "Iceland"
  },
  {
    label: "India",
    value: "India"
  },
  {
    label: "Indonesia",
    value: "Indonesia"
  },
  {
    label: "Iran (Islamic Republic of)",
    value: "Iran (Islamic Republic of)"
  },
  {
    label: "Iraq",
    value: "Iraq"
  },
  {
    label: "Ireland",
    value: "Ireland"
  },
  {
    label: "Isle of Man",
    value: "Isle of Man"
  },
  {
    label: "Israel",
    value: "Israel"
  },
  {
    label: "Italy",
    value: "Italy"
  },
  {
    label: "Jamaica",
    value: "Jamaica"
  },
  {
    label: "Japan",
    value: "Japan"
  },
  {
    label: "Jersey",
    value: "Jersey"
  },
  {
    label: "Jordan",
    value: "Jordan"
  },
  {
    label: "Kazakhstan",
    value: "Kazakhstan"
  },
  {
    label: "Kenya",
    value: "Kenya"
  },
  {
    label: "Kiribati",
    value: "Kiribati"
  },
  {
    label: "Korea (Democratic People's Republic of)",
    value: "Korea (Democratic People's Republic of)"
  },
  {
    label: "Korea, Republic of",
    value: "Korea, Republic of"
  },
  {
    label: "Kuwait",
    value: "Kuwait"
  },
  {
    label: "Kyrgyzstan",
    value: "Kyrgyzstan"
  },
  {
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic"
  },
  {
    label: "Latvia",
    value: "Latvia"
  },
  {
    label: "Lebanon",
    value: "Lebanon"
  },
  {
    label: "Lesotho",
    value: "Lesotho"
  },
  {
    label: "Liberia",
    value: "Liberia"
  },
  {
    label: "Libya",
    value: "Libya"
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein"
  },
  {
    label: "Lithuania",
    value: "Lithuania"
  },
  {
    label: "Luxembourg",
    value: "Luxembourg"
  },
  {
    label: "Macao",
    value: "Macao"
  },
  {
    label: "Madagascar",
    value: "Madagascar"
  },
  {
    label: "Malawi",
    value: "Malawi"
  },
  {
    label: "Malaysia",
    value: "Malaysia"
  },
  {
    label: "Maldives",
    value: "Maldives"
  },
  {
    label: "Mali",
    value: "Mali"
  },
  {
    label: "Malta",
    value: "Malta"
  },
  {
    label: "Marshall Islands",
    value: "Marshall Islands"
  },
  {
    label: "Martinique",
    value: "Martinique"
  },
  {
    label: "Mauritania",
    value: "Mauritania"
  },
  {
    label: "Mauritius",
    value: "Mauritius"
  },
  {
    label: "Mayotte",
    value: "Mayotte"
  },
  {
    label: "Mexico",
    value: "Mexico"
  },
  {
    label: "Micronesia (Federated States of)",
    value: "Micronesia (Federated States of)"
  },
  {
    label: "Moldova, Republic of",
    value: "Moldova, Republic of"
  },
  {
    label: "Monaco",
    value: "Monaco"
  },
  {
    label: "Mongolia",
    value: "Mongolia"
  },
  {
    label: "Montenegro",
    value: "Montenegro"
  },
  {
    label: "Montserrat",
    value: "Montserrat"
  },
  {
    label: "Morocco",
    value: "Morocco"
  },
  {
    label: "Mozambique",
    value: "Mozambique"
  },
  {
    label: "Myanmar",
    value: "Myanmar"
  },
  {
    label: "Namibia",
    value: "Namibia"
  },
  {
    label: "Nauru",
    value: "Nauru"
  },
  {
    label: "Nepal",
    value: "Nepal"
  },
  {
    label: "Netherlands",
    value: "Netherlands"
  },
  {
    label: "New Caledonia",
    value: "New Caledonia"
  },
  {
    label: "New Zealand",
    value: "New Zealand"
  },
  {
    label: "Nicaragua",
    value: "Nicaragua"
  },
  {
    label: "Niger",
    value: "Niger"
  },
  {
    label: "Nigeria",
    value: "Nigeria"
  },
  {
    label: "Niue",
    value: "Niue"
  },
  {
    label: "Norfolk Island",
    value: "Norfolk Island"
  },
  {
    label: "North Macedonia",
    value: "North Macedonia"
  },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands"
  },
  {
    label: "Norway",
    value: "Norway"
  },
  {
    label: "Oman",
    value: "Oman"
  },
  {
    label: "Pakistan",
    value: "Pakistan"
  },
  {
    label: "Palau",
    value: "Palau"
  },
  {
    label: "Palestine, State of",
    value: "Palestine, State of"
  },
  {
    label: "Panama",
    value: "Panama"
  },
  {
    label: "Papua New Guinea",
    value: "Papua New Guinea"
  },
  {
    label: "Paraguay",
    value: "Paraguay"
  },
  {
    label: "Peru",
    value: "Peru"
  },
  {
    label: "Philippines",
    value: "Philippines"
  },
  {
    label: "Pitcairn",
    value: "Pitcairn"
  },
  {
    label: "Poland",
    value: "Poland"
  },
  {
    label: "Portugal",
    value: "Portugal"
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico"
  },
  {
    label: "Qatar",
    value: "Qatar"
  },
  {
    label: "Réunion",
    value: "Réunion"
  },
  {
    label: "Romania",
    value: "Romania"
  },
  {
    label: "Russian Federation",
    value: "Russian Federation"
  },
  {
    label: "Rwanda",
    value: "Rwanda"
  },
  {
    label: "Saint Barthélemy",
    value: "Saint Barthélemy"
  },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis"
  },
  {
    label: "Saint Lucia",
    value: "Saint Lucia"
  },
  {
    label: "Saint Martin (French part)",
    value: "Saint Martin (French part)"
  },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon"
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines"
  },
  {
    label: "Samoa",
    value: "Samoa"
  },
  {
    label: "San Marino",
    value: "San Marino"
  },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe"
  },
  {
    label: "Saudi Arabia",
    value: "Saudi Arabia"
  },
  {
    label: "Senegal",
    value: "Senegal"
  },
  {
    label: "Serbia",
    value: "Serbia"
  },
  {
    label: "Seychelles",
    value: "Seychelles"
  },
  {
    label: "Sierra Leone",
    value: "Sierra Leone"
  },
  {
    label: "Singapore",
    value: "Singapore"
  },
  {
    label: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)"
  },
  {
    label: "Slovakia",
    value: "Slovakia"
  },
  {
    label: "Slovenia",
    value: "Slovenia"
  },
  {
    label: "Solomon Islands",
    value: "Solomon Islands"
  },
  {
    label: "Somalia",
    value: "Somalia"
  },
  {
    label: "South Africa",
    value: "South Africa"
  },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands"
  },
  {
    label: "South Sudan",
    value: "South Sudan"
  },
  {
    label: "Spain",
    value: "Spain"
  },
  {
    label: "Sri Lanka",
    value: "Sri Lanka"
  },
  {
    label: "Sudan",
    value: "Sudan"
  },
  {
    label: "Suriname",
    value: "Suriname"
  },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen"
  },
  {
    label: "Sweden",
    value: "Sweden"
  },
  {
    label: "Switzerland",
    value: "Switzerland"
  },
  {
    label: "Syrian Arab Republic",
    value: "Syrian Arab Republic"
  },
  {
    label: "Taiwan, Province of China",
    value: "Taiwan, Province of China"
  },
  {
    label: "Tajikistan",
    value: "Tajikistan"
  },
  {
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of"
  },
  {
    label: "Thailand",
    value: "Thailand"
  },
  {
    label: "Timor-Leste",
    value: "Timor-Leste"
  },
  {
    label: "Togo",
    value: "Togo"
  },
  {
    label: "Tokelau",
    value: "Tokelau"
  },
  {
    label: "Tonga",
    value: "Tonga"
  },
  {
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago"
  },
  {
    label: "Tunisia",
    value: "Tunisia"
  },
  {
    label: "Turkey",
    value: "Turkey"
  },
  {
    label: "Turkmenistan",
    value: "Turkmenistan"
  },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands"
  },
  {
    label: "Tuvalu",
    value: "Tuvalu"
  },
  {
    label: "Uganda",
    value: "Uganda"
  },
  {
    label: "Ukraine",
    value: "Ukraine"
  },
  {
    label: "United Arab Emirates",
    value: "United Arab Emirates"
  },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    label: "United States of America",
    value: "United States of America"
  },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands"
  },
  {
    label: "Uruguay",
    value: "Uruguay"
  },
  {
    label: "Uzbekistan",
    value: "Uzbekistan"
  },
  {
    label: "Vanuatu",
    value: "Vanuatu"
  },
  {
    label: "Venezuela (Bolivarian Republic of)",
    value: "Venezuela (Bolivarian Republic of)"
  },
  {
    label: "Viet Nam",
    value: "Viet Nam"
  },
  {
    label: "Virgin Islands (British)",
    value: "Virgin Islands (British)"
  },
  {
    label: "Virgin Islands (U.S.)",
    value: "Virgin Islands (U.S.)"
  },
  {
    label: "Wallis and Futuna",
    value: "Wallis and Futuna"
  },
  {
    label: "Western Sahara",
    value: "Western Sahara"
  },
  {
    label: "Yemen",
    value: "Yemen"
  },
  {
    label: "Zambia",
    value: "Zambia"
  },
  {
    label: "Zimbabwe",
    value: "Zimbabwe"
  }
]

import React, { useEffect, useState } from 'react'
import RequiredStar from "../../../Assets/RequiredStar.png"

const TextAreaField = ({title, ph_name, getValue, setFunction}) => {
  const [charLength, setCharLength] = useState(0)
  const maxChar = 600 

  useEffect(() => {
    setCharLength(getValue.length)
  }, [])

  const handleTextChange = (e) => {
    const new_text = e.target.value
    if (new_text.length <= maxChar) {
      setCharLength(new_text.length)
      setFunction(new_text)
    }
  }

  return (
    <>
      <div className='flex flex-row'>
        <label className="text-white mb-2">
          {title}
        </label>
        <img src={RequiredStar} className='requiredStar-style'/>
      </div>
      <textarea 
        className="textInput-style"
        placeholder={ph_name}
        value={getValue}
        onChange={(e) => handleTextChange(e)}
        rows="5"
        required 
      />
      <div>
        Current Character Count (Maximum 600): {charLength} 
      </div>
    </>
  )
}

export default TextAreaField
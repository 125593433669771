import React from 'react'
import PreviousPageIcon from "../../../Assets/PreviousPageIcon.png"
import { useQuestionaire } from "../../../Contexts/QuestionaireContext"

const NextPage = ({location_id}) => {
  const { appPage } = useQuestionaire()

  return (
    <>
      <button
        onClick={() => {appPage.set(location_id)}}
        className={`text-white bg-[#9b4587] hover:bg-[#833a72] font-bold py-2 ss:px-4 px-2 ss:rounded rounded-full w-full`}
      >
        <div className='flex flex-row items-center justify-center gap-3'>
          <img src={PreviousPageIcon} className='w-[25px]'/>
          <div className='ss:block hidden'>
            Prev Page
          </div>
        </div>
      </button>
    </>
  )
}

export default NextPage
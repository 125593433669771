import React from "react";
import Register from "./Pages/Register";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import Sidebar from "./Components/Sidebar";
import Footer from "./Components/Footer";
import Guide from './Pages/Guide'
import Schedule from "./Pages/Schedule";
import Application from "./Pages/Application";
import RegisterConfirm from "./Pages/RegisterConfirm"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./Contexts/AuthContext";

const App = () => {
  const PrivateRoute = ({ path, element }) => {
    const { isEmailVerified } = useAuth();

    return isEmailVerified ? (
      <>
        <Sidebar />
        <div className="">
          <div className="mt-[50px] ss:ml-[100px] lg:ml-[300px]">
            {element}
            <div className="mt-4">
              <Footer />
            </div>
          </div>
        </div>
      </>
    ) : (
      <Navigate to="/login" replace state={{ from: path }} />
    );
  };

  return (
    <div className="main">
      <div className="background-container">
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <PrivateRoute exact path="/" element={<Dashboard />} />
              }
            />
            <Route
              exact
              path="/hacker-guide"
              element={
                <PrivateRoute exact path="/" element={<Guide />} />
              }
            />
            <Route
              exact
              path="/application"
              element={
                <PrivateRoute exact path="/" element={<Application />} />
              }
            />
            <Route
              exact
              path="/schedule"
              element={<PrivateRoute exact path="/" element={<Schedule />} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/register-confirm" element={<RegisterConfirm/>} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;

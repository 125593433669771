import React from 'react'
import { useQuestionaire } from '../../../Contexts/QuestionaireContext'
import { useAuth } from '../../../Contexts/AuthContext'
import InputField from '../Components/InputField'
import SubmitIcon from "../../../Assets/SubmitIcon.png"
import RequiredStar from "../../../Assets/RequiredStar.png"
import { setDoc, doc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from "react-router-dom";
import { auth } from '../../../firebase'

const Final = () => {
  const navigate = useNavigate();
  const { qOne, qTwo, qThree, appPage, errorMsg } = useQuestionaire()
  const { appRef, setAppComplete } = useAuth()

  const checkQuestion = (dictionary, pageNum, varName) => {
    let completed = true

    for (const key in dictionary) {
      if (dictionary[key].required) {
        if (dictionary[key].get === "" || dictionary[key].get === false) {
          appPage.set(pageNum)
          errorMsg[varName].set(true)
          completed = false
          return completed
        }
        else {
          errorMsg[varName].set(false)
        }
      }
    }

    return completed
  }

  const saveApplication = async () => {
    console.log("trying to save application")

    const appDocRef = doc(appRef, auth?.currentUser?.uid);
    const storage = getStorage()

    let uploadedFileURL = '';
    if (qTwo['resume'].get) {
      const storageRef = ref(storage, `resumes/${auth?.currentUser?.uid}/${qTwo['resume'].get.name}`);
      await uploadBytes(storageRef, qTwo['resume'].get);
      uploadedFileURL = await getDownloadURL(storageRef);
    }

    await setDoc(appDocRef, {
      firstName: qOne['firstName'].get,
      lastName: qOne['lastName'].get,
      email: qOne['email'].get,
      DOB: qOne['DOB'].get,
      sponsorEmail: qOne['sponsorEmail'].get,
      phone: qOne['phone'].get,
      school: qOne['school'].get,
      gradYear: qOne['gradYear'].get,
      countryResidence: qOne['countryResidence'].get,
      cityResidence: qOne['cityResidence'].get,
      ruhackBefore: qOne['ruhackBefore'].get,
      hackathonsBefore: qOne['hackathonsBefore'].get,
      dietary: qOne['dietary'].get,
      underRepresented: qOne['underRepresented'].get,
      gender: qOne['gender'].get,
      pronouns: qOne['pronouns'].get,
      ethnicity: qOne['ethnicity'].get,
      sexuality: qOne['sexuality'].get,
      highestEdu: qOne['highestEdu'].get,
      program: qOne['program'].get,
      questionOne: qTwo['questionOne'].get,
      questionTwo: qTwo['questionTwo'].get,
      linkedin: qTwo['linkedin'].get,
      github: qTwo['github'].get,
      personalWebsite: qTwo['personalWebsite'].get,
      resume: uploadedFileURL,
      discordID: qTwo['discordID'].get,
      shirtSize: qTwo['shirtSize'].get,
      shipAdd1: qTwo['shipAdd1'].get,
      shipAdd2: qTwo['shipAdd2'].get,
      shipCity: qTwo['shipCity'].get,
      shipProvince: qTwo['shipProvince'].get,
      shipCountry: qTwo['shipCountry'].get,
      postalCode: qTwo['postalCode'].get,
      agreeOne: qThree['agreeOne'].get,
      agreeTwo: qThree['agreeTwo'].get,
      agreeThree: qThree['agreeThree'].get,
      teamOneName: qThree['teamOneName'].get,
      teamOneEmail: qThree['teamOneEmail'].get,
      teamTwoName: qThree['teamTwoName'].get,
      teamTwoEmail: qThree['teamTwoEmail'].get,
      teamThreeName: qThree['teamThreeName'].get,
      teamThreeEmail: qThree['teamThreeEmail'].get,
      applicationStage: 0,
      discordInitialized: false,
      userID: auth?.currentUser?.uid
    });

    navigate("/");
  }

  const submitApplication = async () => {
    if (checkQuestion(qOne, 0, "qOne")) {
      if (checkQuestion(qTwo, 1, "qTwo")) {
        if (checkQuestion(qThree, 2, "qThree")) {
          saveApplication()
        }
      }
    }
  }

  return (
    <>
      <div className='m-10 w-[90%]'>

        {errorMsg['qThree'].get && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">Please fill in all Required Sections</div>}

        <div className='mb-3 font-bold'>
          Team Formation Information
        </div>

        <div className='mb-5'>
          Please know that inserting your team members here will have no impact towards the consideration of your application. However, for any future inquires throughout the hackathon application process, we will be using this as a source to cross reference any future dicussions.
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"Team Member Full Name #1"} ph_name={"e.g. Robert Munch"} getValue={qThree['teamOneName'].get} setFunction={qThree['teamOneName'].set} fieldType={'text'} required={false}/>
          </div>
          <div className="double-single-container">
          <InputField title={"Team Member Email #1"} ph_name={"e.g. Robert.Munch@books.com"} getValue={qThree['teamOneEmail'].get} setFunction={qThree['teamOneEmail'].set} fieldType={'text'} required={false}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"Team Member Full Name #2"} ph_name={"e.g. Cristiano Ronaldo"} getValue={qThree['teamTwoName'].get} setFunction={qThree['teamTwoName'].set} fieldType={'text'} required={false}/>
          </div>
          <div className="double-single-container">
          <InputField title={"Team Member Email #2"} ph_name={"e.g. Cristiano.Ronaldo@nba.com"} getValue={qThree['teamTwoEmail'].get} setFunction={qThree['teamTwoEmail'].set} fieldType={'text'} required={false}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"Team Member Full Name #3"} ph_name={"e.g. Mark Zuckerberg"} getValue={qThree['teamThreeName'].get} setFunction={qThree['teamThreeName'].set} fieldType={'text'} required={false}/>
          </div>
          <div className="double-single-container">
          <InputField title={"Team Member Email #3"} ph_name={"e.g. Mark.Zuckerberg@x.com"} getValue={qThree['teamThreeEmail'].get} setFunction={qThree['teamThreeEmail'].set} fieldType={'text'} required={false}/>
          </div>
        </div>


        <div className='mb-3 mt-5 font-bold flex flex-row'>
          MLH Agreements 
          <img src={RequiredStar} className='requiredStar-style'/>
        </div>

        <div className="single-container">
          <div className='flex flex-row justify-start gap-3'>
            <input 
              type="checkbox" 
              id="q1_checkbox" 
              class="relative h-5 w-5 shrink-0 rounded-sm border checked:bg-gray-500 hover:ring hover:ring-gray-300 focus:outline-none" 
              checked={qThree['agreeOne'].get}
              onClick={(e) => {qThree['agreeOne'].set(e.target.checked)}}
            />
            <label 
              for="q1_checkbox" 
              class="w-full cursor-pointer"
            > 
              I have read and agree to the MLH Code of Conduct (<a href='https://static.mlh.io/docs/mlh-code-of-conduct.pdf' className='text-blue-400 hover:text-blue-500 underline' target="_blank">https://static.mlh.io/docs/mlh-code-of-conduct.pdf</a>)
            </label>
          </div>
        </div>

        <div className="single-container">
          <div className='flex flex-row justify-start gap-3'>
            <input 
              type="checkbox" 
              id="q2_checkbox" 
              class="relative h-5 w-5 shrink-0 rounded-sm border checked:bg-gray-500 hover:ring hover:ring-gray-300 focus:outline-none" 
              checked={qThree['agreeTwo'].get}
              onClick={(e) => {qThree['agreeTwo'].set(e.target.checked)}}
            />
            <label 
              for="q2_checkbox" 
              class="w-full cursor-pointer"
            > 
              I authorize you to share my application/registration information with Major League Hacking for event administration, ranking, and MLH administration in-line with the MLH Privacy Policy (<a href='https://mlh.io/privacy' className='text-blue-400 hover:text-blue-500 underline' target="_blank">https://mlh.io/privacy</a>) I further agree to the terms of both the MLH Contest Terms and Conditions (<a href='https://github.com/MLH/mlh-policies/blob/main/contest-terms.md' className='text-blue-400 hover:text-blue-500 underline' target="_blank">https://github.com/MLH/mlh-policies/blob/main/contest-terms.md</a>) and the MLH Privacy Policy (<a href='https://mlh.io/privacy' className='text-blue-400 hover:text-blue-500 underline' target="_blank">https://mlh.io/privacy</a>)
            </label>
          </div>
        </div>

        <div className="single-container">
          <div className='flex flex-row justify-start gap-3'>
            <input 
              type="checkbox" 
              id="q3_checkbox" 
              class="relative h-5 w-5 shrink-0 rounded-sm border checked:bg-gray-500 hover:ring hover:ring-gray-300 focus:outline-none" 
              checked={qThree['agreeThree'].get}
              onClick={(e) => {qThree['agreeThree'].set(e.target.checked)}}
            />
            <label 
              for="q3_checkbox" 
              class="w-full cursor-pointer"
            > 
              I authorize MLH to send me occasional emails about relevant events, career opportunities, and community announcements.
            </label>
          </div>
        </div>


        <div className='mt-10 mb-3 font-bold'>
          Before Submission
        </div>

        <div>
          You application cannot be edited after you submit. Please be sure that all the information that you have inserted is accurate.
        </div>

        <button
          onClick={() => {submitApplication()}}
          className={`text-white bg-[#9b4587] hover:bg-[#833a72] font-bold mt-8 py-2 ss:px-4 px-2 ss:rounded rounded-full w-full`}
        >
          <div className='flex flex-row items-center justify-center gap-3'>
            <div>
              Submit Application
            </div>
            <img src={SubmitIcon} className='w-[25px]'/>
            </div>
        </button>
        
      </div>
    </>
  )
}

export default Final
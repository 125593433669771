import React from 'react'
import RequiredStar from "../../../Assets/RequiredStar.png"

const Star = () => {
  return ( 
    <>
      <img src={RequiredStar} className='requiredStar-style'/>
    </>
  )
}

const Optional = () => {
  return (
    <>
      <div className='ml-1'>(Optional)</div>
    </>
  )
}

const InputField = ({title, ph_name, getValue, setFunction, fieldType, required=true}) => {
  return (
    <>
      <div className='flex flex-row'>
        <label className="text-white mb-2">
          {title} 
        </label>
        {required && <Star/>}
        {!required && <Optional/>}
      </div>
      <input 
        type={fieldType}
        className="textInput-style"
        placeholder={ph_name}
        value={getValue}
        onChange={(e) => setFunction(e.target.value)}
        required={required}
      />
    </>
  )
}

export default InputField
import React, { useRef, useState } from "react";
import { useAuth } from "../../Contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import MetHacksLogo from "../../Assets/MetHacksLogoSmall.png"
import MascotComputer from "../../Assets/mascot_computer.png"

const ForgotPassword = () => {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage('')
      setError('')
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Check your inbox for further instructions")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)

  }

  return (
    <>
      <div className="lg:h-[100vh] md:m-0">
        <div className="flex flex-col item-center justify-center lg:h-full lg:my-0 my-10 lg:px-24">
          <div className="flex lg:flex-row flex-col w-full items-center justify-center">
            
            <div className="lg:w-[50%] w-[90%] lg:px-20">
              {/* <button 
                className="md:flex hidden flex-row w-full gap-1 text-white"
              >
                <img src={BackArrow} className="h-[24x]"/>
                Back
              </button> */}
              <div className="flex flex-col justify-center item-center h-full lg:gap-5 gap-1">
                <img src={MascotComputer} className="w-[70%] lg:block hidden"/>
                <div className="flex flex-row items-center justify-center">
                  <img src={MetHacksLogo} className="md:w-[100px] w-[75px] lg:hidden"/>
                </div>
                <div className="font-bold text-title text-center lg:text-start">
                  Welcome to the <br /> MetHacks Portal
                </div>
                <div className="text-header text-center lg:text-start md:block hidden">
                  Register to view your application status, hackathon schedule, and more!
                </div>
              </div>
            </div>

            <div className="lg:w-[50%] w-[100%] flex items-center justify-center lg:my-0 my-8">
              <div className="lg:w-[100%] w-[90%] lg:p-10">
                <form onSubmit={handleSubmit}>
                  <div className="rounded-3xl dark-Box lg:px-20 px-8 lg:py-12 py-6">
                    <div className="text-center font-bold text-bigHeader lg:mb-16 mb-12">
                      Forgot Password
                    </div>
                    {error && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">{error}</div>}
                    <div className="mb-4 flex flex-col">
                      <label className="text-norm mb-2">
                        Email
                      </label>
                      <input 
                        className="rounded-2xl w-full p-4 text-norm bg-[#222d4d] placeholder-[#475377] outline-none"
                        type="email" 
                        ref={emailRef}
                        placeholder="HelloWorld@email.com"
                        required />
                    </div>

                    <div className='flex flex-col w-full justify-between mt-[30px] items-center'>
                      <div className="w-full">
                        <button
                          className="bg-[#da6d6d] hover:bg-[#a85151] text-norm text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full lg:block hidden"
                          type="submit"
                          disabled={loading}
                        >
                          Send Forgot Password Link
                        </button>
                      </div>

                      <div className='w-full flex flex-col mt-5'>
                        <Link className="text-norm underline text-[#da6dc0] hover:text-[#a85194] text-center" to="/login">Return to Login</Link>
                      </div>
                    </div>
                  </div>
                  <button
                    className="bg-[#da6dc0] hover:bg-[#a85194] text-norm text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-8 lg:hidden"
                    type="submit"
                    disabled={loading}
                  >
                    Send Forgot Password Link
                  </button>
                </form>
              </div>
            </div>
            <div className="text-header text-center w-[90%] mb-10 lg:hidden">
              Register to view your application status, hackathon schedule, and more!
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

import React from 'react'
import { 
    GraduationYear, 
    Genders, 
    Pronouns,
    Countries, 
    HackathonNumber, 
    RUHacksAttended,
    DietaryRestrictions,
    UnderRepresented,
    RaceAndEthnicity,
    Sexuality,
    EducationLevel,
    MajorOfStudy
} from '../Components/Variables'
import Schools from "../Components/Schools"
import { useQuestionaire } from '../../../Contexts/QuestionaireContext'
import InputField from '../Components/InputField'
import DateField from '../Components/DateField'
import DropdownField from "../Components/DropdownField"
import OptionalInput from '../Components/OptionalInput'
import NextPage from "../Components/NextPage"

const General = () => {
  const { qOne, errorMsg } = useQuestionaire()

  return (
    <>
      <div className='my-8 w-[90%]'>
        {errorMsg['qOne'].get && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">Please fill in all Required Sections</div>}

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"First Name"} ph_name={"e.g. Elon"} getValue={qOne['firstName'].get} setFunction={qOne['firstName'].set} fieldType={'text'}/>
          </div>
          <div className="double-single-container">
            <InputField title={"Last Name"} ph_name={"e.g. Musk"} getValue={qOne['lastName'].get} setFunction={qOne['lastName'].set} fieldType={'text'}/>
          </div>
        </div>

        <div className="single-container">
          <InputField title={"Email"} ph_name={"e.g. hotmale@hotmail.com"} getValue={qOne['email'].get} setFunction={qOne['email'].set} fieldType={'email'}/>
          <span className='my-3'>* Please ensure this email is the same as the one you used to register with and will submit your final project. </span>
          <div className='flex flex-row justify-start gap-3'>
            <input 
              type="checkbox" 
              id="sponsor_checkbox" 
              class="relative h-5 w-5 shrink-0 rounded-sm border checked:bg-gray-500 hover:ring hover:ring-gray-300 focus:outline-none" 
              checked={qOne['sponsorEmail'].get}
              onClick={(e) => {qOne['sponsorEmail'].set(e.target.checked)}}
            />
            <label 
              for="sponsor_checkbox" 
              class="w-full cursor-pointer"
            > 
              I would like to recieve emails from our sponsors.
            </label>
          </div>
        </div>

        <div className="single-container">
          <DateField title={"Date of Birth"} getValue={qOne['DOB'].get} setFunction={qOne['DOB'].set}/>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"Phone Number"} ph_name={"e.g. 416 420 6969"} getValue={qOne['phone'].get} setFunction={qOne['phone'].set} fieldType={'tel'}/>
          </div>
          <div className="double-single-container">
            <InputField title={"City of Residence"} ph_name={"e.g. Toronto"} getValue={qOne['cityResidence'].get} setFunction={qOne['cityResidence'].set} fieldType={'text'}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <DropdownField title={"Country of Residence"} ph_name={"Select your country..."} dropdownOptions={Countries} getValue={qOne['countryResidence'].get} setFunction={qOne['countryResidence'].set}/>
          </div>
          <div className="double-single-container">
            <DropdownField title={"School"} ph_name={"Select your school..."} dropdownOptions={Schools} getValue={qOne['school'].get} setFunction={qOne['school'].set}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <DropdownField title={"Major / Field of Study"} ph_name={"Select your Major / Field..."} dropdownOptions={MajorOfStudy} getValue={qOne['program'].get} setFunction={qOne['program'].set}/>
          </div>
          <div className="double-single-container">
            <DropdownField title={"Graduation Year"} ph_name={"Select your graduation year..."} dropdownOptions={GraduationYear} getValue={qOne['gradYear'].get} setFunction={qOne['gradYear'].set}/>
          </div>
        </div>

        <div className='single-container'>
          <DropdownField title={"Highest level of formal education you have completed"} ph_name={"Select your level of study..."} dropdownOptions={EducationLevel} getValue={qOne['highestEdu'].get} setFunction={qOne['highestEdu'].set}/>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <DropdownField title={"Number of Hackathons Attended?"} ph_name={"Select your level of study..."} dropdownOptions={HackathonNumber} getValue={qOne['hackathonsBefore'].get} setFunction={qOne['hackathonsBefore'].set}/>
          </div>
          <div className="double-single-container">
            <DropdownField title={"Have you Partcipated in MetHacks Before?"} ph_name={"Select no or yes..."} dropdownOptions={RUHacksAttended} getValue={qOne['ruhackBefore'].get} setFunction={qOne['ruhackBefore'].set}/>
          </div>
        </div>

        <div className='single-container'>
          <OptionalInput title={"Dietary Restrictions?"} ph_name={"Select any restrictions..."} dropdownOptions={DietaryRestrictions} getValue={qOne['dietary'].get} setFunction={qOne['dietary'].set}/>
        </div>

        <div className="single-container">
          <DropdownField title={"Do you Identify as a part of an underrepresented group in the technology industry?"} ph_name={"Select an option..."} dropdownOptions={UnderRepresented} getValue={qOne['underRepresented'].get} setFunction={qOne['underRepresented'].set}/>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <OptionalInput title={"Gender"} ph_name={"Select gender..."} dropdownOptions={Genders} getValue={qOne['gender'].get} setFunction={qOne['gender'].set}/>
          </div>
          <div className="double-single-container">
            <OptionalInput title={"Pronouns"} ph_name={"Select pronouns..."} dropdownOptions={Pronouns} getValue={qOne['pronouns'].get} setFunction={qOne['pronouns'].set}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <OptionalInput title={"Race / Ethnicity"} ph_name={"Select and Race / Ethnicity..."} dropdownOptions={RaceAndEthnicity} getValue={qOne['ethnicity'].get} setFunction={qOne['ethnicity'].set}/>
          </div>
          <div className="double-single-container">
            <OptionalInput title={"Do you consider yourself to be any of the following?"} ph_name={"Select orientation..."} dropdownOptions={Sexuality} getValue={qOne['sexuality'].get} setFunction={qOne['sexuality'].set}/>
          </div>
        </div>

        <div className='flex flex-row gap-3 mt-8 justify-center'>
          <div>
            <NextPage location_id={1}/>
          </div>
        </div>

      </div>
    </>
  )
}

export default General
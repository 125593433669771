import React from 'react'
import PageTitle from '../../Components/PageTitle'
import trs7thFloor from "../../Assets/trsm_level1_7thfloor.jpg";
import trs8thFloor from "../../Assets/trsm_level2_8thfloor.jpg";
import trs9thFloor from "../../Assets/trsm_level3_9thfloor.jpg";

const Guide = () => {
  return (
    <>
      <div className='text-white flex flex-col gap-2 items-center h-[100%] ss:p-10'>
        <PageTitle title={"Hacker Guide"} description={"View Information about the Hackathon here!"}/>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            📌 Ted Rogers School of Management @ Toronto Metropolitan University
          </div>
          <div className='text-header'>
          55 Dundas St W, Toronto, ON M5G 2C3
          </div>
          <div className='text-header'>
            Friday, May 5th - Sunday, May, 7th 2023
          </div>
        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            ✅ Ultimate Hackathon Packing CheckList
          </div>    
          <ul className='text-norm list-disc list-outside'>
            <li>Identification (Drivers License, Student Cards, or Health Cards)</li>
            <li>Notebook + Pen</li>
            <li>Laptop + Charger</li>
            <li>Phone + Charger</li>
            <li>Headphones</li>
            <li>Sleeping Bag + Pillow</li>
            <li>Comfy Clothes</li>
            <li>Deodorant</li>
            <li>Toothbrush + Toothpaste</li>
            <li>Shampoo, Face Wash, etc.</li>
            <li>Change of Clothing</li>
            <li>Reusable Water Bottle</li>
            <li>Required Medication, Inhalers, Epi Pens, etc.</li>
            <li>Resume</li>
            <li>Playing Cards</li>
          </ul>
        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🗼 To Connect to Wifi
          </div>
          <ul className='text-norm list-disc list-outside'>
            <li>Wifi name: TMU</li>
            <li>Username / Identity: trsguests</li>
            <li>Password: M*93c%pF</li>
          </ul>
        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🏠 Room Directory
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Hacker Rooms:
            </div>
            <div>
              2-102, 2-104, 2-106, 2-108, 2-110, 2-112, 2-099, 2-109, 2-119, 2-164, 2-166, 3-176
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Sign in Table:
            </div>
            <div>
              In front of CARA Commons (7th floor)
            </div>
          </div>


          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Food Area:
            </div>
            <div>
              CARA Commons (7th floor)
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Opening and Closing Ceremony:
            </div>
            <div>
              Auditorium (1-067)
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Mentor & Judges Lounge:
            </div>
            <div>
              Four Seasons Conference Room (TRS 3-164)
            </div>
          </div>

        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🛍️ Workshops Being Hosted
          </div>    

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Resume Roast:
            </div>
            <div>
              Join us for a lighthearted and informative event where we'll review and critique resumes in a fun and humorous way. Colleagues and friends will provide feedback on the strengths and weaknesses of each resume, with the goal of helping everyone improve their resumes.
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              IBZ Infobesity:
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
          </div>


          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              TD Workshop:
            </div>
            <div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              XRPL:
            </div>
            <div>
              Want to get some free XRP and mint your very own NFT? Come see how easy it is to get started on the XRP Ledger and learn about how merchants are starting to use NFTs in eCommerce.
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Cohere:
            </div>
            <div>
              Intro to Cohere, LLM's and real-world applications. Build something with Cohere's API this weekend, and don't miss the Cohere workshop with Luis Serrano (AI Scientist & popular YouTuber) for a gentle intro to Cohere, and real-world applications of large language models!
            </div>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              How to Pitch by MLH:
            </div>
            <div>
              Join Major League Hacking (MLH) for an interactive and engaging workshop designed to transform your hackathon project pitch into a winning formula. Learn valuable techniques on storytelling, structuring, and presentation from industry experts, and gain the confidence to captivate your audience. Turn your innovative ideas into compelling narratives and amplify your hackathon success!
            </div>
          </div>

        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            😋 Food Menu
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Friday Dinner - TRSM
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Garden Veg Pizza</li>
              <li>Cheese Pizza</li>
              <li>Chicken Pizza (Halal)</li>
            </ul>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Saturday Breakfast - TRSM
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Breakfast Sandwich</li>
              <li>Backed Goods</li>
            </ul>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Saturady Lunch - Tut's Egyptian
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Chicken (Halal) Sandwich</li>
              <li>Beef (Halal) Sandwich</li>
              <li>Potato Sandwich</li>
            </ul>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Saturady Dinner - TRSM
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Food</li>
              <li>Yummy</li>
              <li>Food</li>
            </ul>
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Sunday Breakfast - What A Bagel
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Bagels</li>
              <li>Pastries</li>
              <li>Spreads</li>
            </ul>
          </div>

        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🧠 Some Tips
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <ul className='text-norm list-disc list-outside'>
              <li><span className='font-bold'>Attend the opening ceremony.</span> This ceremony will go over the details of the event such as the sponsors and rules. It will also give you an opportunity to hear from our wonderful sponsors and you’ll get to meet your fellow hackers.</li>
              <li><span className='font-bold'>Attend workshops</span> to learn new skills that you can use to build your Hack. Workshops are a great way to learn new skills, acquire knowledge, and connect with like-minded individuals. They provide an interactive and hands-on learning experience that can be much more effective than just reading or watching instructional materials.</li>
              <li><span className='font-bold'>Network.</span> Hackathons are a great way to meet new people, talk with industry professionals, and expand your network.</li>
              <li><span className='font-bold'>Rehearse your pitch.</span> It's important to have a well-prepared and rehearsed pitch. Practice with your team, keep it concise, informative, and engaging. Focus on the problem, the solution, and the potential impact your hack may have. Judges may ask questions so make sure you are prepared and know your project inside out.</li>
              <li><span className='font-bold'>Attend the Awards Ceremony.</span> Celebrate your accomplishments with others. Take the opportunity to learn from the winning teams and gather feedback from the judges.</li>
              <li><span className='font-bold'>Post-Hackathon: Reflect on your experience</span> such as what went well, what could need some improvement, how the team communicated and etc. Keep in touch with your teammates, mentors, and other contacts you made during the hackathon. These connections can lead to future collaborations, job opportunities, or friendships.</li>
              <li><span className='font-bold'>Don’t forget your belongings.</span> Make sure to grab ALL your belongings before leaving. TMU and MetHacks are not responsible for any lost or stolen items.</li>
            </ul>
          </div>

        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🦜 Discord Etiquette and Policies
          </div>    

          <div className='flex flex-col text-norm gap-6'>
            <div>
              Please follow MetHacks 2023 rules set below, in addition to the MLH <a href='https://static.mlh.io/docs/mlh-code-of-conduct.pdf' className='text-blue-400 hover:text-blue-500 underline' target="_blank">Code of Conduct</a> and <a href='https://mlh.io/privacy' className='text-blue-400 hover:text-blue-500 underline' target="_blank">Privacy Policy</a>. These rules will ensure that a respectful environment is maintained when communicating. If you need clarification or have questions about any of the rules, feel free to reach out to us in the <span className='font-bold underline'>#ask-organizers</span> channel on Discord! Further, if you see any inappropriate content, please message the @organizers or @mods on the server or privately.
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Remember to always be <span className='font-bold underline'>respectful</span> while communicating on the server.</li>
              <li>Consider the privacy of others when posting pictures and ensure that all those included in the photo have consented to the sharing of it before posting. If consent is not properly obtained, the post will be removed immediately</li>
              <li><span className='underline'>Listen</span> Listen to Organizers and any instructions they may provide.</li>
              <li>Do not provide help with projects, other than for your own team’s. Do not request for help from external resources.</li>
              <li>DO NOT send spam to any of the channels. Spam includes, but is not limited to, posting random characters, sentences, or images that are constant within a short period of time.</li>
              <li>No inappropriate profile pictures or names.</li>
              <li>Respect each channel’s policy, and its purpose/topics, (just try to keep conversations on topic depending on the titled channel)</li>
              <li>Finally, please remember to be kind when roaming and communicating on our server :)</li>
            </ul>
          </div>
        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🏨 Building Etiquette and Policies
          </div>
          <ul className='text-norm list-disc list-outside'>
            <li>Only participants are allowed inside the building. You are not allowed to invite your friends who are not participating to enter the building.</li>
            <li>Participants are responsible for their belongings. TMU and MetHacks are not responsible for any damaged or lost items. </li>
            <li>Rooms and work areas must be cleaned and left in the same condition as they were found.</li>
          </ul>
        </div>


        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            👮 Security
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div className='font-bold'>
              Security will be posted at the TRSM building during the following hours:
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>Friday 2pm - Saturday 7am</li>
              <li>Saturday 8pm - Sunday 9am</li>
            </ul>
          </div>
        </div>

        <div className='guideBox'>
          <div className='text-subHeader font-bold'>
            🚗 Parking
          </div>

          <div className='flex flex-col text-norm gap-1'>
            <div>
              Parking <span className='font-bold'>will not</span> be provided. Hackers will have to find their own parking accommodations. The closest parking lots are:
            </div>
            <ul className='text-norm list-disc list-outside'>
              <li>154 Elizabeth St, Toronto, ON M5G 1H4 - $20 CASH</li>
              <li>Eaton Centre Parking -  Connected to TRSM ~$28</li>
              <li>Street Parking on Mutual St - a bit of a walk, slightly cheaper</li>
              <li>202 Jarvis St, Toronto, ON M5G 1E5 - a bit of a walk, slightly cheaper</li>
            </ul>
          </div>
        </div>

        <div className='guideBox flex flex-col gap-5 items-center justify-center'>
          <div className='text-subHeader font-bold'>
            Map of the Building
          </div>
          <img src={trs7thFloor} className='w-[90%]'/>
          <img src={trs8thFloor} className='w-[90%]'/>
          <img src={trs9thFloor} className='w-[90%]'/>

        </div>

      </div>
    </>
  )
}

export default Guide
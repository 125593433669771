import React, { useEffect } from 'react'
import { useQuestionaire } from '../../../Contexts/QuestionaireContext'
// import TeamIdentificationIcon from "../../../Assets/TeamIndentificationIcon.png"
import GeneralIcon from "../../../Assets/ApplicationIcons/GeneralIcon.png"
import CheckBoxIcon from "../../../Assets/ApplicationIcons/CheckBoxIcon.png"
import PersonalIcon from "../../../Assets/ApplicationIcons/PersonalIcon.png"
// import TeamIcon from "../../../Assets/ApplicationIcons/TeamIcon.png"
import FinalIcon from "../../../Assets/FinalIcon.png"

const AppNavbar = () => {
  const { appPage } = useQuestionaire()

  return (
    <>
      <div className='w-full navigation'>
        <ul>
          <li className={`list ${appPage.get === 0 ? 'active' : ''} cursor-pointer`}>
            <div className='clicker' onClick={() => {appPage.set(0)}}>
              <span className='icon text-white'>
                <img src={GeneralIcon} className='ss:w-[30px] w-[25px] mt-[13px]'/>
              </span>
              <span className='text ss:mt-4'>General</span>
            </div>
          </li>
          <li className={`list ${appPage.get === 1 ? 'active' : ''} cursor-pointer`}>
            <div className='clicker' onClick={() => {appPage.set(1)}}>
              <span className='icon text-white'>
                <img src={PersonalIcon} className='ss:w-[30px] w-[25px] mt-[13px]'/>
              </span>
              <span className='text ss:mt-4'>Personal</span>
            </div>
          </li>
          <li className={`list ${appPage.get === 2 ? 'active' : ''} cursor-pointer`}>
            <div className='clicker' onClick={() => {appPage.set(2)}}>
              <span className='icon text-white'>
                <img src={FinalIcon} className='ss:w-[30px] w-[25px] mt-[13px]'/>
              </span>
              <span className='text ss:mt-4'>Final</span>
            </div>
          </li>
          {/* <li className={`list ${appPage.get === 3 ? 'active' : ''} cursor-pointer`}>
            <div className='clicker' onClick={() => {appPage.set(3)}}>
              <span className='icon text-white'>
                <img src={TeamIcon} className='w-[25px] h=[25px] mt-[13px]'/>
              </span>
              <span className='text'>Team</span>
            </div>
          </li> */}
          <div className='indicator'></div>
        </ul>
      </div>
    </>
  )
}

export default AppNavbar
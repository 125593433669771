import React from 'react'
import { useQuestionaire } from '../../../Contexts/QuestionaireContext'
import TextAreaField from '../Components/TextAreaField'
import InputField from '../Components/InputField'
import DropdownField from "../Components/DropdownField"
import { ShirtSize } from '../Components/Variables'
import PrevPage from "../Components/PrevPage"
import NextPage from "../Components/NextPage"

const LongQs = () => {
  const { qTwo, errorMsg } = useQuestionaire()

  return (
    <>
      <div className='m-10 w-[90%]'>
        {errorMsg['qTwo'].get && <div className="rounded-lg bg-red-100 p-2 text-red-700 text-center mb-4">Please fill in all Required Sections</div>}

        <div className="single-container">
          <TextAreaField title={"Tell us about a time you were humbled (by anything) and how it broadened your perspective?"} ph_name={"Insert Answer Here..."} getValue={qTwo['questionOne'].get} setFunction={qTwo['questionOne'].set}/>
        </div>

        <div className="single-container">
          <TextAreaField title={"What hackathon project would you pitch to the villian of your favourite game / tv show / movie / book?"} ph_name={"Insert Answer Here..."} getValue={qTwo['questionTwo'].get} setFunction={qTwo['questionTwo'].set}/>
        </div>

        <div className="single-container">
          <InputField title={"Linkedin Profile"} ph_name={"Insert Linkedin URL..."} getValue={qTwo['linkedin'].get} setFunction={qTwo['linkedin'].set} fieldType={"#url"} required={false}/>
        </div>

        <div className="single-container">
          <InputField title={"GitHub Profile"} ph_name={"Insert GitHub URL..."} getValue={qTwo['github'].get} setFunction={qTwo['github'].set} fieldType={"#url"} required={false}/>
        </div>

        <div className="single-container">
          <InputField title={"Personal Website"} ph_name={"Insert Personal Website URL..."} getValue={qTwo['personalWebsite'].get} setFunction={qTwo['personalWebsite'].set} fieldType={"#url"} required={false}/>
        </div>

        <div className="single-container">        
          <label class="block mb-2 text-norm" for="large_size">Resume Dropbox (Optional)</label>
          <input 
            class="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-norm leading-[2.15] text-neutral-100 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-gray-900 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-300 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-200 dark:text-neutral-200 dark:file:bg-neutral-100 dark:file:text-neutral-900 dark:focus:border-primary" 
            id="large_size" 
            type="file"  
            accept=".pdf"
            onChange={(e) => {
              qTwo['resume'].set(e.target.files[0])
            }}
          />
        </div>

        <div className="single-container">
          <InputField title={"Discord ID (If accepted to the Hackathon, note this ID must match the account you use to join the Discord Server)"} ph_name={"Insert Discord ID..."} getValue={qTwo['discordID'].get} setFunction={qTwo['discordID'].set} fieldType={"text"}/>
        </div>

        <div className="single-container">
          <DropdownField title={"Shirt Size"} ph_name={"Select a Shirt Size..."} getValue={qTwo['shirtSize'].get} setFunction={qTwo['shirtSize'].set} dropdownOptions={ShirtSize}/>
        </div>

        <div className='my-3 mt-5 font-bold'>
          Shipping Information
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"Shipping Address 1"} ph_name={"Insert Shipping Address 1..."} getValue={qTwo['shipAdd1'].get} setFunction={qTwo['shipAdd1'].set} fieldType={'text'}/>
          </div>
          <div className="double-single-container">
            <InputField title={"Shipping Address 2"} ph_name={"Insert Shipping Address 2..."} getValue={qTwo['shipAdd2'].get} setFunction={qTwo['shipAdd2'].set} fieldType={'text'} required={false}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"City"} ph_name={"Insert City Shipping..."} getValue={qTwo['shipCity'].get} setFunction={qTwo['shipCity'].set} fieldType={'text'}/>
          </div>
          <div className="double-single-container">
            <InputField title={"Province"} ph_name={"Insert Provine Shipping..."} getValue={qTwo['shipProvince'].get} setFunction={qTwo['shipProvince'].set} fieldType={'text'}/>
          </div>
        </div>

        <div className='double-container'>
          <div className="double-single-container">
            <InputField title={"Country"} ph_name={"Insert Country Shipping..."} getValue={qTwo['shipCountry'].get} setFunction={qTwo['shipCountry'].set} fieldType={'text'}/>
          </div>
          <div className="double-single-container">
            <InputField title={"Postal Code"} ph_name={"Insert Postal Code..."} getValue={qTwo['postalCode'].get} setFunction={qTwo['postalCode'].set} fieldType={'text'}/>
          </div>
        </div>

        <div className='flex flex-row gap-3 mt-8 justify-center'>
          <div>
            <PrevPage location_id={0}/>
          </div>
          <div>
            <NextPage location_id={2}/>
          </div>
        </div>

      </div>
    </>
  )
}

export default LongQs
import React from "react";
import Mascot from "../../../Assets/mascot_happy.png";
import { useAuth } from "../../../Contexts/AuthContext";

const Complete = () => {
  const { appFirstName } = useAuth();

  return (
    <>
      <div className="flex flex-col justify-center items-center mt-12 py-10 rounded-3xl dark-Box w-[80%] text-norm">
        <div className="text-subHeader font-semibold text-center px-5 text-[#da6dc0] mb-3">
          Congratulations {appFirstName}
        </div>
        <div className="text-subHeader font-semibold text-center px-5">
          You have Completed you Application to MetHacks 2024
        </div>
        <img src={Mascot} className="my-16 md:w-[30%] w-[65%]" />
        <div className="text-header text-center px-5">
          Be sure to keep up to date on your application status using the
          general page
        </div>
      </div>
    </>
  );
};

export default Complete;
